import styled from 'styled-components';
import { Typography } from '@mui/material';

export const DiscountDurationBanner = styled(Typography)`
    &&{
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 15px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.palette.notificationBlue};
    }
`;

export const StyledFinalPrice = styled(Typography)`
    && {
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        text-align: right;
        font-size: 16px;
        font-style: normal;
        line-height: normal;
    }
`;
