import React, { FC, SyntheticEvent, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Field, FieldInputProps, FieldProps, FormikHelpers, FormikProps } from 'formik';
import StyledTitle from 'src/components/Title';
import Checkbox from 'src/components/Checkbox/Checkbox';
import BaseModal from 'src/components/Modals/BaseModal/BaseModal';
import AddManagerModal from 'src/components/Modals/AddManagerModal/AddManagerModal';
import { CurrentTitle } from 'src/components/Modals/ChangeOwnerModal/ChangeOwnerModal.styles';
import { OwnerSelect } from 'src/components/Select';
import { Label } from 'src/components/Label';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';
import { removeManagers, setOwner } from 'src/services/subscription-service-api';
import { SnackbarMessageVariants } from 'src/constants';
import { User } from 'src/@types/unified-db-api';
import { DEFAULT_MODAL_PADDING_BOTTOM, DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import type { VerticalsFormValues } from 'src/components/NewslettersDelivery/NewslettersDelivery';
import { OwnerLablel } from 'src/components/Forms/ManagerModalForm/components/AddManagerForm/AddManagerForm.styles';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';
import { UserInfo } from 'src/components/Forms/components/UserInfo';

type ChangeOwnerFormProps = {
    subscriptionUUID: string;
    managers: Omit<User, 'jobInfo'>[] | null;
    owner: Omit<User, 'jobInfo'> | null;
    loadNextPage: () => void;
    refreshData: () => Promise<void>;
    preSelectedUser?: User;
    isLoadingNextPage?: boolean;
};

type ChangeOwnerValuesType = {
    newOwner: string;
    removeOwner: boolean;
};

const initialValues: ChangeOwnerValuesType = {
    newOwner: '',
    removeOwner: false,
};

const ChangeOwnerForm: FC<ChangeOwnerFormProps> = ({
    subscriptionUUID,
    managers,
    owner,
    refreshData,
    preSelectedUser,
    loadNextPage,
    isLoadingNextPage,
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showAddManager, setShowAddManager] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(!preSelectedUser);

    const { addMessage } = useSnackbarMessage();
    const { closeModal, updateModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const handleSubmitting = ((
        { newOwner, removeOwner }: ChangeOwnerValuesType,
        { resetForm }: FormikHelpers<ChangeOwnerValuesType>,
    ) => {
        const fullName = managers?.find((manager) => manager.uuid === newOwner)?.fullName;

        setIsSubmitting(true);

        setOwner(subscriptionUUID, { userUUID: newOwner })
            .then(async () => {
                addMessage(
                    `${fullName} has been successfully set as an owner`,
                    SnackbarMessageVariants.SUCCESS,
                );

                if (removeOwner && owner) {
                    await removeManagers(subscriptionUUID, { managers: [owner.uuid] })
                        .then(() => {
                            addMessage(
                                `${owner.fullName} has been successfully removed from managers`,
                                SnackbarMessageVariants.SUCCESS,
                            );
                        })
                        .catch(() => {
                            addMessage(`Failed to remove ${owner.fullName} from managers`, SnackbarMessageVariants.ERROR);
                        });
                }

                reloadPage();
                closeModal();
                resetForm({ values: initialValues });
            })
            .catch(() => {
                addMessage(`Failed to set ${fullName} as an owner`, SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    });

    const handleCheck = (
        field: FieldInputProps<VerticalsFormValues>,
        form: FormikProps<VerticalsFormValues>,
    ) => (
        event: SyntheticEvent,
    ) => {
        const { checked } = event.target as HTMLInputElement;

        form.setValues(
            {
                ...form.values,
                [field.name]: checked,
            },
        );
    };

    const addManagerHandler = () => {
        setShowAddManager(true);
        setIsFocused(false);
    };

    const handleAddManagerSuccess = (user: User, isOwner: boolean) => {
        setIsDisabled(user.uuid === owner?.uuid);
        setShowAddManager(false);
        setIsSubmitting(true);
        reloadPage();

        if (isOwner) {
            closeModal();
            setIsSubmitting(false);

            return;
        }

        updateModal({
            extraProps: {
                preSelectedUser: user,
            },
        });

        refreshData().finally(() => {
            setIsSubmitting(false);
        });
    };

    if (showAddManager) {
        return (
            <BaseModal
                paddingX={0}
                showCloseAndGoBack
                paddingBottom={0}
                title="Add manager"
                open={showAddManager}
                handleClose={closeModal}
                handleGoBack={() => setShowAddManager(false)}
            >
                <AddManagerModal
                    subscriptionUUID={subscriptionUUID}
                    subscriptionManagers={managers ?? []}
                    onSuccess={handleAddManagerSuccess}
                    paddingTop={0}
                />
            </BaseModal>
        );
    }

    return (
        <FormWrapper<ChangeOwnerValuesType>
            testId="change-owner-form"
            initialValues={{
                ...initialValues,
                newOwner: preSelectedUser?.uuid ?? '',
            }}
            onSubmit={handleSubmitting}
            isSubmitting={isSubmitting}
        >
            <Box
                maxWidth={550}
                paddingX={DEFAULT_MODAL_PADDING_X}
                paddingBottom={DEFAULT_MODAL_PADDING_BOTTOM}
                boxSizing="border-box"
            >
                <StyledTitle data-font="medium-modal" marginBottom={{ xs: 2.5, sm: 3 }}>
                    { owner
                        ? 'To change the current owner please select a new owner from the drop-down list of managers.'
                        : 'This subscription has no owner. Please select an owner from the drop-down list of managers below.'
                    }
                </StyledTitle>
                {owner && (
                    <Box marginBottom={{ xs: 2.5, sm: 4 }}>
                        <CurrentTitle marginBottom={0.5}>Current owner</CurrentTitle>
                        <UserInfo
                            name={owner.fullName}
                            additionalRows={[owner.email.email]}
                            badgeLabel="Owner"
                        />
                    </Box>
                )}
                <Box marginBottom={owner ? 0 : 3}>
                    <Label isFocused={isFocused} htmlFor="newOwner">New owner</Label>
                    <Field name="newOwner" as="select">
                        {({ field, form }: FieldProps) => (
                            <OwnerSelect
                                testId="new-owner"
                                title="Select new owner"
                                name={field.name}
                                value={field.value}
                                placeholder="Select new owner"
                                managers={managers}
                                owner={owner}
                                addManagerHandler={addManagerHandler}
                                onOpen={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                handleInputChange={(value) => {
                                    form.setFieldValue(field.name, value);
                                    setIsDisabled(!value);
                                }}
                                loadNextPage={loadNextPage}
                                isLoadingNextPage={isLoadingNextPage}
                            />
                        )}
                    </Field>
                </Box>
                {owner && (
                    <Box
                        data-testid="change-owner-block"
                        marginY={3}
                    >
                        <Field name="removeOwner" type="checkbox">
                            {({ field, form }: FieldProps) => (
                                <Stack direction="row" alignItems="center">
                                    <Checkbox
                                        data-testid={field.name}
                                        name={field.name}
                                        value={field.value}
                                        onClick={handleCheck(field, form)}
                                        checked={field.checked}
                                        disabled={!form.values.newOwner}
                                    />
                                    <OwnerLablel marginLeft={{ xs: 1, sm: 1.25 }}>
                                        Remove current owner from managers
                                    </OwnerLablel>
                                </Stack>
                            )}
                        </Field>
                    </Box>
                )}
                <Button
                    data-testid="change-owner-submit-button"
                    type="submit"
                    fullWidth
                    disabled={isDisabled}
                >
                    Change owner
                </Button>
            </Box>
        </FormWrapper>
    );
};

export default ChangeOwnerForm;
