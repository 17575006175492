import React, { FC, Fragment, ReactNode } from 'react';
import { Box, type BoxProps, Stack } from '@mui/material';
import { SectionTitle, StyledPageSection, StyledPageSectionHeader } from './Section.styles';
import Line from 'src/components/Line';
import { LoaderOverlay } from 'src/components/LoaderOverlay';

type PageSectionProps = {
    header?: string;
    isMultiSection?: boolean;
    isLoading?: boolean;
    testId?: string;
    headerChildren?: ReactNode;
    headerHint?: ReactNode;
    children: ReactNode;
    sectionPaddingX?: BoxProps['paddingX'];
    sectionPaddingTop?: BoxProps['paddingTop'];
    sectionPaddingBottom?: BoxProps['paddingBottom'];
    variant?: 'headless' | 'default';
} & BoxProps;

export const DEFAULT_SECTION_PADDING_X = { md: 4.5, xs: 2 };

const Section: FC<PageSectionProps> = ({
    header,
    isMultiSection,
    isLoading,
    testId,
    children,
    headerHint,
    headerChildren,
    variant = 'default',
    sectionPaddingX = DEFAULT_SECTION_PADDING_X,
    sectionPaddingTop = variant !== 'headless' ? { md: 3, xs: 2 } : 0,
    sectionPaddingBottom,
    ...BoxProps
}) => {
    const childrenArray = (!isMultiSection || !Array.isArray(children) ? [children] : children).filter(Boolean);

    const getSectionPaddingBottom = () => {
        if (header && variant !== 'headless') {
            return { md: 4.5, xs: 3 };
        }

        return { md: 4, xs: 3 };
    };

    return (
        <StyledPageSection position="relative" data-testid={testId} marginTop={{ xs: 2, md: header ? 2.5 : 2 }} {...BoxProps}>
            {header && (
                <>
                    <Stack
                        paddingX={{ md: 4.5, xs: 2 }}
                        paddingTop={{ md: variant === 'headless' ? 3 : 2.5, xs: 2 }}
                        paddingBottom={{ md: 2.5, xs: 2 }}
                        justifyContent="space-between"
                        direction="row"
                    >
                        {variant !== 'headless' ? (
                            <StyledPageSectionHeader variant="h2">
                                {header}
                                {headerHint}
                            </StyledPageSectionHeader>
                        ) : (
                            <SectionTitle>
                                {header}
                            </SectionTitle>
                        )}
                        {headerChildren}
                    </Stack>
                    {variant !== 'headless' && <Line />}
                </>
            )}
            {isLoading && <LoaderOverlay />}
            {childrenArray.map((child, index) => (
                <Fragment key={`${testId ?? header}-${index}`}>
                    {index > 0 && <Line />}
                    <Box
                        position="relative"
                        paddingX={sectionPaddingX}
                        paddingTop={sectionPaddingTop}
                        paddingBottom={sectionPaddingBottom ?? getSectionPaddingBottom()}
                    >
                        {child}
                    </Box>
                </Fragment>
            ))}
        </StyledPageSection>
    );
};

export default Section;
