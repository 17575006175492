import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from 'src/redux/root-reducer';
import type { CurrentUserState } from 'src/@types/redux';
import { UserRoles } from 'src/constants';
import { userSelector } from 'src/redux/slices';

export enum Scope {
    CREDENTIALS_MODIFY_ALL = 'credentials.modify.all',
    CREDENTIALS_MODIFY_USER = 'credentials.modify.user',
    ACCESS_CODE_GENERATE_ALL = 'accessCode.generate.all',
    ACCESS_CODE_GENERATE_USER = 'accessCode.generate.user',
    APPLICATION_READ = 'application.read',
    CREDENTIALS_READ = 'credentials.read',
    SUBSCRIPTIONS_SERVICE_READ = 'subscriptionsService.read',
    SUBSCRIPTIONS_SERVICE_WRITE = 'subscriptionsService.write',
    UDB_READ = 'udb.read',
    UDB_WRITE = 'udb.write',
}

const scopesMap: Partial<Record<UserRoles, Scope[]>> = {
    [UserRoles.APP_MANAGER]: [
        Scope.APPLICATION_READ,
    ],
    [UserRoles.ANALYST]: [
        Scope.SUBSCRIPTIONS_SERVICE_READ,
        Scope.UDB_READ,
    ],
    [UserRoles.USER_MANAGER]: [
        Scope.CREDENTIALS_MODIFY_USER,
        Scope.ACCESS_CODE_GENERATE_USER,
        Scope.CREDENTIALS_READ,
        Scope.SUBSCRIPTIONS_SERVICE_READ,
        Scope.SUBSCRIPTIONS_SERVICE_WRITE,
        Scope.UDB_READ,
        Scope.UDB_WRITE,
    ],
    [UserRoles.ADMIN]: Object.values(Scope),
};

export const useUserCan = (scope: Scope): boolean => {
    const [userCan, setUserCan] = useState<boolean>(false);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);

    useEffect(() => {
        if (!currentUser?.role) {
            setUserCan(false);
            return;
        }

        setUserCan(!!scopesMap[currentUser.role]?.includes(scope));
    }, [currentUser, scope]);
    return userCan;
};
