import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const UserInfoWrapper = styled(Box)`
    border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    border-radius: 4px;
`;

export const UserInfoName = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
    }
`;

export const UserInfoText = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};
        font-size: 14px;
    }
`;
