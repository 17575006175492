import styled from 'styled-components';
import { Typography } from '@mui/material';

export const CurrentTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
    }
`;
