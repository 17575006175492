import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { TableSection } from 'src/components/TableSection';
import Line from 'src/components/Line';
import { StyledTerminateButton } from 'src/components/ActiveSessionSection/ActiveSessionSections.styles';
import { GeneralModalKey, SnackbarMessageVariants } from 'src/constants';
import { ActiveSessionItem } from 'src/components/ActiveSessionSection/components';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { revokeTokens } from 'src/services/sso-api';
import { useSnackbarMessage } from 'src/hooks';
import { isCurrentSsoSession } from 'src/services/active-session-helper';
import { ActiveSessionType } from 'src/@types/active-session';
import type { GridColumnProps as BaseGridColumnProps } from 'src/components/TableSection/TableSection';

type ActiveSessionSectionProps = {
    activeSessions?: Array<ActiveSessionType>;
    updateActiveSessionsList: () => Promise<void>;
};

export type GridColumnProps = BaseGridColumnProps<'lastActivity' | 'location' | 'os' | 'browser'>;

const gridColumnParams: GridColumnProps = {
    lastActivity: {
        xs: 4,
        md: 1,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap-reverse',
        column_title: 'Last Activity',
        order: { xs: 1, md: 0 },
        display_title: 'flex',
    },
    location: {
        xs: 4,
        md: 1,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 2,
        order: { xs: 3, md: 0 },
        column_title: 'Location & ip address',
    },
    os: {
        xs: 4,
        md: 1,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 2,
        order: { xs: 4, md: 0 },
        column_title: 'Operating system',
    },
    browser: {
        xs: 4,
        md: 1,
        display: { xs: 'none', md: 'flex' },
        alignItems: 'center',
        paddingRight: 2,
        order: { xs: 5, md: 0 },
        column_title: 'Browser/app',
    },
};

const ActiveSessionSection: FC<ActiveSessionSectionProps> = ({
    activeSessions = [],
    updateActiveSessionsList,
}) => {
    const [terminatingSessions, setTerminatingSessions] = useState<Array<string>>([]);

    const { addMessage } = useSnackbarMessage();
    const { openModal } = useConfirmationModal({
        onConfirm: async () => {
            const refreshTokens = activeSessions
                .filter(({ token }) => !isCurrentSsoSession(token))
                .map(({ token }) => token);

            return revokeTokens(refreshTokens)
                .then(() => {
                    addMessage('All sessions terminated successfully', SnackbarMessageVariants.SUCCESS);
                }).catch(() => {
                    addMessage('Failed to terminate sessions', SnackbarMessageVariants.ERROR);
                }).finally(updateActiveSessionsList);
        },
    });

    const openTerminateAllModal = () => {
        openModal({
            key: GeneralModalKey.confirmationModal,
            title: 'Terminate all sessions',
            extraProps: {
                header: 'Are you sure you want to proceed?',
                text: 'All your sessions except the current one will be terminated.',
                confirmButtonText: 'Proceed',
            },
        });
    };

    const terminateAllButton = (
        <StyledTerminateButton disabled={!!terminatingSessions.length} onClick={openTerminateAllModal}>
          Terminate all
        </StyledTerminateButton>
    );

    return (
        <TableSection
            headerPaddingBottom={2}
            columns={4}
            headerChildren={
                <Box display={{ xs: 'none', md: 'block' }}>
                    {terminateAllButton}
                </Box>
            }
            items={activeSessions}
            gridColumnParams={gridColumnParams}
            sectionHeader="Active sessions"
            isLoading={false}
            renderItem={(session, extraProps) => (
                <ActiveSessionItem
                    session={session}
                    gridColumnParams={extraProps.gridColumnParams}
                    updateActiveSessionsList={updateActiveSessionsList}
                    setTerminatingSessions={setTerminatingSessions}
                />
            )}
        >
            <Stack display={{ xs: 'flex', md: 'none' }} marginBottom={-1} paddingTop={2} spacing={2}>
                <Line />
                <Stack>
                    {terminateAllButton}
                </Stack>
            </Stack>
        </TableSection>
    );
};

export default ActiveSessionSection;
