import styled from 'styled-components';
import { InputAdornment } from '@mui/material';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';
import { StyledTextField } from 'src/components/Inputs/StyledInput.styles';

export const CopyTextField = styled(StyledTextField)`
    && {
        background-color: ${({ theme }) => theme.palette.disabledInput};
        
        & .MuiInputBase-root {
            padding: 0 16px;
            
            & .MuiInputAdornment-root {
                margin-left: 10px;
            }
            
            &:hover {
                background-color: ${({ theme }) => theme.palette.disabledInput};
            }
            
            & .Mui-disabled {
                padding: 0 12px 0 0;
                -webkit-text-fill-color: unset;
            }
            
            fieldset {
                border-color: ${({ theme }) => theme.palette.dirtyWhite};
            }
        }
    }
`;

export const StyledInputAdornment = styled(InputAdornment)`    
    & ${StyledIcon} {
        position: absolute;
        right: 0;
        padding: 0 16px 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${({ theme }) => theme.sizes.inputHeight.default};
        background: ${({ theme }) => theme.palette.disabledInput};
  
        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            height: ${({ theme }) => theme.sizes.inputHeight.xs};
        }
    }
`;
