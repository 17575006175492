import dayjs from 'dayjs';
import { convertDateTimeZone, convertToUTCDate } from 'src/services/date-time-zone-converter';
import type { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import {
    InitialBillingRecordRequest,
    SubscriptionActionParams,
    SubscriptionCreateParams,
} from 'src/@types/subscription-service-api';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import {
    ActivationDate,
    BillingCycleLengthInMonth,
    DATE_TIME_ISO_8601,
    DiscountDuration,
    DiscountType,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';

export const buildCustomAttributes = (
    selectedBillingCycleLength: BillingCycleLengthInMonth,
    numberOfCycles: number,
    selectedBillingType: SubscriptionBillingType,
) => ({
    ...(Number(selectedBillingCycleLength) === BillingCycleLengthInMonth.MONTH && {
        cycleLengthInMonths: Number(selectedBillingCycleLength),
    }),
    ...(selectedBillingType === SubscriptionBillingType.FREE
        && numberOfCycles > 1 && {
        cycleCount: Number(numberOfCycles),
    }),
});

export const buildCreateSubscriptionParams = ({
    selectedSubscriptionType,
    selectedBillingType,
    selectedBillingCycleLength,
    numberOfCycles,
}: Required<TypeAndBillingData>): SubscriptionCreateParams => {
    const customAttributes = buildCustomAttributes(selectedBillingCycleLength, numberOfCycles, selectedBillingType);

    return {
        type: selectedSubscriptionType,
        billingType: selectedBillingType,
        verticalIds: [1, 2],
        ...(!!Object.keys(customAttributes).length && { customAttributes }),
    };
};

export const buildCreateBillingRecordParams = (
    {
        selectedSubscriptionType,
        selectedBillingType,
    }: Required<TypeAndBillingData>,
    pricingTermsData: PricingTermsData,
): Partial<InitialBillingRecordRequest> => {
    let priceInCents = pricingTermsData[selectedSubscriptionType]?.finalPriceInCents;

    if (pricingTermsData.insider?.fixedPriceInCents) {
        priceInCents = pricingTermsData.insider?.fixedPriceInCents;
    }

    return {
        requestedAmountOfUsers: selectedSubscriptionType === SubscriptionType.ENTERPRISE
            ? pricingTermsData[selectedSubscriptionType]!.priceData?.userCount
            : 1,
        priceInCents: selectedBillingType !== SubscriptionBillingType.FREE
            ? priceInCents
            : 0,
    };
};

export const buildActivationParams = ({
    activationDate,
    numberOfCycles,
    selectedActivationDateType,
}: Required<TypeAndBillingData>): SubscriptionActionParams => {
    const isSpecifiedDate = selectedActivationDateType === ActivationDate.SPECIFIED;

    return {
        ...(isSpecifiedDate && { activationDate: convertToUTCDate(activationDate!).format(DATE_TIME_ISO_8601) }),
        ...(numberOfCycles > 1 && { cycleCount: numberOfCycles }),
    };
};

export const shouldUpdateActivationDate = (typeAndBillingData: TypeAndBillingData) => {
    const { activationDate, selectedActivationDateType } = typeAndBillingData;

    return selectedActivationDateType === ActivationDate.SPECIFIED
        && dayjs(activationDate).isAfter(convertDateTimeZone(dayjs()))
        && !!activationDate;
};

export const getDiscountDurationInCycles = (discountDurationType?: DiscountDuration, cycleCount?: number) => {
    if (discountDurationType === DiscountDuration.FOREVER) {
        return null;
    }

    if (discountDurationType === DiscountDuration.FIRST_CYCLE) {
        return 1;
    }

    return cycleCount;
};

export const getDiscountValue = (type?: DiscountType, amount?: number) => (
    type === DiscountType.FIXED && amount ? amount / 100 : amount
);

export const buildUpdateSubscriptionParams = (
    typeAndBillingData: Required<TypeAndBillingData>,
) => {
    const { activationDate } = typeAndBillingData;

    return {
        ...(shouldUpdateActivationDate(typeAndBillingData) && activationDate
            && { activationDate: convertToUTCDate(activationDate).format(DATE_TIME_ISO_8601) }
        ),
    };
};
