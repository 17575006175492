import { Box, List, Typography } from '@mui/material';
import styled from 'styled-components';

export const ManageSubscriptionNotificationWrapper = styled(Box)<{ 'data-allowed'?: boolean }>`
  && {
    background-color: ${({ theme, ...props }) => props['data-allowed'] ? theme.palette.notificationBlue : theme.palette.manageSubscriptionNotificationLightBlue};
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    align-items: flex-start;
  }
`;

export const ManageSubscriptionNotificationTitle = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.bold};
    margin: 0;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
    }
  }  
`;

export const ManageSubscriptionNotificationList = styled(List)`
  && {
    padding: 0;

    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      padding-top: 4px;
    }
        
    .MuiListItem-root{
      padding: 0;
      padding-top: 8px;

      @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
        padding-top: 4px;
      }
            
      .MuiTypography-root{
        font-family: ${({ theme }) => theme.fonts.normal};
        color: ${({ theme }) => theme.palette.black};
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: normal;
          
        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
          font-size: 14px;
        }
      }
    }
  }
`;
