import styled, { css } from 'styled-components';
import { Button } from '@mui/material';
import { MinSizeVariants, ResponsiveMinSize } from './Button';
import { theme as mainTheme } from 'src/theme';

const fontFamilySelector = (props: {
    'data-isbold'?: string;
    theme: typeof mainTheme;
}) => `font-family: ${props['data-isbold'] ? props.theme.fonts.bold : props.theme.fonts.demiBold};`;

const minWidthSelector = (props: {
    'data-min-size'?: MinSizeVariants | ResponsiveMinSize;
    theme: typeof mainTheme;
}) => {
    const getWidth = (size: MinSizeVariants) => {
        switch (size) {
            case 'small':
                return '96px';
            case 'medium':
                return '100px';
            case 'unset':
                return 'unset';
            default:
                return '150px';
        }
    };

    if (typeof props['data-min-size'] !== 'object') {
        return css`
            min-width: ${getWidth(props['data-min-size'] as MinSizeVariants)};
        `;
    }

    const { xs = 'small', sm, md } = props['data-min-size'] as ResponsiveMinSize;

    return css`
        min-width: ${getWidth(xs)};

        @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
            min-width: ${getWidth(sm || xs)};
        }
        @media (min-width: ${({ theme }) => theme.brakePoints.md}) {
            min-width: ${getWidth(md || sm || xs)};
        }
    `;
};

const primary = css`
    background: ${({ theme }) => theme.palette.buttonGradient};
    color: ${({ theme }) => theme.palette.white};
    border: none;

    &:hover {
        filter: saturate(85%);
    }

    &:active {
        filter: saturate(70%);
    }
`;

const secondary = css`
    background: ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.blue};
    border: 2px solid ${({ theme }) => theme.palette.secondaryButton};

    &:hover {
        border: 2px solid ${({ theme }) => theme.palette.secondaryHoverButton};
    }

    &:active {
        border: 2px solid ${({ theme }) => theme.palette.secondaryHoverButton};
        background: ${({ theme }) => theme.palette.secondaryButton};
    }
`;

const grey = css`
    background: ${({ theme }) => theme.palette.mainBackgroundColor};
    color: ${({ theme }) => theme.palette.lightBlack};
    border: 2px solid ${({ theme }) => theme.palette.transparentButtonBorder};

    &:hover {
        border: 2px solid ${({ theme }) => theme.palette.transparentButtonActiveBorder};
    }

    &:active {
        border: 2px solid ${({ theme }) => theme.palette.transparentButtonActiveBorder};
        background: ${({ theme }) => theme.palette.transparentButtonClickBackground};
    }
`;

const variantSelector = (props: unknown & { 'data-variant'?: string }) => {
    switch (props['data-variant']) {
        case 'secondary':
            return secondary;
        case 'grey':
            return grey;
        default:
            return primary;
    }
};

export const StyledButton = styled(Button)`
    &&& {
        text-transform: unset;
        font-size: 16px;
        line-height: 19px;
        height: ${({ theme }) => theme.sizes.inputHeight.default};
        
        & svg {
            margin: 0 8px;
        }

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            height: ${({ theme }) => theme.sizes.inputHeight.xs};
        }

        svg {
            max-height: 18px;
            max-width: 18px;
            fill: currentColor;
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &:focus {
            border: 1px solid ${({ theme }) => theme.palette.white};
            box-sizing: border-box;
            outline: 2px solid ${({ theme }) => theme.palette.blue};
        }

        ${variantSelector}
        ${minWidthSelector}
        ${fontFamilySelector}
    }
`;
