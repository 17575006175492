import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledRow, StyledStack, TabsContainer } from './AttachAnInvoiceModal.styles';
import { AttachExistingInvoice, CreateNewInvoice } from './components';
import { createSubscriptionBillingInvoice } from 'src/services/sso-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import StyledTooltip, { StyledMultilineTooltipBody } from 'src/components/Tooltip';
import { StripeInvoice } from 'src/@types/sso-api';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { updateBillingRecord } from 'src/services/subscription-service-api';
import { DateFormats, SnackbarMessageVariants } from 'src/constants';
import { formatDate } from 'src/services/formatters';
import { BillingRecordModel, BillingRecordWithRenewal } from 'src/@types/subscription-service-api';
import { TabBar } from 'src/components/TabBar';
import Line from 'src/components/Line';
import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';

export type AttachAnInvoiceModalProps = {
    payment: BillingRecordWithRenewal;
};

export enum InvoiceTabType {
    CREATE_A_NEW_ONE = 'CreateANewOne',
    ATTACH_EXISTING = 'AttachExisting',
}

const AttachAnInvoiceModal: FC<AttachAnInvoiceModalProps> = ({ payment }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<StripeInvoice | null>(null);
    const [billingRecord, setBillingRecord] = useState<BillingRecordWithRenewal>(payment);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();
    const {
        priceInCents,
        cycleStart,
        cycleEnd,
        requestedAmountOfUsers,
        domains,
        subscriptionUUID,
        id,
        isPaid,
        stripeInvoiceID,
    } = billingRecord;

    const isCreateInvoiceDisabled = isPaid && !stripeInvoiceID;

    const [selectedTab, setSelectedTab] = useState<InvoiceTabType>(
        isCreateInvoiceDisabled ? InvoiceTabType.ATTACH_EXISTING : InvoiceTabType.CREATE_A_NEW_ONE,
    );

    const handleTabChange = (newValue: string | number) => {
        setSelectedTab(newValue as InvoiceTabType);
    };

    const updateBillingRecordData = (updateData: Partial<BillingRecordModel>) => {
        setIsLoading(true);
        return updateBillingRecord(subscriptionUUID, id, updateData)
            .then((response) => {
                setBillingRecord(response);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSave = (data: Partial<BillingRecordModel>) => {
        return updateBillingRecordData(data);
    };

    const handleAttachInvoice = () => {
        if (!invoiceData) {
            return;
        }

        setIsLoading(true);
        return updateBillingRecordData({
            stripeInvoiceID: invoiceData.id,
            isPaid: invoiceData.paid,
        })
            .then(() => {
                reloadPage();
                closeModal();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCreateInvoice = () => {
        setIsLoading(true);
        return createSubscriptionBillingInvoice(subscriptionUUID, id, priceInCents)
            .then(() => {
                reloadPage();
                closeModal();
            })
            .catch((e) => {
                addMessage(e?.responseError?.data?.error, SnackbarMessageVariants.WARNING);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <StyledStack>
            {isLoading && <LoaderOverlay />}
            <TabsContainer padding={0.5} marginBottom={{ xs: 2.5, sm: 3 }} data-testid="tabs-container">
                <StyledTooltip
                    arrow
                    title="Creating an invoice is not allowed for this billing record"
                    disableHoverListener={!isCreateInvoiceDisabled}
                >
                    <Stack>
                        <TabBar
                            onChange={handleTabChange}
                            initialValue={selectedTab}
                            items={[
                                {
                                    label: 'Create a new one',
                                    value: InvoiceTabType.CREATE_A_NEW_ONE,
                                    disabled: isCreateInvoiceDisabled,
                                },
                                { label: 'Attach existing', value: InvoiceTabType.ATTACH_EXISTING },
                            ]}
                            data-testid="tab-bar"
                        />
                    </Stack>
                </StyledTooltip>
            </TabsContainer>
            <Stack gap={{ xs: 2, md: 2.5 }}>
                <Stack flexDirection="row" justifyContent="space-between">
                    <StyledRow>Billing cycle:</StyledRow>
                    <StyledRow>{`${formatDate(cycleStart, DateFormats.DATE)} - ${formatDate(cycleEnd, DateFormats.DATE)}`}</StyledRow>
                </Stack>
                <Line />
                <Stack flexDirection="row" justifyContent="space-between">
                    <StyledRow>Amount of users:</StyledRow>
                    <StyledRow>{requestedAmountOfUsers || 0}</StyledRow>
                </Stack>
                <Line />
                <Stack flexDirection="row" justifyContent="space-between">
                    <StyledRow>Covered domains:</StyledRow>
                    <Stack flexDirection="row" gap={0.5}>
                        <StyledRow>{domains?.length ?? 0}</StyledRow>
                        {domains?.length ? (
                            <StyledTooltip
                                arrow
                                title={
                                    <StyledMultilineTooltipBody>
                                        {domains.join('\n')}
                                    </StyledMultilineTooltipBody>
                                }
                            >
                                <InfoIcon data-testid="info-icon" />
                            </StyledTooltip>
                        ) : null}
                    </Stack>
                </Stack>
                <Line />
            </Stack>
            <Stack paddingTop={{ xs: 1, md: 0 }}>
                {selectedTab === InvoiceTabType.CREATE_A_NEW_ONE && (
                    <CreateNewInvoice
                        priceInCents={priceInCents}
                        oldPrice={payment.priceInCents}
                        onCreateInvoice={handleCreateInvoice}
                        handleSave={handleSave}
                    />
                )}
                {selectedTab === InvoiceTabType.ATTACH_EXISTING && (
                    <AttachExistingInvoice
                        invoiceData={invoiceData}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setInvoiceData={setInvoiceData}
                        onSearchAgain={() => setInvoiceData(null)}
                        onAttachInvoice={handleAttachInvoice}
                    />
                )}
            </Stack>
        </StyledStack>
    );
};

export default AttachAnInvoiceModal;
