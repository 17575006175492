import React, { FC } from 'react';
import { FinalPriceContainer } from './FinalPriceRow.styles';
import { FinalPriceRowTitle, FinalPriceRowValue } from './index';
import { DiscountType } from 'src/constants';

type FinalPriceRowValueProps = {
    priceInCents: number;
    discountValue?: number | null;
    discountType?: DiscountType | null;
};

const FinalPriceRow: FC<FinalPriceRowValueProps> = ({
    priceInCents,
    discountValue,
    discountType,
}) => {
    return (
        <FinalPriceContainer
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            paddingY={{ xs: 2.5, sm: 3 }}
            paddingX={{ xs: 2, sm: 3 }}
        >
            <FinalPriceRowTitle
                discountType={discountType}
                discountValue={discountValue}
            />
            <FinalPriceRowValue
                priceInCents={priceInCents}
                discountValue={discountValue}
                discountType={discountType}
            />
        </FinalPriceContainer>
    );
};

export default FinalPriceRow;
