import React, { FC, useEffect, useState } from 'react';
import { CustomPricingTermsProps } from 'src/@types/subscription-service-api';
import type {
    EnterprisePricingTermsData,
} from 'src/pages/udb/CreateSubscription/components/EnterprisePricingTerms/EnterprisePricingTerms';
import { EnterprisePricingTerms } from 'src/pages/udb/CreateSubscription/components/EnterprisePricingTerms';
import { BillingCycleLengthInMonth, SubscriptionBillingType, SubscriptionType } from 'src/constants';
import { InsiderPricingTerms } from 'src/pages/udb/CreateSubscription/components/InsiderPricingTerms';
import { StepContainer } from 'src/pages/udb/CreateSubscription/components/StepContainer';

export type PricingTermsData = {
    [SubscriptionType.INSIDER]?: CustomPricingTermsProps & { finalPriceInCents?: number };
    [SubscriptionType.ENTERPRISE]?: EnterprisePricingTermsData;
};

export type PricingTermsStepProps = {
    handleGoBackClick: (data: PricingTermsData) => void;
    handleNextButtonClick: (data: PricingTermsData) => void;
    billingCycleLength: BillingCycleLengthInMonth;
    initialData: PricingTermsData | undefined;
    numberOfCycles: number;
    selectedSubscriptionType: SubscriptionType;
    selectedBillingType: SubscriptionBillingType;
};

export const shouldShowNextButton = ({ insider, enterprise }: PricingTermsData) => {
    const hasInsiderRenewalAllData = !!insider?.discountType
        && insider?.discountDurationInCycles !== undefined
        && !!insider?.discountValue;

    return !!enterprise?.domains.length
        || !!insider?.fixedPriceInCents
        || hasInsiderRenewalAllData
        || insider?.discountValue === null;
};

const PricingTermsStep: FC<PricingTermsStepProps> = ({
    handleGoBackClick,
    handleNextButtonClick,
    billingCycleLength,
    initialData,
    numberOfCycles,
    selectedSubscriptionType,
    selectedBillingType,
}) => {
    const [insiderData, setInsiderData] =
        useState<CustomPricingTermsProps | undefined>(initialData?.[SubscriptionType.INSIDER]);
    const [enterpriseData, setEnterpriseData] =
        useState<EnterprisePricingTermsData | undefined>(initialData?.[SubscriptionType.ENTERPRISE]);

    useEffect(() => {
        setInsiderData(initialData?.[SubscriptionType.INSIDER]);
        setEnterpriseData(initialData?.[SubscriptionType.ENTERPRISE]);
    }, [initialData]);

    return (
        <StepContainer
            handleGoBack={() => {
                handleGoBackClick({
                    [SubscriptionType.INSIDER]: insiderData,
                    [SubscriptionType.ENTERPRISE]: enterpriseData,
                });
            }}
            showGoBackButton
            showNextButton={shouldShowNextButton({
                insider: insiderData,
                enterprise: enterpriseData,
            })}
            title="Pricing terms"
            handleNextButtonClick={() => handleNextButtonClick({
                [SubscriptionType.INSIDER]: insiderData,
                [SubscriptionType.ENTERPRISE]: enterpriseData,
            })}
        >
            {selectedSubscriptionType === SubscriptionType.INSIDER ? (
                <InsiderPricingTerms
                    numberOfCycles={numberOfCycles}
                    initialData={insiderData}
                    billingCycleLength={billingCycleLength}
                    setInsiderData={setInsiderData}
                />
            ) : (
                <EnterprisePricingTerms
                    selectedBillingType={selectedBillingType}
                    initialData={enterpriseData}
                    setEnterpriseData={setEnterpriseData}
                />
            )}
        </StepContainer>
    );
};

export default PricingTermsStep;
