import React, { Dispatch, FC, Fragment, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { Box, BoxProps, Stack } from '@mui/material';
import { discountTypeRadioButtons, getPricingModelRadioButtons } from './services/getters';
import type {
    RenewalPriceAdditionalInfoProps,
} from './components/RenewablePriceAdditionalInfo/RenewablePriceAdditionalInfo';
import { FinalPriceRow } from 'src/components/FinalPriceRow';
import { CustomPricingTermsProps } from 'src/@types/subscription-service-api';
import { Section } from 'src/components/Section';
import { RadioGroup } from 'src/components/Radio';
import { DiscountDuration, DiscountType, PricingModel } from 'src/constants';
import { PercentInput, PriceInput } from 'src/components/Inputs';
import { Checkbox } from 'src/components/Checkbox';
import { StyledCheckboxLabel } from 'src/components/Label/Label.styles';

export type PricingTermsData = CustomPricingTermsProps & {
    finalPriceInCents?: number;
};

type PricingTermsProps = {
    discountDivider?: ReactNode;
    finalPriceDivider?: ReactNode;
    discountDurationPaddingBottom?: BoxProps['paddingBottom'];
    pricingTermsPaddingBottom?: BoxProps['paddingBottom'];
    discountTypePaddingBottom?: BoxProps['paddingBottom'];
    sectionsPaddingBottom?: BoxProps['paddingBottom'];
    sectionsMarginTop?: BoxProps['marginTop'];
    discountDurationBanner?: boolean;
    createInvoiceCheckbox?: boolean;
    finalPriceRowTitle: string;
    isLoading: boolean;
    numberOfCycles?: number;
    billingCycleLength: number;
    productPriceInCents: number;
    customPricingTerms: CustomPricingTermsProps | undefined;
    setPricingTermsData: Dispatch<SetStateAction<CustomPricingTermsProps | undefined>>;
    fieldsProps?: RenewalPriceAdditionalInfoProps['fieldsProps'];
};

export const getInitialDiscountDurationInCycles = (
    discountDuration?: DiscountDuration,
    discountDurationInCycles?: number | null,
): number | null | undefined => {
    if (discountDuration === DiscountDuration.FOREVER) {
        return null;
    }

    if (discountDuration === DiscountDuration.FIRST_CYCLE) {
        return 1;
    }

    if (discountDuration === DiscountDuration.MULTIPLE_CYCLE) {
        return !discountDurationInCycles || discountDurationInCycles === 1 ? 2 : discountDurationInCycles;
    }
};

export const getDiscountDuration = (discountDurationInCycles?: number | null): DiscountDuration | undefined => {
    if (discountDurationInCycles === null) {
        return DiscountDuration.FOREVER;
    }

    if (discountDurationInCycles === 1) {
        return DiscountDuration.FIRST_CYCLE;
    }

    if (discountDurationInCycles && discountDurationInCycles >= 2) {
        return DiscountDuration.MULTIPLE_CYCLE;
    }
};

const PricingTerms: FC<PricingTermsProps> = ({
    isLoading,
    numberOfCycles,
    finalPriceDivider,
    billingCycleLength,
    setPricingTermsData,
    sectionsMarginTop,
    discountDivider,
    productPriceInCents,
    createInvoiceCheckbox,
    finalPriceRowTitle,
    discountTypePaddingBottom,
    pricingTermsPaddingBottom,
    sectionsPaddingBottom,
    discountDurationPaddingBottom,
    discountDurationBanner,
    fieldsProps,
    customPricingTerms = {},
}) => {
    const {
        discountValue = null,
        discountDurationInCycles,
        discountType = null,
        fixedPriceInCents: initialFixedPriceInCents = null,
    } = customPricingTerms;

    const [fixedPriceInCents, setFixedPriceInCents] = useState<number>(initialFixedPriceInCents ?? 0);
    const [cachedDiscountData, setCachedDiscountData] = useState<Omit<PricingTermsData, 'fixedPriceInCents'>>({});

    const [selectedPricingModel, setSelectedPricingModel] = useState<PricingModel | null>();
    const [discountDuration, setDiscountDuration] = useState<DiscountDuration>();
    const [cachedPricingTerms, setCachedPricingTerms] =
        useState<Record<PricingModel, PricingTermsData>>({
            [PricingModel.FIXED_PRICE]: { fixedPriceInCents },
            [PricingModel.RENEWABLE_PRICE]: { discountValue, finalPriceInCents: productPriceInCents },
        });

    const hasDiscount = discountValue !== null;
    const finalPriceInCents = selectedPricingModel === PricingModel.RENEWABLE_PRICE
        ? productPriceInCents
        : fixedPriceInCents;

    const handleDiscountDurationInCyclesChange = (durationInCycles?: number | null) => {
        setPricingTermsData((previousState) => ({
            ...previousState,
            discountDurationInCycles: durationInCycles,
        }));
    };

    useEffect(() => {
        if (!discountDuration) {
            setDiscountDuration(getDiscountDuration(discountDurationInCycles));
        }
    }, [discountDurationInCycles]);

    useEffect(() => {
        if (customPricingTerms.fixedPriceInCents !== undefined) {
            setSelectedPricingModel(PricingModel.FIXED_PRICE);
            return;
        }

        if (customPricingTerms.discountValue !== undefined) {
            setSelectedPricingModel(PricingModel.RENEWABLE_PRICE);
            return;
        }
    }, [customPricingTerms.fixedPriceInCents, customPricingTerms.discountValue]);

    useEffect(() => {
        if (initialFixedPriceInCents) {
            setFixedPriceInCents(initialFixedPriceInCents);
        }
    }, [initialFixedPriceInCents]);

    useEffect(() => {
        if (productPriceInCents) {
            setFixedPriceInCents(productPriceInCents);
            setCachedDiscountData({ discountValue, finalPriceInCents: productPriceInCents });
            setCachedPricingTerms((previousState) => ({
                ...previousState,
                ...(!fixedPriceInCents && { [PricingModel.FIXED_PRICE]: { fixedPriceInCents: productPriceInCents } }),
                [PricingModel.RENEWABLE_PRICE]: { discountValue, finalPriceInCents: productPriceInCents },
            }));
        }
    }, [productPriceInCents]);

    useEffect(() => {
        handleDiscountDurationInCyclesChange(
            getInitialDiscountDurationInCycles(discountDuration, discountDurationInCycles),
        );
    }, [discountDuration]);

    const handlePricingModelChange = (newModel: PricingModel) => {
        setCachedPricingTerms((previousState) => ({
            ...previousState,
            [selectedPricingModel as PricingModel]: customPricingTerms,
        }));

        setPricingTermsData(cachedPricingTerms[newModel]);
        setSelectedPricingModel(newModel);
    };

    const handleDiscountValueChange = (value: number | null) => {
        setPricingTermsData(
            (previousState) => ({
                ...previousState,
                discountValue: value,
            }),
        );
    };

    const handleDiscountTypeChange = (newType: DiscountType) => {
        setPricingTermsData(
            (previousState) => ({
                ...previousState,
                discountType: newType,
            }),
        );
    };

    const getSectionsPaddingBottom = () => {
        if (selectedPricingModel && hasDiscount) {
            return discountDurationPaddingBottom;
        }

        if (selectedPricingModel) {
            return pricingTermsPaddingBottom;
        }
        return sectionsPaddingBottom;
    };

    const handleHasDiscountChange = (value: boolean) => {
        const cachedValue = typeof cachedDiscountData.discountValue !== 'number' ? 0 : cachedDiscountData.discountValue;
        const newDiscountValue = value ? cachedValue : null;

        setCachedDiscountData(customPricingTerms);
        setPricingTermsData({ ...cachedDiscountData, discountValue: newDiscountValue });
    };

    return (
        <Fragment>
            <Section
                marginTop={sectionsMarginTop}
                sectionPaddingBottom={getSectionsPaddingBottom()}
                isLoading={isLoading}
                variant="headless"
                header="Pricing model"
                testId="insider-pricing-model"
            >
                <RadioGroup<PricingModel>
                    selectedValue={selectedPricingModel}
                    onChange={handlePricingModelChange}
                    items={getPricingModelRadioButtons({
                        fieldsProps,
                        discountDurationBanner,
                        finalPriceInCents,
                        numberOfCycles,
                        discountValue,
                        discountDuration,
                        billingCycleLength,
                        selectedPricingModel,
                        discountDurationInCycles,
                        setDiscountDuration,
                        setPrice: (price: number) => setPricingTermsData({ fixedPriceInCents: price }),
                        setHasDiscount: handleHasDiscountChange,
                        setDiscountDurationInCycles: handleDiscountDurationInCyclesChange,
                    })}
                />
            </Section>
            {hasDiscount && (
                <Fragment>
                    {discountDivider}
                    <Section
                        variant="headless"
                        header="Discount type"
                        marginTop={sectionsMarginTop}
                        sectionPaddingBottom={discountTypePaddingBottom}
                    >
                        <RadioGroup
                            selectedValue={discountType}
                            items={discountTypeRadioButtons}
                            onChange={handleDiscountTypeChange}
                            containerProps={{
                                direction: 'row',
                                spacing: 0,
                                columnGap: { xs: 5, sm: 8 },
                                rowGap: 2,
                                flexWrap: 'wrap',
                                maxWidth: { xs: '330px', sm: '100%' },
                            }}
                        />
                        {discountType && (
                            <Box maxWidth="110px" paddingTop={2}>
                                {discountType === DiscountType.PERCENTAGE ? (
                                    <PercentInput
                                        value={discountValue}
                                        onChange={handleDiscountValueChange}
                                    />
                                ) : (
                                    <PriceInput
                                        maxPrice={finalPriceInCents}
                                        initialPrice={discountValue * 100}
                                        handleChange={(valueInCents) => (
                                            handleDiscountValueChange(valueInCents / 100)
                                        )}
                                        currency="$"
                                    />
                                )}
                            </Box>
                        )}
                    </Section>
                </Fragment>
            )}
            {selectedPricingModel && (
                <Fragment>
                    {finalPriceDivider}
                    <Section
                        variant="headless"
                        header={finalPriceRowTitle}
                        marginTop={sectionsMarginTop}
                        sectionPaddingBottom={sectionsPaddingBottom}
                    >
                        <Stack spacing={2}>
                            <FinalPriceRow
                                discountType={discountType}
                                discountValue={discountValue}
                                priceInCents={finalPriceInCents}
                            />
                            {createInvoiceCheckbox && (
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={{ xs: 1.5, sm: 2 } }
                                >
                                    <Checkbox />
                                    <StyledCheckboxLabel>
                                        Create invoice
                                    </StyledCheckboxLabel>
                                </Stack>
                            )}
                        </Stack>
                    </Section>
                </Fragment>
            )}
        </Fragment>
    );
};

export default PricingTerms;
