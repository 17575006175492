import React, { FC, useEffect } from 'react';
import { TextAdornment } from 'src/components/Inputs/components/InputAdornment/InputAdornment.styles';
import { makePlural } from 'src/services/text-modifier';
import { TextInput } from 'src/components/Inputs/TextInput';

export type DurationInputProps = {
    value?: number | null;
    onChange: (value: number) => void;
    disabled?: boolean;
    adornmentText?: string;
    minValue?: number;
};

const DurationInput: FC<DurationInputProps> = ({
    disabled,
    value,
    onChange,
    adornmentText,
    minValue = 1,
}) => {
    useEffect(() => {
        const numberValue = Number(value);

        if (Number.isNaN(numberValue) || numberValue < minValue) {
            onChange(minValue);
        }
    }, [value, minValue]);

    return (
        <TextInput
            type="number"
            disabled={disabled}
            autoSelect
            value={value ?? ''}
            onBlur={() => {
                if (!value || value < minValue) {
                    onChange(minValue);
                }
            }}
            onChange={({ target }) => {
                const newValue = Number(target.value);
                onChange(newValue);
            }}
            InputProps={{
                inputProps: {
                    'data-testid': 'duration-input',
                    step: 1,
                    min: minValue,
                },
                endAdornment: adornmentText && (
                    <TextAdornment paddingRight={2}>
                        {makePlural(adornmentText, Number(value))}
                    </TextAdornment>
                ),
            }}
        />
    );
};

export default DurationInput;
