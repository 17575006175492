import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import { StyledLabel } from './InvoiceIdInput.styles';
import { parseInvoiceId } from 'src/services/parser';
import { useSnackbarMessage } from 'src/hooks';
import { SnackbarMessageVariants } from 'src/constants';
import { Button } from 'src/components/Buttons';
import { invoiceIdValidator } from 'src/services/validators';
import { TextInput } from 'src/components/Inputs/TextInput';
import { StyledErrorMessage, StyledFieldContainer } from 'src/components/Inputs/StyledInput.styles';
import { getStripeInvoice } from 'src/services/sso-api';
import { StripeInvoice } from 'src/@types/sso-api';

type InvoiceIdInputProps = {
    setInvoiceData: (invoice: StripeInvoice | null) => void;
    setIsLoading: (isLoading: boolean) => void;
    paddingBottom?: BoxProps['paddingBottom'];
    paddingTop?: BoxProps['paddingTop'];
    isLoading: boolean;
    label?: string;
};

const InvoiceIdInput: FC<InvoiceIdInputProps> = ({
    paddingBottom,
    paddingTop,
    setInvoiceData,
    setIsLoading,
    isLoading,
    label,
}) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [invoiceId, setInvoiceId] = useState<string>('');
    const [error, setError] = useState<string | null>(null);

    const { addMessage } = useSnackbarMessage();

    const handleRetrieveInvoice = () => {
        invoiceIdValidator.validate(invoiceId)
            .then(() => {
                setError(null);
                setIsLoading(true);

                return getStripeInvoice(parseInvoiceId(invoiceId))
                    .then(setInvoiceData)
                    .catch(() => addMessage('Failed to load invoice data', SnackbarMessageVariants.WARNING))
                    .finally(() => {
                        setIsLoading(false);
                    });
            })
            .catch((validationError) => {
                setError(validationError.message);
                setInvoiceData(null);
            });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInvoiceId(e.target.value);

        if (error) {
            setError(null);
        }
    };

    return (
        <StyledFieldContainer paddingTop={paddingTop} paddingBottom={paddingBottom}>
            {label && <StyledLabel>{label}</StyledLabel>}
            <Stack direction="row" spacing={1}>
                <TextInput
                    name="stripeInvoiceID"
                    placeholder="Invoice ID/URL"
                    value={invoiceId}
                    onChange={handleInputChange}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    className={error && !isFocused ? 'invalid' : ''}
                    InputProps={{
                        inputProps: {
                            'data-testid': 'invoice-id-input',
                        },
                    }}
                />
                <Button minSize={{ xs: 'small', md: 'default' }} onClick={handleRetrieveInvoice} disabled={isLoading}>
                Retrieve
                </Button>
            </Stack>
            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </StyledFieldContainer>
    );
};

export default InvoiceIdInput;
