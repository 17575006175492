import React, { FC, useState } from 'react';
import { EditSocialMediaModalContent } from './components';
import { makeSocialMediaConfirmMessages } from './components/SocialMediaConfirmMessages';
import { ConfirmationMessages, ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { BaseModal } from 'src/components/Modals/BaseModal';
import {
    socialLinkedInValidator,
    socialXValidator,
    validateSchemaObject,
    websiteValidator,
} from 'src/services/validators';
import { updateUser } from 'src/services/unified-db-api';
import { SnackbarMessageVariants } from 'src/constants';
import { useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { FormWrapper } from 'src/components/Forms/components';

export type EditSocialMediaModalProps = {
    website?: string;
    socialLinkedIn?: string;
    socialX?: string;
    uuid: string;
};

export type EditSocialMediaModalFormValues = {
    website: string;
    socialLinkedIn: string;
    socialX: string;
};

const EditSocialMediaModal: FC<EditSocialMediaModalProps> = ({
    uuid,
    socialX = '',
    socialLinkedIn = '',
    website = '',
}) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [updateUserData, setUpdateUserData] = useState<Partial<EditSocialMediaModalFormValues>>({});
    const [isConfirming, setIsConfirming] = useState<boolean>(false);

    const { isSubmitDisabled, onFormChangeHandler, forceChangeValues } =
        useIsSubmitDisabled<EditSocialMediaModalFormValues>({ socialX, socialLinkedIn, website });
    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();

    const messages = makeSocialMediaConfirmMessages(updateUserData, { socialX, socialLinkedIn, website });

    const handleCloseConfirmModal = () => {
        setIsConfirming(false);
    };

    const handleConfirm = () => {
        setIsSubmitting(true);

        updateUser(uuid, {
            customAttributes: updateUserData,
        })
            .then(() => {
                addMessage('User updated successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
            })
            .catch(() => {
                setIsConfirming(false);
                addMessage('Failed to update User', SnackbarMessageVariants.ERROR);
            });
    };

    const handleSubmit = (data: EditSocialMediaModalFormValues) => {
        setUpdateUserData(data);
        setIsConfirming(true);
    };

    if (isConfirming) {
        return (
            <BaseModal
                step={1}
                handleGoBack={handleCloseConfirmModal}
                open={true}
                handleClose={handleCloseConfirmModal}
                title="Confirm changes"
            >
                <ConfirmationModal
                    modalId="confirm-edit-social-media-modal"
                    subtitle={<ConfirmationMessages messages={messages} />}
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    onConfirm={handleConfirm}
                    onCancel={handleCloseConfirmModal}
                />
            </BaseModal>
        );
    }

    return (
        <FormWrapper<EditSocialMediaModalFormValues>
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            initialValues={{
                website: website,
                socialX: socialX,
                socialLinkedIn: socialLinkedIn,
            }}
            validationSchema={validateSchemaObject({
                socialX: socialXValidator.optional(),
                socialLinkedIn: socialLinkedInValidator.optional(),
                website: websiteValidator.optional(),
            })}
            onSubmit={handleSubmit}
        >
            <EditSocialMediaModalContent
                forceChangeValues={forceChangeValues}
                isSubmitDisabled={isSubmitDisabled || isSubmitting}
                updatedFormValues={updateUserData}
            />
        </FormWrapper>
    );
};

export default EditSocialMediaModal;
