import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import { StyledButton } from 'src/components/Buttons/Button/Button.styles';

export const StripeCustomerTitle = styled(Typography)`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.grey};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const RetrieveStripeCustomerInputWithButtonWrapper = styled(Stack)`
    && {
        ${StyledButton} {
            margin-left: 8px;
        }
    }
`;
