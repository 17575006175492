import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';
import { StyledIcon } from 'src/components/CopyIcon/CopyIcon.styles';

export const StyledInfo = styled(Typography)`
  && {
    gap: 8px;  
    font-family: ${({ theme }) => theme.fonts.normal};
    color: ${({ theme }) => theme.palette.black};
    display: flex;
    align-items: center;  
    min-height: 40px;  
    font-size: 16px;
    font-style: normal;
    line-height: normal;
    font-weight: 450;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
      min-height: 24px;
    }

    &:hover ${StyledIcon} {
        opacity: 1;
    }
  }
`;

export const StyledDomain = styled(Typography)`
  && {
    font-family: ${({ theme }) => theme.fonts.medium};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
      
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: 14px;
    }
  }
`;

export const StyledSocialContainer = styled.a`
  color: ${({ theme }) => theme.palette.snackbar.infoFont};
  font-family: ${({ theme }) => theme.fonts.medium};
  line-height: normal;
  display: flex;
  text-decoration: none;
  align-items: center;
  height: 40px;
  gap: 8px;
  max-width: 100%;
    
  svg{
      width: 16px;
  }  
    
  @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
    height: 24px;
    font-size: 14px  
  }
`;

export const StyledStack = styled(Stack)`
    &:hover ${StyledIcon} {
        opacity: 1;
    }
`;
