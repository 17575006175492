import React, { FC, type ReactElement, useState } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import PageTitle from 'src/components/PageTitle';
import { Button } from 'src/components/Buttons';
import { useGeneralModal } from 'src/hooks';
import { LoaderOverlay } from 'src/components/LoaderOverlay';

export type ConfirmationModalProps = {
    header?: string;
    modalId: string;
    text?: string;
    subtitle?: string | ReactElement;
    confirmButtonText?: string;
    cancelButtonText?: string;
    buttonsDirection?: StackProps['direction'];
    onConfirm?: (hideLoaderFn: () => void) => void;
    onCancel?: () => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
    header,
    text,
    subtitle,
    confirmButtonText = 'Confirm',
    cancelButtonText = 'Cancel',
    modalId,
    buttonsDirection = 'row',
    onConfirm,
    onCancel,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { updateModal } = useGeneralModal();

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }
        updateModal({
            extraProps: { canceledModalId: modalId },
        });
    };

    const handleConfirm = () => {
        setIsLoading(true);
        if (onConfirm) {
            onConfirm(() => setIsLoading(false));
        }
        updateModal({
            closeIsBlocked: true,
            extraProps: { confirmedModalId: modalId },
        });
    };

    const modalHeaderPaddingTop = header ? { xs: 0, sm: 1 } : 0;

    return (
        <Box maxWidth={DEFAULT_MODAL_MAX_WIDTH} data-testid="confirmation-modal">
            {isLoading && <LoaderOverlay />}
            <PageTitle
                title={header}
                subtitle={subtitle}
                variant="form"
                data-modal
                paddingTop={modalHeaderPaddingTop}
            >
                <Box>
                    <div dangerouslySetInnerHTML={{ __html: text || '' }} />
                </Box>
            </PageTitle>
            <Stack direction={buttonsDirection} spacing={{ xs: 2, sm: 2.5 }} marginTop={3}>
                <Button onClick={handleCancel} minSize="small" variant="secondary" fullWidth>{cancelButtonText}</Button>
                <Button onClick={handleConfirm} minSize="small" fullWidth testId="confirm-button">{confirmButtonText}</Button>
            </Stack>
        </Box>
    );
};
