import { FC, useEffect, useState } from 'react';
import type { TypeAndBillingData } from './components/TypeAndBillingStep/TypeAndBillingStep';
import { PricingTermsData } from './components/PricingTermsStep/PricingTermsStep';
import { creationStepController } from './services/creation-step-controller';
import { User } from 'src/@types/unified-db-api';

export enum ActiveStep {
    TYPE_AND_BILLING = 'typeAndBilling',
    PRICING_TERMS = 'pricingTerms',
    ACCOUNT_OWNER = 'accountOwner',
    CONFIRMATION = 'confirmation',
}

const CreateSubscriptionPage: FC = () => {
    const activeStepState = useState<ActiveStep>(ActiveStep.TYPE_AND_BILLING);

    const typeAndBillingState = useState<Required<TypeAndBillingData>>();
    const pricingTermsState = useState<PricingTermsData>({});
    const ownerState = useState<User>();

    const [typeAndBilling] = typeAndBillingState;
    const [, setPricingTermsState] = pricingTermsState;

    useEffect(() => {
        setPricingTermsState({});
    }, [typeAndBilling?.selectedSubscriptionType]);

    return creationStepController({
        ownerState,
        activeStepState,
        pricingTermsState,
        typeAndBillingState,
    });
};

export default CreateSubscriptionPage;
