import React, { FC, useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { LinkContainer } from 'src/components/AssociatedLinkSection/AssociatedLinkSection.styles';
import StyledTooltip from 'src/components/Tooltip';
import { buildStripeCustomerHref, buildStripeSubscriptionHref } from 'src/services/stripe';
import Line from 'src/components/Line';
import { getBillingTypeLabel, getSubscriptionPaymentStatus } from 'src/services/subscription-service-getters';
import { SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { BadgeProps } from 'src/components/Badge/Badge/Badge';
import { BadgeVariant } from 'src/components/Badge/Badge/Badge.styles';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { Badge, RoundedBadge } from 'src/components/Badge';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import {
    SecondaryTitle,
    StyledGrid,
    StyledStack,
    SubscriptionInfo,
} from 'src/components/SubscriptionManageHeader/ManageHeader.styles';
import { Accordion } from 'src/components/Accordion';
import { DateFormats, roundedBadgePropsMapping, SubscriptionStatus, SubscriptionType } from 'src/constants';
import { formatDate } from 'src/services/formatters';
import { ManageActions } from 'src/components/ManageActions';
import { ReactComponent as StripeLinkIcon } from 'src/assets/icons/stripe-link-icon.svg';

type SubscriptionManageHeaderProps = {
    subscription: SubscriptionModel;
    managers: SubscriptionUserModel[];
    actionsList: Record<string, ManageActionType>;
    isUdb?: boolean;
};

const badgePropsMapping: Record<SubscriptionType, BadgeProps> = {
    [SubscriptionType.INSIDER]: {
        label: 'Insider',
        variant: BadgeVariant.BLUE,
    },
    [SubscriptionType.ENTERPRISE]: {
        label: 'Enterprise',
        variant: BadgeVariant.GOLD,
    },
};

const SubscriptionManageHeader: FC<SubscriptionManageHeaderProps> = ({
    subscription,
    actionsList,
    managers,
    isUdb,
}) => {
    const [owner, setOwner] = useState<SubscriptionUserModel | null>(null);

    useEffect(() => {
        const ownerData = managers.find((manager) => manager.isOwner);
        if (ownerData) {
            setOwner(ownerData);
        }
    }, [managers, owner]);

    const { stripeSubscriptionId, stripeCustomerId } = subscription.customAttributes || {};

    const paymentStatus = getSubscriptionPaymentStatus(subscription);
    const isDraft = subscription.status === SubscriptionStatus.DRAFT;
    const isAutoRenewal = subscription.billingType === 'autoRenewal';
    const displayedOwnerInfo = subscription.type === SubscriptionType.INSIDER
        ? owner?.fullName
        : owner?.companyName;

    return (
        <StyledGrid data-testid="subscription-header" container alignItems="flex-start">
            <Grid item xs={12} sm={9}>
                <StyledStack direction="row" gap={1}>
                    <SecondaryTitle display={{ xs: 'none', md: 'block' }}>{displayedOwnerInfo}</SecondaryTitle>
                    <StyledTooltip
                        placement="bottom-start"
                        arrow
                        title={displayedOwnerInfo}
                        display={{ xs: 'block', md: 'none' }}
                    >
                        <SecondaryTitle>{displayedOwnerInfo}</SecondaryTitle>
                    </StyledTooltip>
                    <Badge {...badgePropsMapping[subscription.type]} />
                    <RoundedBadge {...roundedBadgePropsMapping[subscription.status]} />
                </StyledStack>
                {!isDraft && (
                    <>
                        <StyledStack direction="row" display={{ xs: 'none', md: 'flex' }} marginTop={0.5}>
                            <SubscriptionInfo>
                                {`Subscription period:
                                    ${formatDate(subscription.activationDate, DateFormats.FULL)}
                                     -
                                    ${formatDate(subscription.expirationDate)}`}
                            </SubscriptionInfo>
                            {paymentStatus && (
                                <RoundedBadge label={paymentStatus} variant={RoundedBadgeVariant.OUTLINED} />
                            )}
                        </StyledStack>

                        {isUdb && (
                            <StyledStack direction="row" display={{ xs: 'none', md: 'flex' }} marginTop={0.5}>
                                <SubscriptionInfo>
                                    {`End of grace period: ${formatDate(subscription.gracePeriodEndDate)}`}
                                </SubscriptionInfo>
                            </StyledStack>
                        )}
                        <StyledStack display={{ xs: 'flex', md: 'none' }} gap={1.5} marginTop={1.5} alignItems="flex-start">
                            <Accordion
                                id="subscription-period"
                                title="Subscription period"
                            >
                                <SubscriptionInfo>
                                    {`${formatDate(subscription.activationDate, DateFormats.FULL)}
                                     - 
                                    ${formatDate(subscription.expirationDate)}`}
                                </SubscriptionInfo>
                            </Accordion>
                            {paymentStatus && (
                                <RoundedBadge label={paymentStatus} variant={RoundedBadgeVariant.OUTLINED} />
                            )}
                            {isUdb && (
                                <Accordion
                                    id="grace-period"
                                    title="End of grace period"
                                >
                                    <SubscriptionInfo>
                                        {formatDate(subscription.gracePeriodEndDate)}
                                    </SubscriptionInfo>
                                </Accordion>
                            )}
                        </StyledStack>
                    </>
                )}
            </Grid>
            <Grid
                item
                container
                xs={3}
                justifyContent="flex-end"
                position={{ xs: 'absolute', sm: 'relative' }}
                top={0}
                right={0}
            >
                <ManageActions
                    uuid={subscription.uuid}
                    subscription={subscription}
                    managers={managers}
                    actionsList={actionsList}
                    isUdb={isUdb}
                />
            </Grid>
            <Box flexGrow={1}>
                <Stack paddingY={1.5}><Line /></Stack>
                <SubscriptionInfo>
                    Billing type: <span>{getBillingTypeLabel(subscription.billingType)}&nbsp;</span>
                    {stripeSubscriptionId && isAutoRenewal && isUdb && (
                        <LinkContainer
                            target="_blank"
                            data-testid="stripe-link"
                            href={buildStripeSubscriptionHref(stripeSubscriptionId)}
                        >
                            Open in Stripe
                            <StripeLinkIcon width="16px" />
                        </LinkContainer>
                    )}
                </SubscriptionInfo>
                {stripeCustomerId && isUdb && (
                    <LinkContainer
                        href={buildStripeCustomerHref(stripeCustomerId)}
                        target="_blank"
                    >
                        Open associated customer in Stripe
                        <StripeLinkIcon width="16px" />
                    </LinkContainer>
                )}
            </Box>
        </StyledGrid>
    );
};

export default SubscriptionManageHeader;
