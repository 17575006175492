import { FormControlLabel, Radio, Stack } from '@mui/material';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)`
    &&&& {
        padding: 0;

        svg {
            width: 18px;
            height: 18px;

            @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
                width: 22px;
                height: 22px;
            }
        }
    }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
    &&& {
        margin: 0;
        max-width: fit-content;

        & .MuiTypography-root {
            color: ${({ theme }) => theme.palette.black};
            font-family: ${({ theme }) => theme.fonts.normal};
            font-size: 16px;
            font-style: normal;
            font-weight: 450;
            line-height: 18px;
            min-width: max-content;
        }

        &.Mui-disabled {
            & .MuiButtonBase-root {
                &.Mui-checked {
                    svg {
                        rect {
                            fill: #CBCED7;
                        }
                    }
                }

                svg {
                    rect {
                        stroke: #F2F2F2;
                    }
                }
            }

            & .MuiTypography-root {
                color: ${({ theme }) => theme.palette.lightBlack};
            }
        }
    }
`;

export const ContainerFormControlLabel = styled(Stack)`
    &&& {
        & .MuiFormControlLabel-root {
            & .MuiButtonBase-root {
                margin-right: 16px;
            }
        }

        & .MuiTypography-root {
            color: ${({ theme }) => theme.palette.black};
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }
`;
