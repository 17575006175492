import styled from 'styled-components';
import { Box, Stack, Typography } from '@mui/material';

export const StyledStack = styled(Stack)`
  && {
    color: ${({ theme }) => theme.palette.black};
    font-family: ${({ theme }) => theme.fonts.medium};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (min-width: ${({ theme }) => theme.brakePoints.sm}) {
        font-size: 16px;
    }
  }
`;

export const StyledRow = styled(Typography)`
  && {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    
    @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
      font-size: inherit;
    }
  }
`;

export const TabsContainer = styled(Box)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.mainBackgroundColor};
    
  & .MuiTabs-root {
    min-height: unset;
    height: 42px;
    align-items: center;
      
    & .MuiTabs-scroller {
      & .MuiTabs-flexContainer {
        justify-content: space-between;
              
        & .MuiTab-root {
          font-size: 16px;
          min-width: 50%;
        }
      }
    }
  }
    
  @media(max-width: ${({ theme }) => theme.brakePoints.sm}){
    max-width: unset;
      
    & .MuiTabs-root {
      height: 32px;
        
      & .MuiTabs-scroller {
        & .MuiTabs-flexContainer {
          & .MuiTab-root {
            padding: 7px 16px;
            min-height: unset;
            font-size: 14px;
            width: 50%;
          }
        }
      }
    }
  }
`;
