import React, { FC, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { FormWrapper } from 'src/components/Forms/components';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { LabeledInput } from 'src/components/Inputs';
import { createAndAssignStripeCustomer } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { emailValidator, fullNameValidator, validateSchemaObject } from 'src/services/validators';

type CreateStripeCustomerFormProps = {
    name: string;
    email: string;
    subscriptionUUID: string;
};

type CreateStripeCustomerFormData = {
    name: string;
    email: string;
};

const CreateStripeCustomerForm: FC<CreateStripeCustomerFormProps> = ({ name, email, subscriptionUUID }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const {
        onFormChangeHandler,
        isSubmitDisabled,
    } = useIsSubmitDisabled<CreateStripeCustomerFormData>({ name, email }, ['name', 'email'], true);

    const handleSubmit = async (values: CreateStripeCustomerFormData) => {
        setIsSubmitting(true);
        return createAndAssignStripeCustomer(subscriptionUUID, values)
            .then(() => {
                addMessage('Stripe customer created successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch((e) => {
                addMessage(e?.responseError?.data?.error ?? 'Failed to create Stripe customer', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            initialValues={{ name, email }}
            validationSchema={validateSchemaObject({
                name: fullNameValidator.required(),
                email: emailValidator.required(),
            })}
        >
            <Stack data-testid="create-stripe-customer-form">
                <Grid container spacing={{ xs: 2.5, sm: 4 }} marginBottom={{ xs: 3, sm: 6 }}>
                    <Grid item xs={12}>
                        <LabeledInput
                            disallowSpace
                            disabled={isSubmitting}
                            name="name"
                            label="Name"
                            placeholder="Enter Stripe customer name"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledInput
                            disallowSpace
                            disabled={isSubmitting}
                            name="email"
                            label="Email"
                            placeholder="Enter Stripe customer email"
                        />
                    </Grid>
                </Grid>
                <Button
                    fullWidth
                    data-testid="customer-submit-button"
                    disabled={isSubmitDisabled || isSubmitting}
                    type="submit"
                >
                    Create customer
                </Button>
            </Stack>
        </FormWrapper>
    );
};

export default CreateStripeCustomerForm;
