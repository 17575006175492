import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { JobInformationSection } from './components/JobInformationSection';
import { SocialMediaSection } from './components/SocialMediaSection';
import { ManageUserHeader } from './components/ManageUserHeader';
import { ContactInformationSection } from './components/ContactInformationSection';
import { SubscriptionsSection } from './components/SubscriptionsSection';
import { getManageUserActionsList } from './services/action-schema';
import type { NewsletterSchemaParams, NewsLetterSetSchema } from 'src/@types/newsletters-set';
import { checkChangeEmailStatusForUser, requestChangeEmailForUser } from 'src/services/sso-api';
import { makeDeliveryPreferences } from 'src/services/udb-delivery-preferences';
import { AccessibleSubscription, SubscriptionModel } from 'src/@types/subscription-service-api';
import {
    getAccessibleSubscriptions,
    getSubscription,
    getUserTypeByVertical,
} from 'src/services/subscription-service-api';
import { useCanEditUser } from 'src/hooks/useCanEditUser';
import { User } from 'src/@types/unified-db-api';
import { formattedContactInfo } from 'src/services/user-data-formatter';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';
import { ChangeEmailCheckData, ProfileEmailDeliveryPreferences } from 'src/@types/sso-api';
import { NewslettersDelivery } from 'src/components/NewslettersDelivery';
import { Section } from 'src/components/Section';
import { APIClientResponseHTTPError } from 'src/@types/api-client';
import { BasicLayout } from 'src/layouts';
import PageTitle from 'src/components/PageTitle';
import { NotFoundPage } from 'src/pages/NotFound';
import { ServerErrorPage } from 'src/pages/ServerError';
import { Spinner } from 'src/components/Spinner';
import { GENERAL_VERTICAL_ID, SnackbarMessageVariants, SubscriberType } from 'src/constants';
import { fetchAvailableNewsletterList, getUser } from 'src/services/unified-db-api';
import ChangeEmailNotification from 'src/components/ChangeEmailNotification';

const newsLetterSchema: NewsletterSchemaParams = {
    subtitle: undefined,
    editIcon: true,
};

const newsLetterSetSchema: NewsLetterSetSchema = {
    biopharma: newsLetterSchema,
    healthcare: newsLetterSchema,
    general_communications: {
        ...newsLetterSchema,
        gridColumnParams: {
            newsletters: {
                md: 4.75,
            },
            date: {
                display: 'none',
            },
        },
    },
    pageColumnParams: {
        newsletters: {
            md: 3,
            display_title: 'block',
        },
        date: {
            md: 1.25,
        },
        sampleIssue: {
            display: 'none',
        },
    },
};

const ManageUserPage: FC = () => {
    const [error, setError] = useState<APIClientResponseHTTPError>();
    const [preferences, setPreferences] = useState<ProfileEmailDeliveryPreferences>();
    const [activeSubscription, setActiveSubscription] = useState<SubscriptionModel>();
    const [changeEmailCheckData, setChangeEmailCheckData] = useState<ChangeEmailCheckData>();
    const [manageUserType, setManageUserType] = useState<SubscriberType>();
    const [manageUser, setManageUser] = useState<User>();
    const [subscriptions, setSubscriptions] = useState<AccessibleSubscription[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { pageReloadCount } = useReloadPage();
    const { uuid = '' } = useParams<{ uuid: string }>();
    const canEditUser: boolean = useCanEditUser(uuid);
    const { closeModal } = useGeneralModal();

    const fetchChangeEmailStatus = () => {
        return checkChangeEmailStatusForUser(uuid)
            .then(setChangeEmailCheckData)
            .catch(() => undefined);
    };

    const handleResendEmail = async () => {
        if (!changeEmailCheckData?.newEmail) {
            return;
        }

        setIsLoading(true);
        await requestChangeEmailForUser(changeEmailCheckData.newEmail, uuid)
            .then(() => fetchChangeEmailStatus())
            .then(() => {
                addMessage('Confirmation email has been re-sent', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to re-send confirmation email', SnackbarMessageVariants.ERROR);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setManageUser(undefined);
        getUser(uuid)
            .then(async (manageUserData) => {
                await Promise.all([
                    fetchAvailableNewsletterList()
                        .catch(() => {
                            addMessage('Failed to fetch available newsletter list', SnackbarMessageVariants.ERROR);
                        }),
                    getUserTypeByVertical(uuid)
                        .catch(() => {
                            addMessage('Failed to fetch user type by vertical', SnackbarMessageVariants.ERROR);
                        }),
                    getAccessibleSubscriptions(uuid)
                        .catch(() => {
                            addMessage('Failed to fetch subscriptions', SnackbarMessageVariants.ERROR);
                        }),
                    fetchChangeEmailStatus(),
                ])
                    .then(async ([
                        availableNewsletterList,
                        subscriberTypeByVertical = [],
                        subscriptionsData,
                    ]) => {
                        const subscriberData = subscriberTypeByVertical
                            ?.find(({ verticalId }) => verticalId === GENERAL_VERTICAL_ID);

                        setManageUserType(subscriberData?.type);

                        if (subscriptionsData) {
                            setSubscriptions(subscriptionsData);
                        }

                        if (availableNewsletterList) {
                            const preferencesData = makeDeliveryPreferences(
                                availableNewsletterList,
                                manageUserData,
                                subscriberTypeByVertical,
                            );

                            setPreferences(preferencesData);
                        }

                        if (subscriberData?.subscriptionUUID) {
                            await getSubscription(subscriberData.subscriptionUUID)
                                .then(setActiveSubscription)
                                .catch(() => {
                                    addMessage('Failed to fetch subscription data', SnackbarMessageVariants.ERROR);
                                });
                        }
                    });

                return manageUserData;
            })
            .then(setManageUser)
            .catch(({ responseError }) => {
                setError(responseError);
            })
            .finally(closeModal);
    }, [uuid, pageReloadCount]);

    if (error) {
        return [404, 403].includes(error.data?.status as number) ? <NotFoundPage /> : <ServerErrorPage />;
    }

    if (!manageUser) {
        return <Spinner />;
    }

    return (
        <BasicLayout testId="manage-user-uuid-page">
            <Box position="relative">
                <PageTitle title="Manage user" marginBottom={{ xs: 2.5, sm: 3 }} />
                <ChangeEmailNotification
                    isLoading={isLoading}
                    changeEmailStatus={changeEmailCheckData}
                    handleResend={canEditUser ? handleResendEmail : undefined}
                />
                <ManageUserHeader
                    subscriberType={manageUserType}
                    manageUser={manageUser}
                    isEditable={canEditUser}
                    actionsList={getManageUserActionsList(canEditUser)}
                    activePaidSubscription={activeSubscription}
                />
                <ContactInformationSection
                    contactsInformation={formattedContactInfo(manageUser)}
                    changeEmailStatus={changeEmailCheckData}
                    isEditable={canEditUser}
                    uuid={uuid}
                />
                <JobInformationSection
                    isEditable={canEditUser}
                    uuid={uuid}
                    jobInformation={manageUser.jobInfo || undefined}
                />
                <SocialMediaSection
                    socialMedia={manageUser.customAttributes}
                    isEditable={canEditUser}
                    uuid={uuid}
                />
                <SubscriptionsSection subscriptions={subscriptions} />
                {preferences && (
                    <Section
                        data-testid="newsletters-section"
                        sectionPaddingX={0}
                        sectionPaddingBottom={0}
                        sectionPaddingTop={0}
                        header="Newsletters"
                    >
                        <NewslettersDelivery
                            isEditable={canEditUser}
                            preferences={preferences}
                            newsLetterSetSchema={newsLetterSetSchema}
                            isEmailDisabled
                            isSMSDisabled
                        />
                    </Section>
                )}
            </Box>
        </BasicLayout>
    );
};

export default ManageUserPage;
