import React, { FC, Fragment, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { AddedDomainsLabel, ConfirmMessage } from './AddDomainModal.styles';
import { DomainInfoBox } from 'src/components/DomainInfoBox';
import { makePlural } from 'src/services/text-modifier';
import { attachDomains } from 'src/services/subscription-service-api';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { DATA_GRID_NO_ROWS_MESSAGE, SnackbarMessageVariants } from 'src/constants';
import { ConfirmationModal } from 'src/components/Modals/ConfirmationModal';
import { Button } from 'src/components/Buttons';
import { BaseModal, DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import { DomainInfoModel, type DomainModel } from 'src/@types/subscription-service-api';
import { AddDomainModalForm } from 'src/components/Forms';
import Line from 'src/components/Line';
import Table from 'src/components/Table';

export type AddDomainModalProps = {
    subscriptionUUID: string;
    verticalIds: number[];
    domains: DomainModel[];
};

const AddDomainModal: FC<AddDomainModalProps> = ({ subscriptionUUID, verticalIds, domains: subscriptionDomains }) => {
    const [domains, setDomains] = useState<DomainInfoModel[]>([]);
    const [shouldConfirm, setShouldConfirm] = useState<boolean>(false);

    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();
    const { reloadPage } = useReloadPage();

    const removeDomain = (domainName: string) => {
        setDomains(domains.filter(({ domain }) => domain !== domainName));
    };

    const addDomains = () => {
        setShouldConfirm(true);
    };

    const confirmChanges = async () => {
        const attachedDomains = domains.map(({ domain }) => domain);

        return attachDomains(subscriptionUUID, { domains: attachedDomains })
            .then(() => {
                reloadPage();
                closeModal();
                addMessage(
                    `${attachedDomains.length} ${makePlural('domain', attachedDomains.length)} added successfully`,
                    SnackbarMessageVariants.SUCCESS,
                );
            })
            .catch(() => {
                setShouldConfirm(false);
                addMessage(
                    `Failed to add ${attachedDomains.length} ${makePlural('domain', attachedDomains.length)}`,
                    SnackbarMessageVariants.ERROR,
                );
            });
    };

    if (shouldConfirm) {
        const domainsCount = domains.length;
        const usersCount = domains.reduce<number>((count, { userCount }) => count + userCount, 0);

        const columns = [
            { field: 'domain', headerName: 'Domain name', flex: 1 },
            { field: 'userCount', headerName: 'Users' },
        ];

        return (
            <BaseModal
                open
                step={1}
                handleClose={closeModal}
                handleGoBack={() => setShouldConfirm(false)}
                title={`Confirm new ${makePlural('domain', domainsCount)}`}
            >
                <ConfirmationModal
                    modalId="add-domain-modal"
                    onConfirm={confirmChanges}
                    onCancel={() => setShouldConfirm(false)}
                    subtitle={(
                        <Box>
                            <ConfirmMessage>
                                Are you sure you want to add the following&nbsp;
                                <b>{domainsCount} {makePlural('domain', domainsCount)}</b>&nbsp;
                                with a total of <b>{usersCount} {makePlural('user', usersCount)}</b>?
                            </ConfirmMessage>
                            <Table
                                hideFooter
                                rowCount={1}
                                rows={domains}
                                minDataRows={1}
                                columns={columns}
                                disableColumnMenu
                                marginTop="16px"
                                getRowId={(row) => row.domain}
                                noRowsOverlayMessage={DATA_GRID_NO_ROWS_MESSAGE}
                            />
                        </Box>
                    )}
                />
            </BaseModal>
        );
    }

    return (
        <Fragment>
            <AddDomainModalForm
                domains={domains}
                paddingX={DEFAULT_MODAL_PADDING_X}
                setDomains={setDomains}
                verticalIds={verticalIds}
                subscriptionDomains={subscriptionDomains}
                subscriptionUUID={subscriptionUUID}
            />
            {!!domains.length && (
                <Box paddingTop={{ xs: 3, sm: 4.5 }}>
                    <Line />
                    <Box paddingX={DEFAULT_MODAL_PADDING_X}>
                        <AddedDomainsLabel paddingTop={3} paddingBottom={0.5}>
                            {makePlural('Domain', domains.length)} to be added
                        </AddedDomainsLabel>
                        <Stack spacing={1.5} paddingBottom={3}>
                            {domains.map((domain) => (
                                <DomainInfoBox
                                    removeDomain={removeDomain}
                                    key={domain.domain}
                                    domain={domain}
                                />
                            ))}
                        </Stack>
                        <Button onClick={addDomains} fullWidth testId="add-domain-button">
                            Add {makePlural('domain', domains.length)}
                        </Button>
                    </Box>
                </Box>
            )}
        </Fragment>
    );
};

export default AddDomainModal;
