import React, { FC, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { UserInfo } from 'src/components/Forms/components/UserInfo';
import { RetrieveStripeCustomerInputWithButtonWrapper, StripeCustomerTitle } from 'src/components/Modals/AssignStripeCustomerModal/AssignStripeCustomerModal.styles';
import { FormWrapper } from 'src/components/Forms/components';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Button, TextButton } from 'src/components/Buttons';
import { LabeledInput } from 'src/components/Inputs';
import { stripeCustomerIdValidator, validateSchemaObject } from 'src/services/validators';
import { assignStripeCustomer, getStripeCustomerInfo } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { StripeCustomerInfo } from 'src/@types/sso-api';
import { parseStripeCustomerId } from 'src/services/parser';

type AssignStripeCustomerFormProps = {
    subscriptionUUID: string;
};

type AssignStripeCustomerFormData = {
    customerId: string;
};

const AssignStripeCustomerForm: FC<AssignStripeCustomerFormProps> = ({ subscriptionUUID }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [customerInfo, setCustomerInfo] = useState<StripeCustomerInfo>();

    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const {
        onFormChangeHandler,
        isSubmitDisabled,
    } = useIsSubmitDisabled<AssignStripeCustomerFormData>({ customerId: '' });

    const handleSubmitCustomerSearch = async ({ customerId }: AssignStripeCustomerFormData) => {
        setIsSubmitting(true);

        return getStripeCustomerInfo(parseStripeCustomerId(customerId))
            .then((data) => setCustomerInfo(data))
            .catch((e) => {
                addMessage(
                    e?.responseError?.data?.error ?? 'Failed to find Stripe customer',
                    SnackbarMessageVariants.ERROR,
                );
            })
            .finally(() => setIsSubmitting(false));
    };

    const handleAssignCustomer = async () => {
        if (!customerInfo?.id) {
            return;
        }

        setIsSubmitting(true);

        return assignStripeCustomer(subscriptionUUID, customerInfo.id)
            .then(() => {
                addMessage('Stripe customer assigned successfully', SnackbarMessageVariants.SUCCESS);
                reloadPage();
                closeModal();
            })
            .catch((e) => {
                addMessage(
                    e?.responseError?.data?.error ?? 'Failed to assign Stripe customer',
                    SnackbarMessageVariants.ERROR,
                );
            })
            .finally(() => setIsSubmitting(false));
    };

    return (
        <Grid container spacing={{ xs: 2.5, sm: 4 }} data-testid="assign-stripe-customer-form">
            <Grid item xs={12}>
                {customerInfo ? (
                    <>
                        <Box marginBottom={3}>
                            <StripeCustomerTitle marginBottom={0.5}>
                                Customer
                                <TextButton
                                    data-testid="search-again-button"
                                    onClick={() => setCustomerInfo(undefined)}
                                >
                                    Search again
                                </TextButton>
                            </StripeCustomerTitle>
                            <UserInfo
                                name={customerInfo.name}
                                additionalRows={[customerInfo.email, `Customer ID: ${customerInfo.id}`]}
                            />
                        </Box>
                        <Button
                            data-testid="customer-assign-button"
                            disabled={isSubmitDisabled || isSubmitting}
                            onClick={handleAssignCustomer}
                            fullWidth
                        >
                            Assign customer
                        </Button>
                    </>
                ) : (
                    <FormWrapper
                        onFormChange={onFormChangeHandler}
                        isSubmitting={isSubmitting}
                        onSubmit={handleSubmitCustomerSearch}
                        initialValues={{ customerId: '' }}
                        validationSchema={validateSchemaObject({
                            customerId: stripeCustomerIdValidator.required(),
                        })}
                    >
                        <RetrieveStripeCustomerInputWithButtonWrapper
                            spacing={{ xs: 2, sm: 1 }}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-end"
                        >
                            <LabeledInput
                                disallowSpace
                                disabled={isSubmitting}
                                name="customerId"
                                label="Customer"
                                placeholder="Customer ID/URL"
                                flexGrow={1}
                            />
                            <Button
                                data-testid="customer-retrieve-button"
                                disabled={isSubmitDisabled || isSubmitting}
                                type="submit"
                                minSize={{ xs: 'medium', sm: 'default' }}
                            >
                                Retrieve
                            </Button>
                        </RetrieveStripeCustomerInputWithButtonWrapper>
                    </FormWrapper>
                )}
            </Grid>
        </Grid>
    );
};

export default AssignStripeCustomerForm;
