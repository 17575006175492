import React, { FC } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { GridColumnProps } from './ManagerSection';
import { EmailTitle, FullNameTitle, JobTitle, StyledUserIcon } from './ManagerItem.styles';
import { Scope, useUserCan } from 'src/hooks/useUserCan';
import { StyledGridItem } from 'src/components/CopyIcon/CopyIcon.styles';
import { CopyIcon } from 'src/components/CopyIcon';
import { ActionButton, ActionButtonVariant } from 'src/components/Buttons/ActionButton';
import StyledTooltip from 'src/components/Tooltip';
import { SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { RoundedBadge } from 'src/components/Badge';
import { useSnackbarMessage } from 'src/hooks';
import { useConfirmationModal } from 'src/hooks/useConfirmationModal';
import { ReactComponent as RedCrossIcon } from 'src/assets/icons/red-cross-icon.svg';
import {
    DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE,
    editableSubscriptionStatuses,
    GeneralModalKey,
    SnackbarMessageVariants,
    SubscriptionStatus,
    TABLE_SECTION_DEFAULT_COLUMNS,
} from 'src/constants';
import { removeManagers } from 'src/services/subscription-service-api';
import { Link } from 'src/components/Link';

type ManagerProps = {
    gridColumnParams: GridColumnProps;
    manager?: SubscriptionUserModel;
    showIcon?: boolean;
    subscription: SubscriptionModel;
    updateManagersList?: () => void;
    hasUserLink?: boolean;
};

export const getRemoveButtonTooltipText = (status: SubscriptionStatus, isOwner: boolean) => {
    if (!editableSubscriptionStatuses.includes(status)) {
        return DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE;
    }

    if (isOwner) {
        return 'Removal of the owner is not allowed';
    }
};

const ManagerItem: FC<ManagerProps> = ({
    manager,
    gridColumnParams,
    showIcon = false,
    updateManagersList,
    subscription,
    hasUserLink,
}) => {
    if (!manager) {
        return null;
    }

    const { userUUID, fullName, isOwner, jobTitle, email } = manager;
    const { addMessage } = useSnackbarMessage();
    const canManage = useUserCan(Scope.SUBSCRIPTIONS_SERVICE_WRITE);

    const { openModal } = useConfirmationModal({
        onConfirm: async () => {
            removeManagers(subscription.uuid, { managers: [userUUID] })
                .then(() => {
                    addMessage(`${fullName} has been successfully removed from managers`, SnackbarMessageVariants.SUCCESS);
                    if (updateManagersList) {
                        updateManagersList();
                    }
                })
                .catch(() => {
                    addMessage('Failed to remove manager', SnackbarMessageVariants.ERROR);
                });
        },
    });

    const handleRemoveClick = () => {
        openModal({
            key: GeneralModalKey.confirmationModal,
            title: 'Remove manager',
            extraProps: {
                text: `Are you sure you want to remove <b>${manager?.fullName}</b> from managers of this subscription?`,
                confirmButtonText: 'Remove',
            },
        });
    };

    const isDisabledRemove = isOwner || !editableSubscriptionStatuses.includes(subscription.status);

    return (
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                <StyledGridItem item {...gridColumnParams.fullName}>
                    {hasUserLink && (
                        <Link outlineOffset="-1px" display="flex" target="_blank" href={`/udb/users/${userUUID}`}>
                            <StyledTooltip arrow title="Open user page">
                                <StyledUserIcon />
                            </StyledTooltip>
                        </Link>
                    )}
                    <FullNameTitle>{fullName}</FullNameTitle>
                    <RoundedBadge label={manager?.isOwner ? 'Owner' : 'Manager'} />
                    <CopyIcon content={fullName} hiddenIcon />
                </StyledGridItem>
                <StyledGridItem item {...gridColumnParams.jobTitle}>
                    <JobTitle>{jobTitle}</JobTitle>
                    {jobTitle && <CopyIcon content={jobTitle} hiddenIcon />}
                </StyledGridItem>
                <StyledGridItem item {...gridColumnParams.email}>
                    <EmailTitle>{email}</EmailTitle>
                    <CopyIcon content={email} hiddenIcon />
                </StyledGridItem>
            </Grid>
            {showIcon && canManage && (
                <Stack position="absolute">
                    <StyledTooltip
                        title={getRemoveButtonTooltipText(subscription.status, isOwner)}
                        data-max-width="unset"
                        arrow
                    >
                        <Box>
                            <ActionButton
                                disabled={isDisabledRemove}
                                data-attribute-to-change="stroke"
                                data-variant={ActionButtonVariant.RED}
                                data-testid="remove-icon"
                                onClick={handleRemoveClick}
                            >
                                <RedCrossIcon />
                            </ActionButton>
                        </Box>
                    </StyledTooltip>
                </Stack>
            )}
        </Stack>
    );
};

export default ManagerItem;
