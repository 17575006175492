import { stripeCustomerIdRegex } from './validators';

export const parseDomain = (url: string): string => {
    try {
        const { hostname } = new URL(url);
        return hostname;
    } catch (error) {
        return url;
    }
};

export const parseTwitterHandle = (url: string): string | null => {
    const match = url.match(/(?:twitter|x)\.com\/([a-zA-Z0-9_]+)/);
    return match ? `@${match[1]}` : null;
};

export const parseInvoiceId = (invoiceId: string): string => {
    const match = invoiceId.match(/in_\w{24,26}/);
    return match ? match[0] : invoiceId;
};

export const parseStripeCustomerId = (customerId: string): string => {
    const result = stripeCustomerIdRegex.exec(customerId);
    return result ? result[0] : '';
};
