import { InitialBillingRecordRequest } from 'src/@types/subscription-service-api';
import { JobInfo } from 'src/@types/unified-db-api';
import { ProfileInfo, ProfileInfoSocial } from 'src/@types/profile-info';
import { RequestAQuoteInfo } from 'src/@types/reques-a-quote';

type PrepareProfileInfoData = Partial<Omit<ProfileInfo, 'social'> & ProfileInfoSocial>;
type PrepareRequestAQuoteInfoData = Partial<RequestAQuoteInfo>;
type PrepareJobInfoInfoData = Partial<JobInfo>;

const prepareNullableValue = (
    name: string,
    value: string | undefined | null,
    initialValue: string | undefined | null = '',
    targetType: 'string' | 'number' = 'string',
) => {
    const typeConverters = {
        'string': String,
        'number': Number,
    };
    const converter = typeConverters[targetType];

    if (typeof value === 'string' && value !== initialValue) {
        return {
            [name]: value ? converter(value) : null,
        };
    }
};

export const prepareJobInfoRequestData = (
    data: Partial<PrepareJobInfoInfoData>,
    initialValues: Partial<JobInfo>,
): Partial<JobInfo> => {
    const {
        companyName,
        industryId,
        jobTitle,
        jobFunctionId,
    } = data;

    return {
        ...prepareNullableValue('industryId', industryId?.toString(), initialValues?.industryId?.toString(), 'number'),
        ...prepareNullableValue('jobFunctionId', jobFunctionId?.toString(), initialValues?.jobFunctionId?.toString(), 'number'),
        ...(typeof companyName === 'string' && companyName !== initialValues.companyName && { companyName }),
        ...(typeof jobTitle === 'string' && jobTitle !== initialValues.jobTitle && { jobTitle }),
    };
};

export const prepareProfileInfoRequestData = (
    data: Partial<PrepareProfileInfoData>,
    initialValues: ProfileInfo,
): Partial<ProfileInfo> => {
    const {
        fullName = '',
        company,
        industryId,
        country,
        jobTitle,
        jobFunctionId,
        socialLinkedIn,
        socialX,
        website,
    } = data;
    const social = {
        ...prepareNullableValue('socialLinkedIn', socialLinkedIn, initialValues?.social?.socialLinkedIn),
        ...prepareNullableValue('socialX', socialX, initialValues?.social?.socialX),
        ...prepareNullableValue('website', website, initialValues?.social?.website),
    };

    return {
        ...prepareNullableValue('country', country, initialValues?.country),
        ...(fullName && fullName !== initialValues.fullName && { fullName }),
        ...prepareNullableValue('industryId', industryId?.toString(), initialValues?.industryId?.toString(), 'number'),
        ...prepareNullableValue('jobFunctionId', jobFunctionId?.toString(), initialValues?.jobFunctionId?.toString(), 'number'),
        ...(typeof company === 'string' && company !== initialValues.company && { company }),
        ...(typeof jobTitle === 'string' && jobTitle !== initialValues.jobTitle && { jobTitle }),
        ...(Object.keys(social).length && { social }),
    };
};

export const prepareRequestAQuoteInfoRequestData = (
    data: Partial<PrepareRequestAQuoteInfoData>,
    initialValues: RequestAQuoteInfo,
): Partial<RequestAQuoteInfo> => {
    const {
        fullName = '',
        email,
        phoneNumber,
        company,
        jobTitle,
        numberOfEmployees,
        primaryDomain,
        message,
    } = data;
    return {
        ...(fullName && fullName !== initialValues.fullName && { fullName }),
        ...(typeof email === 'string' && email !== initialValues.email && { email }),
        ...prepareNullableValue('phoneNumber', phoneNumber, initialValues?.phoneNumber),
        ...(typeof company === 'string' && company !== initialValues.company && { company }),
        ...(typeof jobTitle === 'string' && jobTitle !== initialValues.jobTitle && { jobTitle }),
        ...prepareNullableValue('numberOfEmployees', numberOfEmployees?.toString(), initialValues?.numberOfEmployees?.toString(), 'number'),
        ...(typeof primaryDomain === 'string' && primaryDomain !== initialValues.primaryDomain && { primaryDomain }),
        ...prepareNullableValue('message', message, initialValues?.message),
    };
};

export const prepareBillingRequestData = (
    data: Partial<InitialBillingRecordRequest>,
    initialValues: Partial<InitialBillingRecordRequest>,
): Partial<InitialBillingRecordRequest> => {
    const {
        priceInCents,
        requestedAmountOfUsers,
        contractLink,
        contractSigned,
        stripeInvoiceID,
        isPaid,
    } = data;

    return {
        ...prepareNullableValue('priceInCents', priceInCents?.toString(), initialValues?.priceInCents?.toString(), 'number'),
        ...prepareNullableValue('requestedAmountOfUsers', requestedAmountOfUsers?.toString(), initialValues?.requestedAmountOfUsers?.toString(), 'number'),
        ...prepareNullableValue('contractLink', contractLink, initialValues?.contractLink),
        ...(typeof contractSigned === 'boolean' && contractSigned !== initialValues.contractSigned && { contractSigned }),
        ...(typeof stripeInvoiceID === 'string' && stripeInvoiceID !== initialValues.stripeInvoiceID && { stripeInvoiceID }),
        ...(typeof isPaid === 'boolean' && isPaid !== initialValues.isPaid && { isPaid }),
    };
};
