import React, { FC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { StyledHeaderName } from 'src/components/TableSection/HeaderSection/HeaderSection.styles';
import { StyledInfo } from 'src/pages/udb/ManageUser/ManageUser.styles';
import { CopyIcon } from 'src/components/CopyIcon';

type JobInformationItemProps = {
    title: string;
    value?: string | null;
    gridProps: GridProps;
};

const JobInformationItem: FC<JobInformationItemProps> = ({ title, value, gridProps }) => (
    <Grid item {...gridProps}>
        <StyledHeaderName>{title}</StyledHeaderName>
        <StyledInfo>
            {value}
            {value && <CopyIcon content={value} hiddenIcon />}
        </StyledInfo>
    </Grid>
);

export default JobInformationItem;
