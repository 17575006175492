import styled from 'styled-components';
import { Stack, Typography } from '@mui/material';

export const StyledInvoiceText = styled(Typography)`
    && {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 16px;
        
        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;

export const StyledSearchText = styled.span`
    && {
        line-height: normal;
        color: ${({ theme }) => theme.palette.blue};
        font-family: ${({ theme }) => theme.fonts.medium};
        font-size: 14px;
        cursor: pointer;
    }
`;

export const StyledStack = styled(Stack)`
    && {
        border-radius: 4px;
        padding: 16px 24px 16px 16px;
        background-color: ${({ theme }) => theme.palette.white};
        border: 1px solid ${({ theme }) => theme.palette.borderGrey};
    }
`;

export const Title = styled(Typography)`
    && {
        gap: 8px;
        display: flex;
        align-items: center;
        line-height: normal;
        color: ${({ theme }) => theme.palette.black};
        font-family: ${({ theme }) => theme.fonts.demiBold};
        font-size: 16px;
    }
`;

export const InvoiceInfo = styled(Typography)`
    && {
        font-size: 14px;
        line-height: normal;
        color: ${({ theme }) => theme.palette.lightBlack};
        font-family: ${({ theme }) => theme.fonts.normal};

        @media(max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 12px;
        }
    }
`;
