import React, { FC, useEffect, useState } from 'react';
import { Tab, type TabProps } from '@mui/material';
import { StyledTabs, TabDivider } from 'src/components/TabBar/TabBar.styles';

export enum TabBarVariants {
    SWITCHER = 'switcher',
    TABS = 'tabs',
    SECONDARY_TABS = 'secondary-tabs',
}

type TabBarItem = Pick<TabProps, 'label' | 'value' | 'onClick' | 'disabled' | 'icon' | 'iconPosition'> & {
    extra?: Record<string, string>;
};

type TabBarProps = {
    items: TabBarItem[];
    initialValue?: string | number;
    onChange?: (newValue: string | number, item?: TabBarItem) => void;
    testId?: string;
    floatingFirstChildPosition?: boolean;
    mobileProfileTab?: boolean;
    variant?: TabBarVariants;
};

const TabBar: FC<TabBarProps> = ({
    items,
    initialValue,
    mobileProfileTab,
    onChange, testId = 'tab-bar',
    floatingFirstChildPosition,
    variant = TabBarVariants.TABS,
}) => {
    const [tabValue, setTabValue] = useState<number | string | undefined>(initialValue);

    const firstChildIsNotSelected = tabValue !== items[0]?.value;

    useEffect(() => {
        if (initialValue !== tabValue) {
            setTabValue(initialValue);
        }
    }, [initialValue]);

    const handleChange = (event: React.SyntheticEvent, newValue: number | string) => {
        setTabValue(newValue);
        if (onChange) {
            onChange(newValue, items.find(({ value }) => value === newValue));
        }
    };

    return (
        <StyledTabs
            variant="scrollable"
            scrollButtons={false}
            data-variant={variant}
            value={tabValue}
            onChange={handleChange}
            data-testid={testId}
            data-mobile-profile-tab={mobileProfileTab}
            data-floating-container={firstChildIsNotSelected && floatingFirstChildPosition}
        >
            {items.map(({ extra, ...item }, index) => {
                const isCurrentTabInactive = tabValue !== item.value;
                const isPreviousTabInactive = index !== 0 && tabValue !== items[index - 1].value;
                const tabElement = <Tab key={`${testId}-tab-${index}`} data-testid={`${testId}-tab-${index}`} disableRipple {...item} />;

                if (isCurrentTabInactive && isPreviousTabInactive) {
                    return [<TabDivider key={`divider-${index}`} />, tabElement];
                }
                return [tabElement];
            })}
        </StyledTabs>
    );
};

export default TabBar;
