import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useReloadPage } from 'src/hooks/useReloadPage';
import { Section } from 'src/components/Section';
import { ChangePasswordForm } from 'src/components/Forms/ProfileForms/ChangePasswordForm';
import { ActiveSessionSection } from 'src/components/ActiveSessionSection';
import { Spinner } from 'src/components/Spinner';
import { getTokenCollection } from 'src/services/sso-api';
import { getActiveSessionsList } from 'src/services/active-session-helper';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';
import { ActiveSessionType } from 'src/@types/active-session';
import { TwoFactorAuthForm } from 'src/components/TwoFactorAuthForm';

const ProfileSecurity: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [activeSessions, setActiveSessions] = useState<Array<ActiveSessionType>>([]);

    const { addMessage } = useSnackbarMessage();
    const { pageReloadCount } = useReloadPage();
    const hasCurrentSession = activeSessions.length === 0 || activeSessions.some((item) => item.isCurrent);

    const updateActiveSessionsList = (): Promise<void> => {
        return getTokenCollection()
            .then(getActiveSessionsList)
            .then(setActiveSessions)
            .catch(() => {
                addMessage('Failed to fetch active sessions', SnackbarMessageVariants.ERROR);
            });
    };

    useEffect(() => {
        updateActiveSessionsList()
            .finally(() => setIsLoading(false));
    }, [pageReloadCount, hasCurrentSession]);

    if (isLoading) {
        return (
            <Spinner />
        );
    }

    return (
        <Box data-testid="profile-security-container">
            <Section header="Change password">
                <ChangePasswordForm />
            </Section>
            <Section header="Two-factor authentication">
                <TwoFactorAuthForm />
            </Section>
            <ActiveSessionSection
                activeSessions={activeSessions}
                updateActiveSessionsList={updateActiveSessionsList}
            />
        </Box>
    );
};

export default ProfileSecurity;
