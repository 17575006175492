import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { InvoiceIdInput } from 'src/components/Inputs/InvoiceIdInput';
import { CheckboxWithLabel } from 'src/components/CheckboxWithLabel';
import { InvoiceInfoBox } from 'src/components/InvoiceInfoBox';
import { StripeInvoice } from 'src/@types/sso-api';
import { PriceFormInput } from 'src/components/Inputs/PriceFormInput';
import { InvoiceStatus, SubscriptionBillingType } from 'src/constants';
import { prepareBillingRequestData } from 'src/services/request-data-formatter';
import { createInitialBillingRecord } from 'src/services/subscription-service-api';
import { InitialBillingRecordRequest } from 'src/@types/subscription-service-api';
import {
    contractLinkValidator,
    numberOfPriceValidator,
    numberOfRequestedUserValidator,
    validateSchemaObject,
} from 'src/services/validators';
import { SignedText } from 'src/components/Modals/ContractDetailsModal/ContractDetailsModal.styles';
import { SwitchAdornment } from 'src/components/Inputs/Adornments/Switch';
import { LabeledInput } from 'src/components/Inputs';
import { useGeneralModal, useIsSubmitDisabled, useReloadPage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { FormWrapper } from 'src/components/Forms/components';

export type CreateInitialPaymentRecordModalProps = {
    uuid: string;
    billingType: SubscriptionBillingType;
};

type BillingRecordFormValues = Partial<InitialBillingRecordRequest> & {
    priceInCents: string;
    requestedAmountOfUsers: string;
};

const CreateInitialPaymentRecordModal: FC<CreateInitialPaymentRecordModalProps> = ({ uuid, billingType }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<StripeInvoice | null>(null);
    const [createInvoiceChecked, setCreateInvoiceChecked] = useState<boolean>(false);
    const [attachExistingInvoiceChecked, setAttachExistingInvoiceChecked] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isFree = billingType === SubscriptionBillingType.FREE;

    const initialValues = {
        priceInCents: '',
        requestedAmountOfUsers: '',
        contractLink: '',
        contractSigned: false,
        stripeInvoiceID: '',
        isPaid: false,
    } as BillingRecordFormValues;

    const {
        onFormChangeHandler,
        isSubmitDisabled,
        setIsSubmitDisabled,
    } = useIsSubmitDisabled(initialValues, !isFree ? ['priceInCents'] : undefined);
    const { reloadPage } = useReloadPage();
    const { closeModal } = useGeneralModal();

    const handleSaveInitialRecord = async (
        values: BillingRecordFormValues,
    ) => {
        setIsSubmitting(true);
        setIsSubmitDisabled(true);

        const requestData = prepareBillingRequestData(
            {
                ...values,
                stripeInvoiceID: invoiceData?.id,
                isPaid: invoiceData?.status === InvoiceStatus.PAID,
            },
            initialValues,
        );

        return createInitialBillingRecord(uuid, requestData)
            .then(() => {
                closeModal();
                reloadPage();
            })
            .finally(() => setIsSubmitting(false));
    };

    const handleCreateInvoiceChange = () => {
        setCreateInvoiceChecked(!createInvoiceChecked);
    };

    const handleAttachExistingInvoiceChange = () => {
        setAttachExistingInvoiceChecked(!attachExistingInvoiceChecked);
    };

    const handleSearchAgain = () => {
        setInvoiceData(null);
    };

    return (
        <FormWrapper
            onFormChange={onFormChangeHandler}
            testId="initial-billing-record-form"
            isSubmitting={isSubmitting || isLoading}
            initialValues={{
                ...initialValues,
                priceInCents: isFree ? 0 : initialValues.priceInCents,
            } as BillingRecordFormValues}
            onSubmit={handleSaveInitialRecord}
            validationSchema={validateSchemaObject({
                priceInCents: numberOfPriceValidator,
                requestedAmountOfUsers: numberOfRequestedUserValidator,
                contractLink: contractLinkValidator,
            })}
        >
            <Stack>
                <PriceFormInput
                    testId="price-in-cents"
                    name="priceInCents"
                    label="Price"
                    placeholder=""
                    disabled={isFree}
                    fromForm={!isFree}
                    required={!isFree}
                />
                <LabeledInput
                    marginTop={{ xs: 2.5, md: 4 }}
                    type="number"
                    name="requestedAmountOfUsers"
                    testId="requested-amount-of-users"
                    label="Requested amount of users"
                    placeholder=""
                />
                <Stack marginTop={{ xs: 2.5, md: 4 }} position="relative">
                    <SignedText>Signed</SignedText>
                    <LabeledInput
                        disallowSpace
                        flexGrow={1}
                        placeholder="Add contract link"
                        name="contractLink"
                        label="Contract link"
                        testId="contract-link-input"
                        endAdornment={<SwitchAdornment />}
                    />
                </Stack>
                <CheckboxWithLabel
                    testId="create-invoice-checkbox"
                    checked={createInvoiceChecked}
                    onClick={handleCreateInvoiceChange}
                    label="Create invoice"
                    disabled={attachExistingInvoiceChecked}
                    paddingBottom={{ xs: 1, md: 2 }}
                    paddingTop={3}
                />
                <CheckboxWithLabel
                    testId="attach-existing-invoice-checkbox"
                    checked={attachExistingInvoiceChecked}
                    onClick={handleAttachExistingInvoiceChange}
                    label="Attach existing invoice"
                    disabled={createInvoiceChecked}
                />
                {attachExistingInvoiceChecked && !invoiceData && (
                    <InvoiceIdInput
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        setInvoiceData={setInvoiceData}
                        paddingTop={{ xs: 2, md: 2.5 }}
                        paddingBottom={{ xs: 0, md: 3 }}
                    />
                )}
                {invoiceData && attachExistingInvoiceChecked && (
                    <InvoiceInfoBox invoiceData={invoiceData} onSearchAgain={handleSearchAgain} />
                )}
                <Stack marginTop={3} direction="row" spacing={{ xs: 2, sm: 2.5 }}>
                    <Button onClick={closeModal} minSize="small" variant="secondary" fullWidth>
                        Cancel
                    </Button>
                    <Button disabled={isSubmitting || (!isFree && isSubmitDisabled)} minSize="small" fullWidth type="submit">
                        Create
                    </Button>
                </Stack>
            </Stack>
        </FormWrapper>
    );
};

export default CreateInitialPaymentRecordModal;
