import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { UserInfoName, UserInfoText, UserInfoWrapper } from './UserInfo.styles';
import { RoundedBadge } from 'src/components/Badge';

type UserInfoProps = {
    name: string;
    badgeLabel?: string;
    additionalRows: string[];
};

const UserInfo: FC<UserInfoProps> = ({ name, additionalRows, badgeLabel }) => {
    return (
        <UserInfoWrapper padding={2} paddingRight={3}>
            <Stack
                direction="row"
                alignItems="center"
                marginBottom={0.5}
            >
                <UserInfoName marginRight={1}>{name}</UserInfoName>
                {badgeLabel && <RoundedBadge label={badgeLabel} />}
            </Stack>
            {additionalRows && additionalRows.map((row) => (
                <UserInfoText key={row}>{row}</UserInfoText>
            ))}
        </UserInfoWrapper>
    );
};

export default UserInfo;
