import React, { FC } from 'react';
import { Box } from '@mui/material';
import { StyledDiscountInfo, StyledPriceLabel } from './FinalPriceRow.styles';
import { formatDiscount } from 'src/services/formatters';
import { DiscountType } from 'src/constants';

type FinalPriceRowTitleProps = {
    discountType?: DiscountType | null;
    discountValue?: number | null;
};

export const FinalPriceRowTitle: FC<FinalPriceRowTitleProps> = ({
    discountType,
    discountValue,
}) => {
    const shouldRenderDiscount = discountType
        && !!discountValue
        && !Number.isNaN(discountValue);

    return (
        <Box>
            <StyledPriceLabel variant="caption">
                Final price
                {shouldRenderDiscount && (
                    <StyledPriceLabel display={{ xs: 'none', sm: 'inline' }}>
                        &nbsp;({formatDiscount(discountValue, discountType)} discount applied)
                    </StyledPriceLabel>
                )}:
            </StyledPriceLabel>
            {shouldRenderDiscount && (
                <StyledDiscountInfo display={{ xs: 'block', sm: 'none' }}>
                    {formatDiscount(discountValue, discountType)} discount applied
                </StyledDiscountInfo>
            )}
        </Box>
    );
};

export default FinalPriceRowTitle;
