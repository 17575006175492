import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import { CreateStripeCustomerForm, AssignStripeCustomerForm } from 'src/components/Forms/AssignStripeCustomerModalForms/';
import { TabsContainer } from 'src/components/Modals/QRCodeModal/QRCodeModal.styles';
import { DEFAULT_MODAL_MAX_WIDTH } from 'src/components/Modals/BaseModal';
import { SubscriptionUserModel } from 'src/@types/subscription-service-api';
import { TabBar } from 'src/components/TabBar';
import { SubscriptionType } from 'src/constants';
import { TabBarVariants } from 'src/components/TabBar/TabBar';

export type AssignStripeCustomerModalProps = {
    type: SubscriptionType;
    subscriptionManagers: SubscriptionUserModel[];
    subscriptionUUID: string;
};

export enum AssignStripeCustomerType {
    CREATE_NEW = 'createNew',
    ASSIGN_EXISTING = 'assignExisting',
}

const AssignStripeCustomerModal: FC<AssignStripeCustomerModalProps> = ({
    type,
    subscriptionManagers,
    subscriptionUUID,
}) => {
    const [selectedTab, setSelectedTab] = useState<AssignStripeCustomerType>(AssignStripeCustomerType.CREATE_NEW);

    const owner = subscriptionManagers.find((manager) => manager.isOwner);
    const defaultName = type === 'enterprise' ? owner?.companyName : owner?.fullName;

    const handleTabChange = (newValue: string | number) => {
        setSelectedTab(newValue as AssignStripeCustomerType);
    };

    return (
        <Stack maxWidth={DEFAULT_MODAL_MAX_WIDTH} data-testid="assign-stripe-customer-modal">
            <TabsContainer padding={0.5} marginBottom={{ xs: 2.5, sm: 3 }} data-testid="tabs-container">
                <TabBar
                    onChange={handleTabChange}
                    initialValue={selectedTab}
                    items={[
                        { label: 'Create a new one', value: AssignStripeCustomerType.CREATE_NEW },
                        { label: 'Assign existing', value: AssignStripeCustomerType.ASSIGN_EXISTING },
                    ]}
                    data-testid="tab-bar"
                    variant={TabBarVariants.SECONDARY_TABS}
                />
            </TabsContainer>
            <Stack data-testid={`${selectedTab}-list`}>
                {selectedTab === AssignStripeCustomerType.CREATE_NEW && (
                    <CreateStripeCustomerForm
                        email={owner?.email || ''}
                        name={defaultName || ''}
                        subscriptionUUID={subscriptionUUID}
                    />
                )}
                {selectedTab === AssignStripeCustomerType.ASSIGN_EXISTING && (
                    <AssignStripeCustomerForm subscriptionUUID={subscriptionUUID} />
                )}
            </Stack>
        </Stack>
    );
};

export default AssignStripeCustomerModal;
