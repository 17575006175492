import styled, { css } from 'styled-components';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import React from 'react';
import { Box } from '@mui/material';
import { INTERCOM_ICON_Z_INDEX } from 'src/constants';

const maxWithSelector = (props: { 'data-max-width'?: 'unset' }) => {
    if (props['data-max-width'] === 'unset') {
        return css`
            max-width: unset;
        `;
    }
};

const placementSelector = ({ placement }: { 'placement'?: TooltipProps['placement'] }) => {
    switch (placement) {
        case 'right':
        case 'right-start':
        case 'right-end':
        case 'left':
        case 'left-start':
        case 'left-end':
            return css`
                top: calc(${({ theme }) => theme.sizes.tooltipArrowSize} / 2) !important;
                width: ${({ theme }) => theme.sizes.tooltipArrowSize};
                margin-left: -${({ theme }) => theme.sizes.tooltipArrowSize};
                margin-right: -${({ theme }) => theme.sizes.tooltipArrowSize};
            `;
        case 'top':
        case 'top-start':
        case 'top-end':
        case 'bottom':
        case 'bottom-start':
        case 'bottom-end':
        default:
            return css`
                left: calc(${({ theme }) => theme.sizes.tooltipArrowSize} / 2) !important;
                height: ${({ theme }) => theme.sizes.tooltipArrowSize};
                margin-top: -${({ theme }) => theme.sizes.tooltipArrowSize};
                margin-bottom: -${({ theme }) => theme.sizes.tooltipArrowSize};
            `;
    }
};

const StyledTooltip = styled(({
    className,
    ...props
}) => (
    <Tooltip
        classes={{ popper: className }}
        enterTouchDelay={0}
        PopperProps={{ style: { zIndex: INTERCOM_ICON_Z_INDEX + 2 } }}
        {...props}
    />
))`
    &&& .MuiTooltip-tooltip {
        margin: 6px;
        padding: 8px 12px;
        font-size: 15px;
        font-style: normal;
        font-weight: 450;
        font-family: ${({ theme }) => theme.fonts.normal};
        line-height: normal;
        background-color: ${({ theme }) => theme.palette.lightBlack};

        ${maxWithSelector}
    }

    &&& .MuiTooltip-arrow {
        color: ${({ theme }) => theme.palette.lightBlack};
        ${placementSelector}
    }
`;

export const StyledMultilineTooltipBody = styled(Box)`
    && {
        white-space: pre-line;
        text-align: center;
    }
`;

export default StyledTooltip;
