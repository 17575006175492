import dayjs from 'dayjs';
import { InvoiceStatus } from 'src/constants';
import { RoundedBadgeProps } from 'src/components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from 'src/components/Badge/RoundedBadge/RoundedBadge.styles';
import { StripeInvoice } from 'src/@types/sso-api';

const invoiceStatusMapping: Partial<Record<InvoiceStatus, RoundedBadgeProps>> = {
    [InvoiceStatus.PAID]: {
        label: InvoiceStatus.PAID,
        variant: RoundedBadgeVariant.GREEN,
    },
    [InvoiceStatus.VOID]: {
        label: InvoiceStatus.VOID,
        variant: RoundedBadgeVariant.GREY,
    },
    [InvoiceStatus.UNCOLLECTIBLE]: {
        label: InvoiceStatus.UNCOLLECTIBLE,
        variant: RoundedBadgeVariant.GREY,
    },
    [InvoiceStatus.OPEN]: {
        label: InvoiceStatus.OPEN,
        variant: RoundedBadgeVariant.BLUE,
    },
    [InvoiceStatus.DRAFT]: {
        label: InvoiceStatus.DRAFT,
    },
};

const pastDueBadgeProps: RoundedBadgeProps = {
    label: InvoiceStatus.PAST_DUE,
    variant: RoundedBadgeVariant.RED,
};

export const getInvoiceStatusBadgeProps = (
    invoice: Pick<StripeInvoice, 'status' | 'dueDate'>,
): RoundedBadgeProps | null => {
    const { status, dueDate } = invoice;

    if (status === InvoiceStatus.OPEN && dueDate && dayjs(dueDate).isBefore(dayjs())) {
        return pastDueBadgeProps;
    }

    return status ? invoiceStatusMapping[status] || null : null;
};
