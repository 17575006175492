import styled from 'styled-components';
import { Link } from '@mui/material';

export const LinkContainer = styled(Link)`
    && {
        color: ${({ theme }) => theme.palette.snackbar.infoFont};
        font-family: ${({ theme }) => theme.fonts.medium};
        display: inline-flex;
        font-size: 16px;
        text-decoration: none;
        gap: 8px;

        @media (max-width: ${({ theme }) => theme.brakePoints.sm}) {
            font-size: 14px;
        }
    }
`;
