import React, { FC, ReactNode, useState } from 'react';
import { Box, Stack, StackProps } from '@mui/material';
import QRCode from 'qrcode.react';
import { useSelector } from 'react-redux';
import { StyledEnableTFAModalStep } from './EnableTFAModalForm.styles';
import QRCodeModal from 'src/components/Modals/QRCodeModal/QRCodeModal';
import TFACodeInput from 'src/components/Inputs/TFACodeInput/TFACodeInput';
import { Button, TextButton } from 'src/components/Buttons';
import Line from 'src/components/Line';
import PageTitle from 'src/components/PageTitle';
import { generateQRUrl, generateTfaKey, tfaKeyToSecret } from 'src/services/tfa-key-generator';
import type { RootState } from 'src/redux/root-reducer';
import type { ConfigState, CurrentUserState } from 'src/@types/redux';
import { configSelector, userSelector } from 'src/redux/slices';
import { tfaCodeValidate } from 'src/services/validators';
import { enabledTwoFactorAuth, ssoApiClient } from 'src/services/sso-api';
import { SnackbarMessageVariants } from 'src/constants';
import { useGeneralModal, useSnackbarMessage } from 'src/hooks';

type EnableTFAModalFormProps<T> = {
    ModalContainer: FC<T>;
    titleMarginTop?: StackProps['marginTop'];
    manualSetupGoBackFunctionName: keyof T;
    modalContainerProps: Omit<T, 'children'>;
    manualSetupContainerProps: Omit<T, 'children'>;
};

const EnableTFAModalForm = <T = object & { children: ReactNode }> ({
    ModalContainer,
    titleMarginTop,
    modalContainerProps,
    manualSetupGoBackFunctionName,
    manualSetupContainerProps,
}: EnableTFAModalFormProps<T>) => {
    const { appName } = useSelector<RootState, ConfigState>(configSelector);
    const { currentUser } = useSelector<RootState, CurrentUserState>(userSelector);
    const { addMessage } = useSnackbarMessage();
    const { closeModal } = useGeneralModal();

    const [shouldManuallySetup, setShouldManuallySetup] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [twoFactorKey] = useState<string>(generateTfaKey());
    const [codeInput, setCodeInput] = useState<string>('');
    const isValidCode = tfaCodeValidate(codeInput);

    if (!currentUser) {
        return null;
    }

    const [qrLink] = useState<string>(generateQRUrl(appName, currentUser.email, twoFactorKey));

    const enableTwoFactorAuth = () => {
        setIsSubmitting(true);

        enabledTwoFactorAuth(twoFactorKey, codeInput)
            .then(() => ssoApiClient.refreshToken())
            .then(() => {
                closeModal();
                addMessage('Two-factor authentication enabled', SnackbarMessageVariants.SUCCESS);
            })
            .catch(() => {
                addMessage('Failed to enable two-factor authentication', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setCodeInput('');
                setIsSubmitting(false);
            });
    };

    const onSetupManually = () => {
        setShouldManuallySetup(true);
    };

    const handleGoBack = () => {
        setShouldManuallySetup(false);
    };

    if (shouldManuallySetup) {
        return (
            <ModalContainer {...{ ...manualSetupContainerProps as T, [manualSetupGoBackFunctionName]: handleGoBack }} >
                <QRCodeModal tfaSecret={tfaKeyToSecret(twoFactorKey)} />
            </ModalContainer>
        );
    }

    return (
        <ModalContainer {...modalContainerProps as T}>
            <Box maxWidth="672px">
                <PageTitle
                    title="How to enable 2FA?"
                    testId="form-title"
                    marginBottom={{ xs: 0.5, sm: 2 }}
                    marginTop={titleMarginTop}
                    variant="form"
                />
                <Stack spacing={{ xs: 1.5, sm: 2 }}>
                    <StyledEnableTFAModalStep>
                    1. Download an “authenticator” app from your app store and open it.&nbsp;
                    This can be Microsoft Authenticator, Authy, 1Password, Google Authenticator or another app.
                    </StyledEnableTFAModalStep>
                    <Line />
                    <Stack spacing={{ xs: 1, sm: 1.5 }}>
                        <StyledEnableTFAModalStep>
                        2. Pair the app by scanning the following QR code:
                        </StyledEnableTFAModalStep>
                        <QRCode value={qrLink} size={113} />
                        <StyledEnableTFAModalStep>
                            If you cannot scan the QR code, please click&nbsp;
                            <TextButton onClick={onSetupManually}>here</TextButton>.
                        </StyledEnableTFAModalStep>
                    </Stack>
                    <Line />
                    <Stack spacing={{ xs: 1, sm: 1.5 }}>
                        <StyledEnableTFAModalStep>
                        3. Verify that the pairing was successful by entering a code below:<br />
                        </StyledEnableTFAModalStep>
                        <Box maxWidth="300px">
                            <TFACodeInput
                                codeInput={codeInput}
                                setCodeInput={setCodeInput}
                                isValidCode={isValidCode}
                                disabled={isSubmitting}
                                enableTwoFactorAuth={enableTwoFactorAuth}
                            />
                        </Box>
                    </Stack>
                    <Box paddingTop={{ xs: 1.5, sm: 4 }}>
                        <Button fullWidth disabled={!isValidCode || isSubmitting} onClick={enableTwoFactorAuth}>
                        Enable 2FA now
                        </Button>
                    </Box>
                </Stack>
            </Box>
        </ModalContainer>
    );
};

export default EnableTFAModalForm;
