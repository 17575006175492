import { SyntheticEvent, useEffect, useState } from 'react';

export const useIsSubmitDisabled = <T = Record<string, string | number | undefined>>(
    initialValues: T,
    requiredFields?: Array<keyof T>,
    shouldBeEnabledAtDefaultValues: boolean = false,
) => {
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<T>(initialValues);
    const [changedFieldsMapping, setChangedFieldsMapping] = useState<Record<string, boolean>>({});

    useEffect(() => {
        const isAllRequiredFieldsFilled = requiredFields?.every((field) => {
            const value = formValues[field];
            return value !== undefined && String(value).trim() !== '';
        }) ?? true;

        const isAnyFieldChanged = Object.values(changedFieldsMapping).some(Boolean);

        setIsSubmitDisabled(!(isAllRequiredFieldsFilled && (isAnyFieldChanged || shouldBeEnabledAtDefaultValues)));
    }, [formValues, requiredFields, changedFieldsMapping]);

    useEffect(() => {
        setChangedFieldsMapping({});
        setFormValues(initialValues);
    }, [JSON.stringify(initialValues)]);

    const forceChangeValues = (newValues: Partial<T>) => {
        const composedValues = { ...initialValues, ...newValues };

        setFormValues(composedValues);
        setChangedFieldsMapping({
            ...Object.keys(composedValues).reduce<Record<string, boolean>>((preparedFields, key) => {
                const composedValue = composedValues[key as keyof T] ?? '';
                const initialValue = initialValues[key as keyof T] ?? '';
                if (String(composedValue) !== String(initialValue)) {
                    preparedFields[key] = true;
                }

                return preparedFields;
            }, {}),
        });
    };

    const onFormChangeHandler = (event: SyntheticEvent, updatedFormValues?: T) => {
        const element = event.target as HTMLInputElement;
        const { name, value, checked, type, required } = element;
        const fieldValue = type === 'checkbox' ? checked : value;
        const isFieldRequired = required || element.getAttribute?.('data-required');

        if (updatedFormValues) {
            const changedFields = Object.keys(updatedFormValues)
                .reduce<Record<string, boolean>>((preparedFields, key) => {
                if (String(updatedFormValues[key as keyof T]) !== String(initialValues[key as keyof T])) {
                    preparedFields[key] = true;
                }
                return preparedFields;
            }, {});

            setChangedFieldsMapping(changedFields);
            setFormValues(updatedFormValues);
            return;
        }

        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues, [name]: fieldValue };
            const initialFieldValue = initialValues[name as keyof T] ?? '';
            const isFieldChanged = (!isFieldRequired || !!fieldValue) && (
                String(initialFieldValue) !== String(fieldValue)
            );

            setChangedFieldsMapping((previousState) => ({ ...previousState, [name]: isFieldChanged }));

            return updatedValues;
        });
    };

    return { isSubmitDisabled, onFormChangeHandler, setIsSubmitDisabled, forceChangeValues };
};
