import React, { FC } from 'react';
import { Stack } from '@mui/material';
import type { BillingRecordModel } from 'src/@types/subscription-service-api';
import { PriceRow } from 'src/components/PriceRow';
import { Button } from 'src/components/Buttons';

type CreateNewInvoiceProps = {
    priceInCents: number | null;
    oldPrice: number | null;
    onCreateInvoice: () => void;
    handleSave: (renewal: Partial<BillingRecordModel>) => Promise<void>;
};

const CreateNewInvoice: FC<CreateNewInvoiceProps> = ({ priceInCents, oldPrice, onCreateInvoice, handleSave }) => (
    <>
        <PriceRow
            skipLine
            title="Price:"
            height={{ xs: 'unset', md: '60px' }}
            handleSave={handleSave}
            finalPrice={priceInCents}
            oldPrice={oldPrice}
        />
        <Stack paddingTop={{ xs: 2.5, md: 3 }}>
            <Button onClick={onCreateInvoice} fullWidth>
            Create invoice
            </Button>
        </Stack>
    </>
);

export default CreateNewInvoice;
