import { GridColumnProps } from 'src/components/TableSection/TableSection';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';

export const gridColumnParams: GridColumnProps = {
    company: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 2,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap-reverse',
        column_title: 'Company',
        gap: { xs: 0, md: 1 },
    },
    jobTitle: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        column_title: 'Job title',
        gap: { xs: 0, md: 1 },
    },
    industry: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        column_title: 'Industry',
        gap: { xs: 0, md: 1 },
    },
    jobFunction: {
        xs: TABLE_SECTION_DEFAULT_COLUMNS,
        md: 1,
        display: 'flex',
        flexDirection: 'column',
        column_title: 'Job function',
        gap: { xs: 0, md: 1 },
    },
};
