import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { StyledFinalPrice, StyledPrice } from './FinalPriceRow.styles';
import {
    calculatePriceWithAmountDiscount,
    calculatePriceWithPercentageDiscount,
} from 'src/services/discount-calculator';
import { DiscountType } from 'src/constants';
import { formatPrice } from 'src/services/formatters';

type FinalPriceRowValueProps = {
    priceInCents?: number;
    discountValue?: number | null;
    discountType?: DiscountType | null;
};

export const calculateFinalPrice = ({
    priceInCents,
    discountValue,
    discountType,
}: {
    priceInCents?: number;
    discountValue?: number | null;
    discountType?: DiscountType | null;
}): number | undefined => {
    if (!discountValue || Number.isNaN(discountValue) || !priceInCents || Number.isNaN(priceInCents)) {
        return priceInCents;
    }

    if (discountType === DiscountType.PERCENTAGE) {
        return calculatePriceWithPercentageDiscount(priceInCents, discountValue);
    }

    return calculatePriceWithAmountDiscount(priceInCents, discountValue * 100);
};

const FinalPriceRowValue: FC<FinalPriceRowValueProps> = ({
    priceInCents,
    discountValue,
    discountType,
}) => {
    const shouldRenderDiscount = discountType
        && !!discountValue
        && !Number.isNaN(discountValue);

    return (
        <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            spacing={{ xs: 0, sm: 1 }}
            justifyContent="flex-end"
        >
            {shouldRenderDiscount && (
                <StyledPrice>
                    {formatPrice(priceInCents)}
                </StyledPrice>
            )}
            <StyledFinalPrice>
                {formatPrice(calculateFinalPrice(
                    { priceInCents, discountValue, discountType },
                ))}
            </StyledFinalPrice>
        </Stack>
    );
};

export default FinalPriceRowValue;
