import { gridColumnParams } from './schemas';
import { useLookupNamesHook } from 'src/hooks';
import { getIndustriesList, getJobFunctionsList } from 'src/services/unified-db-api';
import type { JobInfo } from 'src/@types/unified-db-api';

export const getJobInformationFields = (jobInformation?: JobInfo) => {
    const { companyName, jobTitle, industryId, jobFunctionId } = jobInformation || {};
    const industryName = useLookupNamesHook(getIndustriesList, industryId);
    const jobFunctionName = useLookupNamesHook(getJobFunctionsList, jobFunctionId);

    return [
        {
            title: gridColumnParams.company.column_title,
            value: companyName,
            gridProps: gridColumnParams.company,
        },
        {
            title: gridColumnParams.jobTitle.column_title,
            value: jobTitle,
            gridProps: gridColumnParams.jobTitle,
        },
        {
            title: gridColumnParams.industry.column_title,
            value: industryName,
            gridProps: gridColumnParams.industry,
        },
        {
            title: gridColumnParams.jobFunction.column_title,
            value: jobFunctionName,
            gridProps: gridColumnParams.jobFunction,
        },
    ];
};
