import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import {
    ManageProfileSubscriptionActions,
    ProfilePaymentMethodSectionModal,
    SubscriptionAction,
} from 'src/constants';
import { PaymentMethodDetails } from 'src/@types/sso-api';

export const getManageSubscriptionActionsList = (
    isActionAllowed: (actionKey: SubscriptionAction) => boolean,
): Partial<Record<ManageProfileSubscriptionActions, ManageActionType>> => {

    return {
        ...(isActionAllowed(SubscriptionAction.CANCEL) && { [ManageProfileSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESTORE) && { [ManageProfileSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
    };
};

export const getPaymentMethodSectionActionsList = (
    paymentMethod?: PaymentMethodDetails,
): Partial<Record<ProfilePaymentMethodSectionModal, ManageActionType>> => {
    const card = paymentMethod?.card;

    return {
        ...(!card && { [ProfilePaymentMethodSectionModal.addPaymentMethodModal]: { label: 'Add payment method' } }),
        ...(card && { [ProfilePaymentMethodSectionModal.changePaymentMethodModal]: { label: 'Change payment method' } }),
    };
};
