import React, { FC, Fragment } from 'react';
import { Grid } from '@mui/material';
import { getPaymentFields } from './services/getters';
import PaymentMethodItem from './PaymentMethodItem';
import { ManageActionType } from 'src/components/ManageActions/ManageActions';
import { MenuVariant } from 'src/components/Menu/Menu';
import { Menu } from 'src/components/Menu';
import { PaymentMethodDetails } from 'src/@types/sso-api';
import Line from 'src/components/Line';
import { TABLE_SECTION_DEFAULT_COLUMNS } from 'src/constants';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { NoContentMessage } from 'src/components/TableSection/TableSection.styles';
import { Section } from 'src/components/Section';

type PaymentSectionProps = {
    isLoading: boolean;
    paymentMethod?: PaymentMethodDetails;
    actionsList?: Record<string, ManageActionType>;
};

const PaymentMethodSection: FC<PaymentSectionProps> = ({
    paymentMethod,
    isLoading,
    actionsList,
}) => {
    const hasActions = actionsList && Object.keys(actionsList).length > 0;
    const cardDetails = paymentMethod?.card;
    const billingDetails = paymentMethod?.billingDetails;
    const paymentFields = getPaymentFields(cardDetails, billingDetails);

    return (
        <Section
            data-testid="payment-method-section"
            sectionPaddingBottom={3}
            header="Payment method"
            headerChildren={
                hasActions && (
                    <Menu
                        buttonVariant={MenuVariant.THREE_DOTS_TEXT_BUTTON}
                        tooltipMaxWidth="unset"
                        menuItems={Object.entries(actionsList)
                            .map(([value, { label, disabled, tooltipTitle }]) => (
                                { value, label, disabled, tooltipTitle }
                            ))}
                        onClick={() => {}}
                    />
                )
            }
        >
            {isLoading && <LoaderOverlay />}
            {!!cardDetails ? (
                <Grid container columns={TABLE_SECTION_DEFAULT_COLUMNS}>
                    {paymentFields.map((field, index) => (
                        <Fragment key={field.title}>
                            {index !== 0 && (
                                <Grid item display={{ xs: 'block', md: 'none' }} paddingY={2} xs={12}>
                                    <Line />
                                </Grid>
                            )}
                            <PaymentMethodItem
                                title={field.title}
                                value={field.value}
                                gridProps={field.gridProps}
                            />
                        </Fragment>
                    ))}
                </Grid>
            ) : (
                <NoContentMessage marginY={1.5}>
                    There is no payment method associated with this subscription.
                </NoContentMessage>
            )}
        </Section>
    );
};

export default PaymentMethodSection;
