import React, { FC } from 'react';
import { BoxProps } from '@mui/material/Box';
import {
    InvoiceInfo,
    StyledInvoiceText,
    StyledSearchText,
    StyledStack,
    Title,
} from './InvoiceInfoBox.styles';
import { getInvoiceStatusBadgeProps } from 'src/components/InvoiceInfoBox/services/getters';
import { RoundedBadge } from 'src/components/Badge';
import { formatDate, formatPrice } from 'src/services/formatters';
import { StripeInvoice } from 'src/@types/sso-api';

type InvoiceInfoBoxProps = {
    invoiceData: StripeInvoice;
    onSearchAgain: () => void;
    paddingTop?: BoxProps['paddingTop'];
    marginBottom?: BoxProps['marginBottom'];
};

const InvoiceInfoBox: FC<InvoiceInfoBoxProps> = ({
    invoiceData,
    onSearchAgain,
    paddingTop = { xs: 1.5, md: 4 },
    marginBottom = { xs: 0, md: 3 },
}) => {
    const invoiceStatusBadgeProps = getInvoiceStatusBadgeProps(invoiceData);

    return (
        <>
            <StyledInvoiceText paddingBottom={1.5} paddingTop={paddingTop}>
                Invoice
                <StyledSearchText onClick={onSearchAgain}>
                    Search again
                </StyledSearchText>
            </StyledInvoiceText>
            <StyledStack gap={0.5} data-testid="search-invoice-data" marginBottom={marginBottom}>
                <Title flexDirection="row">
                    Amount due: {formatPrice(invoiceData.total)}
                    {invoiceStatusBadgeProps && <RoundedBadge {...invoiceStatusBadgeProps} />}
                </Title>
                {invoiceData.dueDate && (
                    <InvoiceInfo>
                        Due on: {formatDate(invoiceData.dueDate)}
                    </InvoiceInfo>
                )}
                <InvoiceInfo>
                    Invoice number: {invoiceData.number}
                </InvoiceInfo>
                <InvoiceInfo>
                    Invoice ID: {invoiceData.id}
                </InvoiceInfo>
            </StyledStack>
        </>
    );
};

export default InvoiceInfoBox;
