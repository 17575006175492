import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { LinkContainer } from './AssociatedLinkSection.styles';
import Line from 'src/components/Line';
import { ReactComponent as LinkIcon } from 'src/assets/icons/stripe-link-icon.svg';

type AssociatedLinkSectionProps = {
    href: string;
    linkText: string;
};

const AssociatedLinkSection: FC<AssociatedLinkSectionProps> = ({ href, linkText }) => (
    <Box flexGrow={1}>
        <Stack paddingY={1.5}><Line /></Stack>
        <LinkContainer href={href} target="_blank">
            {linkText}
            <LinkIcon width="16px" />
        </LinkContainer>
    </Box>
);

export default AssociatedLinkSection;
