import dayjs from 'dayjs';
import React from 'react';
import { getDiscountDurationRenderLabels } from 'src/components/PricingTerm/services/getters';
import { makePlural } from 'src/services/text-modifier';
import { FinalPriceRowTitle, FinalPriceRowValue } from 'src/components/FinalPriceRow';
import type {
    TypeAndBillingData,
} from 'src/pages/udb/CreateSubscription/components/TypeAndBillingStep/TypeAndBillingStep';
import {
    billingCycleLengthRenderMapping,
    billingTypeRenderMapping,
    DATE_TIME_FORMAT,
    DiscountDuration,
    PricingModel,
    pricingModelRenderLabels,
    SubscriptionBillingType,
    SubscriptionType,
} from 'src/constants';
import { PricingTermsData } from 'src/pages/udb/CreateSubscription/components/PricingTermsStep/PricingTermsStep';
import { formatPrice } from 'src/services/formatters';
import { ReactComponent as EndpointsInsider } from 'src/assets/icons/endpoints-insider-logo.svg';
import { ReactComponent as EndpointsEnterprise } from 'src/assets/icons/endpoints-enterprise-logo.svg';

export const subscriptionTypeIcons = {
    [SubscriptionType.INSIDER]: <EndpointsInsider />,
    [SubscriptionType.ENTERPRISE]: <EndpointsEnterprise />,
};

const getExpirationDateText = ({ selectedBillingCycleLength, numberOfCycles }: Required<TypeAndBillingData>) => {
    const billingCycleLength = billingCycleLengthRenderMapping[selectedBillingCycleLength];

    return `${numberOfCycles} ${makePlural(billingCycleLength, numberOfCycles)} from the moment of activation`;
};

export const getTypeAndBillingData = (typeAndBillingData: Required<TypeAndBillingData>, expirationDate?: string) => {
    return [
        { key: 'subscriptionType', label: 'Subscription type:', value: subscriptionTypeIcons[typeAndBillingData.selectedSubscriptionType] },
        { key: 'billing', label: 'Billing:', value: billingTypeRenderMapping[typeAndBillingData.selectedBillingType] },
        {
            key: 'billingCycleLength',
            label: 'Billing cycle length:',
            value: `1 ${billingCycleLengthRenderMapping[typeAndBillingData.selectedBillingCycleLength]}`,
        },
        {
            key: 'activationDate',
            label: 'Activation date:',
            value: typeAndBillingData.activationDate
                ? dayjs(typeAndBillingData.activationDate).format(DATE_TIME_FORMAT)
                : 'Moment of activation',
        },
        {
            key: 'expirationDate',
            label: 'Expiration date:',
            value: expirationDate
                ? dayjs(expirationDate).format(DATE_TIME_FORMAT)
                : getExpirationDateText(typeAndBillingData),
        },
    ];
};

export const getPricingTermsData = (
    pricingTermsData: PricingTermsData,
    {
        selectedBillingCycleLength,
        selectedSubscriptionType,
        selectedBillingType,
    }: Required<TypeAndBillingData>,
) => {
    if (selectedSubscriptionType === SubscriptionType.ENTERPRISE) {
        const isFree = selectedBillingType === SubscriptionBillingType.FREE;

        return [
            {
                key: 'amountOfUsers',
                label: 'Amount of users:',
                value: (
                    isFree
                        ? pricingTermsData[selectedSubscriptionType]?.domains
                            ?.reduce((acc, domain) => acc + domain.userCount, 0)
                        : pricingTermsData[selectedSubscriptionType]?.priceData?.userCount
                ) ?? 0,
            },
            {
                key: 'firstYearPrice',
                label: isFree ? 'Price:' : 'First year price:',
                value: isFree ? 'Free' : formatPrice(pricingTermsData[selectedSubscriptionType]?.finalPriceInCents ?? 0),
            },
        ];
    }

    const {
        discountDurationInCycles,
        discountType,
        discountValue,
        fixedPriceInCents,
        finalPriceInCents,
    } = pricingTermsData[selectedSubscriptionType]!;

    const pricingModel = fixedPriceInCents ? PricingModel.FIXED_PRICE : PricingModel.RENEWABLE_PRICE;
    const discountDurationCyclesAmount = discountDurationInCycles === 1
        ? DiscountDuration.FIRST_CYCLE
        : DiscountDuration.MULTIPLE_CYCLE;
    const discountDuration = discountDurationInCycles ? discountDurationCyclesAmount : DiscountDuration.FOREVER;

    const renderFields = [
        { key: 'pricingModel', label: 'Pricing model:', value: pricingModelRenderLabels[pricingModel] },
        { key: 'finalPrice',
            label: (
                <FinalPriceRowTitle
                    discountType={discountType}
                    discountValue={discountValue}
                />
            ),
            value: (
                <FinalPriceRowValue
                    priceInCents={fixedPriceInCents || finalPriceInCents}
                    discountValue={discountValue}
                    discountType={discountType}
                />
            ) },
    ];

    if (discountValue) {
        renderFields.push({
            key: 'discountDuration',
            label: 'Discount duration:',
            value: getDiscountDurationRenderLabels(
                selectedBillingCycleLength,
                discountDurationInCycles,
            )[discountDuration],
        });
    }

    return renderFields;
};
