import React, { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { DiscountDurationBanner, StyledFinalPrice } from './RenewablePriceAdditionalInfo.styles';
import {
    DiscountDurationItemsProps,
    getDiscountDurationRadioButtons,
} from 'src/components/PricingTerm/services/getters';
import { StyledSwitchLabel } from 'src/components/Label/Label.styles';
import { BillingCycleLengthInMonth, DiscountDuration } from 'src/constants';
import { StyledSwitch } from 'src/components/Switch';
import { formatPrice } from 'src/services/formatters';
import { SectionTitle } from 'src/components/Section/Section.styles';
import Line from 'src/components/Line';
import { RadioGroup } from 'src/components/Radio';

export type RenewalPriceAdditionalInfoProps = {
    finalPriceInCents: number;
    discountValue: number | null;
    setHasDiscount: (value: boolean) => void;
    discountDuration: DiscountDuration | undefined;
    setDiscountDuration: (value: DiscountDuration) => void;
    billingCycleLength: BillingCycleLengthInMonth;
    numberOfCycles?: number;
    discountDurationInCycles?: number | null;
    setDiscountDurationInCycles: (value: number) => void;
    discountDurationBanner?: boolean;
    fieldsProps?: { discountDuration?: DiscountDurationItemsProps };
};

const RenewablePriceAdditionalInfo: FC<RenewalPriceAdditionalInfoProps> = ({
    finalPriceInCents,
    discountValue,
    setHasDiscount,
    discountDuration,
    setDiscountDuration,
    discountDurationInCycles,
    billingCycleLength,
    numberOfCycles,
    setDiscountDurationInCycles,
    discountDurationBanner,
    fieldsProps,
}) => {
    return (
        <Stack spacing={{ xs: 2, sm: 2.5 }} paddingTop={{ xs: 0, sm: 0.5 }}>
            <Line />
            <Stack direction="row" justifyContent="space-between">
                <StyledFinalPrice>
                    {formatPrice(finalPriceInCents)}
                </StyledFinalPrice>
                <Stack direction="row" spacing={1} alignItems="center">
                    <StyledSwitch
                        data-testid="apply-discount-switch"
                        checked={discountValue !== null}
                        onClick={() => setHasDiscount(discountValue === null)}
                    />
                    <StyledSwitchLabel>
                        Apply discount
                    </StyledSwitchLabel>
                </Stack>
            </Stack>
            {discountValue !== null && (
                <Stack spacing={{ xs: 2, sm: 2.5 }}>
                    <Line />
                    {discountDurationBanner && (
                        <Box paddingTop={{ sm: 0.5 }}>
                            <DiscountDurationBanner padding={2}>
                                Discount duration is counted from the start of the subscription.
                            </DiscountDurationBanner>
                        </Box>
                    )}
                    <SectionTitle paddingTop={{ xs: 0, sm: 0.5 }}>
                        Discount duration
                    </SectionTitle>
                    <RadioGroup
                        selectedValue={discountDuration}
                        items={getDiscountDurationRadioButtons({
                            billingCycleLength,
                            numberOfCycles,
                            discountDuration,
                            discountDurationInCycles,
                            setDiscountDurationInCycles,
                            itemsProps: fieldsProps?.discountDuration,
                        })}
                        onChange={setDiscountDuration}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default RenewablePriceAdditionalInfo;
