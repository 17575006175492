import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { StyledRecalculate } from './EnterpriseCalculator.styles';
import { InfoBoxTitle } from 'src/components/InfoBox/InfoBox.styles';
import { InfoBox } from 'src/components/InfoBox';
import { Section } from 'src/components/Section';
import { TextInput } from 'src/components/Inputs/TextInput';
import { Button } from 'src/components/Buttons';
import { CalculatePriceResponse } from 'src/@types/subscription-service-api';
import { calculatePrice } from 'src/services/subscription-service-api';
import { SnackbarMessageVariants } from 'src/constants';
import { useSnackbarMessage } from 'src/hooks';

type PriceCalculationProps = {
    getFieldToRender: (
        price: CalculatePriceResponse | null,
        userCount: number
    ) => { label: ReactNode, value: ReactNode, key: string }[];
    initialUserCount: number;
    initialPrice?: CalculatePriceResponse | null;
    hash?: string;
    onPriceChange?: (price: CalculatePriceResponse | null) => void;
    children?: ReactNode;
};

const PriceCalculator: FC<PriceCalculationProps> = ({
    onPriceChange,
    getFieldToRender,
    initialUserCount,
    initialPrice = null,
    hash,
    children,
}) => {
    const [price, setPrice] = useState<CalculatePriceResponse | null>(initialPrice);
    const [userCount, setUserCount] = useState<number>(initialUserCount);
    const [pendingUserCount, setPendingUserCount] = useState<number>(0);
    const [isRecalculateShow, setIsRecalculateShow] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(false);

    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

    const handlePendingUserCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Math.max(0, Number(e.target.value));
        setPendingUserCount(value);
    };

    const { addMessage } = useSnackbarMessage();

    const fetchPrice = (count: number) => {
        if (count === 0) {
            setPrice(null);
            setPendingUserCount(0);
            return;
        }

        setLoading(true);
        calculatePrice({
            method: 'userCountBasedBrackets',
            params: { userCount: count },
        })
            .then((data) => {
                setPrice(data);
                setPendingUserCount(count);
            })
            .catch(() => {
                addMessage('An error occurred while calculating the price', SnackbarMessageVariants.ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        onPriceChange?.(price);
    }, [price]);

    useEffect(() => {
        if (initialPrice) {
            setUserCount(initialPrice.userCount);
            setPendingUserCount(initialPrice.userCount);
        }
    }, []);

    useEffect(() => {
        setIsFirstRender(false);

        if (initialPrice && isFirstRender) {
            return;
        }

        fetchPrice(initialUserCount);
        setUserCount(initialUserCount);
    }, [initialUserCount, hash]);

    return (
        <Section header="Price calculation" variant="headless" isLoading={isLoading} sectionPaddingBottom={{ md: 4, xs: 3 }}>
            <InfoBox
                items={getFieldToRender(price, userCount)}
                prependNode={(
                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        gap={{ xs: isRecalculateShow ? 2.5 : 0, md: 0 }}
                        position="relative"
                        justifyContent="space-between"
                    >
                        <InfoBoxTitle>Amount of users:</InfoBoxTitle>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (pendingUserCount !== userCount) {
                                    setUserCount(pendingUserCount);
                                    fetchPrice(pendingUserCount);
                                }
                            }}
                        >
                            <StyledRecalculate gap={1.5}>
                                {(isRecalculateShow || pendingUserCount !== userCount) && (
                                    <Button
                                        testId="recalculate-button"
                                        fullWidth
                                        type="submit"
                                        disabled={pendingUserCount === userCount}
                                    >
                                            Recalculate
                                    </Button>
                                )}
                                <TextInput
                                    placeholder=""
                                    type="number"
                                    name="amount-of-users"
                                    onFocus={() => setIsRecalculateShow(true)}
                                    onBlur={() => setIsRecalculateShow(false)}
                                    value={String(pendingUserCount)}
                                    onChange={handlePendingUserCountChange}
                                    InputProps={{
                                        inputProps: {
                                            'data-testid': 'amount-of-users-input',
                                        },
                                    }}
                                />
                            </StyledRecalculate>
                        </form>
                    </Box>
                )}
            />
            {children}
        </Section>
    );
};

export default PriceCalculator;
