import { Box } from '@mui/material';
import React from 'react';
import { DurationInputProps } from 'src/components/Inputs/DurationInput/DurationInput';
import {
    RenewalPriceAdditionalInfoProps,
} from 'src/components/PricingTerm/components/RenewablePriceAdditionalInfo/RenewablePriceAdditionalInfo';
import { DurationInput } from 'src/components/Inputs';
import {
    additionalShortInputWidth,
    additionalWideInputWidth,
    BillingCycleLengthInMonth,
    billingCycleLengthRenderMapping,
    DiscountDuration,
    DiscountType,
    PricingModel,
    pricingModelRenderLabels,
} from 'src/constants';
import { RadioGroupItem } from 'src/components/Radio/RadioGroup';
import PriceInput from 'src/components/Inputs/PriceInput';
import { RenewablePriceAdditionalInfo } from 'src/components/PricingTerm/components/RenewablePriceAdditionalInfo';
import { makePlural } from 'src/services/text-modifier';

export type PricingModelRadioDependencies = {
    setPrice: (price: number) => void;
    selectedPricingModel?: PricingModel | null;
} & RenewalPriceAdditionalInfoProps;

export type DiscountDurationItemsProps = {
    firstCycle?: Partial<RadioGroupItem>;
    durationInput?: Partial<DurationInputProps>;
};

export const discountTypeRadioButtons = [
    {
        value: DiscountType.PERCENTAGE,
        label: 'Percentage off',
    },
    {
        value: DiscountType.FIXED,
        label: 'Amount off',
    },
];

export const getPricingModelRadioButtons = ({
    selectedPricingModel,
    setPrice,
    ...props
}: PricingModelRadioDependencies): Array<RadioGroupItem<PricingModel>> => [
    {
        value: PricingModel.FIXED_PRICE,
        label: pricingModelRenderLabels[PricingModel.FIXED_PRICE],
        ...(selectedPricingModel === PricingModel.FIXED_PRICE && {
            additionalNode: (
                <Box maxWidth={{ xs: '150px', sm: additionalWideInputWidth }}>
                    <PriceInput
                        initialPrice={props.finalPriceInCents}
                        handleChange={setPrice}
                        placeholder="Price"
                        currency="$"
                    />
                </Box>
            ),
            additionalNodeProps: {
                direction: 'column',
                spacing: 2,
            },
        }),
    },
    {
        value: PricingModel.RENEWABLE_PRICE,
        label: pricingModelRenderLabels[PricingModel.RENEWABLE_PRICE],
        ...(selectedPricingModel === PricingModel.RENEWABLE_PRICE && {
            additionalNode: (
                <RenewablePriceAdditionalInfo {...props} />
            ),
            additionalNodeProps: {
                direction: 'column',
                spacing: 2,
            },
        }),
    },
];

export const getDiscountDurationRenderLabels = (
    billingCycleLength: BillingCycleLengthInMonth,
    discountDurationLength?: number | null,
): Record<DiscountDuration, string> => ({
    [DiscountDuration.FIRST_CYCLE]: `First ${billingCycleLengthRenderMapping[billingCycleLength]}`,
    [DiscountDuration.MULTIPLE_CYCLE]:
        makePlural(
            `${discountDurationLength ?? 'Multiple'} ${billingCycleLengthRenderMapping[billingCycleLength]}`,
            Number(discountDurationLength),
        ),
    [DiscountDuration.FOREVER]: 'Forever',
});

export const getDiscountDurationRadioButtons = (
    {
        billingCycleLength,
        numberOfCycles,
        discountDuration,
        setDiscountDurationInCycles,
        discountDurationInCycles,
        itemsProps = {},
    }: {
        itemsProps?: DiscountDurationItemsProps;
        billingCycleLength: BillingCycleLengthInMonth;
        numberOfCycles?: number;
        discountDuration: DiscountDuration | undefined;
        discountDurationInCycles?: number | null;
        setDiscountDurationInCycles: (value: number) => void;
    },
): Array<RadioGroupItem<DiscountDuration>> => {
    const discountDurationRenderLabels = getDiscountDurationRenderLabels(billingCycleLength);

    return [
        {
            ...itemsProps.firstCycle,
            value: DiscountDuration.FIRST_CYCLE,
            label: discountDurationRenderLabels[DiscountDuration.FIRST_CYCLE],
        },
        {
            value: DiscountDuration.MULTIPLE_CYCLE,
            label: discountDurationRenderLabels[DiscountDuration.MULTIPLE_CYCLE],
            ...(discountDuration === DiscountDuration.MULTIPLE_CYCLE && {
                additionalNode: (
                    <Box maxWidth={additionalShortInputWidth}>
                        <DurationInput
                            value={discountDurationInCycles ?? 2}
                            onChange={setDiscountDurationInCycles}
                            minValue={Number(numberOfCycles) || (itemsProps.durationInput?.minValue ?? 0)}
                            adornmentText={billingCycleLength && (
                                billingCycleLengthRenderMapping[billingCycleLength]
                            )}
                        />
                    </Box>
                ),
                additionalNodeProps: {
                    direction: 'column',
                    spacing: 2,
                },
            }),
        },
        {
            value: DiscountDuration.FOREVER,
            label: discountDurationRenderLabels[DiscountDuration.FOREVER],
        },
    ];
};
