import type { OptionsType } from './components/Select/Select';
import type { ManageActionType } from './components/ManageActions/ManageActions';
import { RoundedBadgeProps } from './components/Badge/RoundedBadge/RoundedBadge';
import { RoundedBadgeVariant } from './components/Badge/RoundedBadge/RoundedBadge.styles';

export const GENERAL_VERTICAL_ID = 1;
export const TFA_CODE_LENGTH = 6;
export const REDIRECT_TIMEOUT = 5_000;
export const MIN_PASSWORD_LENGTH = 10;
export const MAX_FULL_NAME_LENGTH = 255;
export const INPUT_MAX_LENGTH = 510;
export const SUBSCRIPTION_RENEWAL_DAYS = 90;
export const INTERCOM_ICON_Z_INDEX = 2147483003;
export const TABLE_SECTION_DEFAULT_COLUMNS = 5;
export const DATE_FORMAT = 'DD MMM, YYYY';
export const DATE_TIME_FORMAT = 'DD MMM YYYY, HH:mm:ss';
export const DATE_ISO_8601 = 'YYYY-MM-DD';
export const DATE_TIME_ISO_8601 = 'YYYY-MM-DD HH:mm:ss';
export const URL_MUST_START_WITH_MESSAGE = 'URL must start with';
export const DUPLICATE_DOMAIN_ERROR_MESSAGE = 'You already added this domain';
export const FULL_NAME_IS_REQUIRED_MESSAGE = 'Full name is required';
export const INVALID_FULL_NAME_MESSAGE = 'Allowed characters: A-Z \' " - . ,';
export const COMPANY_NAME_IS_REQUIRED_MESSAGE = 'Company name is required';
export const INVALID_COMPANY_NAME_MESSAGE = 'Allowed characters: A-Z 0-9 \' " - . , ( ) & : ; # _ / + * !';
export const JOB_TITLE_IS_REQUIRED_MESSAGE = 'Job title is required';
export const INVALID_JOB_TITLE_MESSAGE = 'Allowed characters: A-Z 0-9 \' " - . , ( ) & : ; # /';
export const MAX_LENGTH_IS_REACHED_MESSAGE = 'Max field length has reached';
export const INVALID_EMAIL_VALIDATION_ERROR = 'Email address is not valid';
export const INVALID_PRIMARY_EMAIL_DOMAIN_MESSAGE = 'Please enter a valid domain';
export const INVALID_NUMBER_OF_EMPLOYEES_MESSAGE = 'Number of employees should be greater than zero';
export const INVALID_NUMBER_OF_REQUESTED_USER_MESSAGE = 'Requested amount of users should be greater than zero';
export const MAX_NUMBER_OF_REQUESTED_USER_MESSAGE = 'The requested amount of users must not be greater than 4294967295';
export const MAX_PRICE_IN_CENTS_MESSAGE = 'The price in cents must not be greater than 42949672';
export const FORBIDDEN_TLD_ERROR_MESSAGE = 'You may follow the directions given by a Ukrainian soldier to your moskva cruiser';
export const INVALID_PHONE_NUMBER_VALIDATION_ERROR = 'Phone number is not valid';
export const INVALID_NUMBER_TYPE_MESSAGE = 'Must be a number';
export const INVALID_INVOICE_ID_MESSAGE = 'Invalid invoice ID';
export const INVALID_INVOICE_URL_MESSAGE = 'Invalid invoice URL';
export const EMAIL_IS_TAKEN_ERROR = 'Email is already taken';
export const EMPTY_EMAIL_VALIDATION_ERROR = 'Email field can\'t be empty';
export const EMPTY_PASSWORD_VALIDATION_ERROR = 'Password field can\'t be empty';
export const FAILED_LOG_IN_MESSAGE = 'Login failed. Please check that the email and password provided are correct';
export const FAILED_REQUEST_RESET_PASSWORD_MESSAGE = 'Failed to request password change. Please, try again or contact us to get help.';
export const FAILED_PASSWORD_CHANGE_MESSAGE = 'Failed to set a new password. Please, try again or contact us to get help.';
export const TO_SHORT_PASSWORD_MESSAGE = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long`;
export const NO_LOVER_CASE_CHARACTER_PASSWORD_MESSAGE = 'Password must contain at least one lower case character';
export const NO_UPPER_CASE_CHARACTER_PASSWORD_MESSAGE = 'Password must contain at least one upper case character';
export const NO_NUMBER_CHARACTER_PASSWORD_MESSAGE = 'Password must contain at least one number';
export const NO_SYMBOL_CHARACTER_PASSWORD_MESSAGE = 'Password must contain at least one symbol';
export const PASSWORDS_DO_NOT_MATCH_MESSAGE = 'Passwords do not match';
export const FAILED_REQUEST_LOG_IN_LINK_MESSAGE = 'Failed to request login link. Please, try again or contact us to get help.';
export const DATA_GRID_NO_ROWS_MESSAGE = 'No rows';
export const FAILED_TO_LOAD_DATA_GRID_MESSAGE = 'Failed to load data';
export const NOT_LINKEDIN_URL_MESSAGE = 'This is not a valid LinkedIn URL';
export const NOT_TWITTER_URL_MESSAGE = 'This is not a valid X (Twitter) URL';
export const NOT_VALID_URL_MESSAGE = 'This is not a valid URL';
export const INVALID_DOMAIN_NAME_MESSAGE = 'This is not a valid domain';
export const FILTERING_ERROR_MESSAGE = 'Filtering error. Please check the selected filters.';
export const SORTING_ERROR_MESSAGE = 'Sorting error. Please check the selected sorting.';
export const INVALID_SALESFORCE_CONTRACT_LINK = 'Invalid Salesforce contract link';
export const DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE = 'Action not allowed due to subscription status';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const ACCESS_TOKEN_KEY = 'access_token';
export const TOKEN_EXPIRED_CODE = 498;
export const INVALID_STRIPE_CUSTOMER_ID = 'Stripe customer ID is invalid';

export enum GeneralModalKey {
    text = 'text',
    usageDataModal = 'usageDataModal',
    confirmationModal = 'confirmationModal',
    settingsModal = 'settingsModal',
    QRCodeModal = 'QRCodeModal',
    billingDetailsModal = 'billingDetailsModal',
    addManager = 'addManager',
    additionalEmailModal = 'additionalEmailModal',
    upcomingRenewal = 'upcomingRenewal',
    changeOwner = 'changeOwner',
    updateGracePeriod = 'updateGracePeriod',
    generateLink = 'generateLink',
    editJobInformation = 'editJobInformation',
    editSocialMedia = 'editSocialMedia',
    editContactInformation = 'editContactInformation',
    editBillingRenewalNotification = 'editBillingRenewalNotification',
    editUserName = 'editUserName',
    editNewsletterDelivery = 'editNewsletterDelivery',
    addDomain = 'addDomain',
    subscriptionActionModal = 'subscriptionActionModal',
    contractDetailsModal = 'contractDetailsModal',
    createInitialRecordModal = 'createInitialRecordModal',
    activateSubscriptionModal = 'activateSubscriptionModal',
    reactivateSubscriptionModal = 'reactivateSubscriptionModal',
    extendSubscriptionModal = 'extendSubscriptionModal',
    changeAccountStatusModal = 'changeAccountStatusModal',
    changeRoleModal = 'changeRoleModal',
    disable2FAModal = 'disable2FAModal',
    assignStripeCustomerModal = 'assignStripeCustomerModal',
    enableTFAModal = 'enableTFAModal',
    adjustPricingTerms = 'adjustPricingTerms',
    attachAnInvoice = 'attachAnInvoice',
}

export enum UDBNewsletterChannel {
    EMAIL = 'email',
    SMS = 'sms',
}

export enum Order {
    ASC = 'asc',
    DESC = 'desc',
}

export enum Status {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
}

export enum EmailStatus {
    BOUNCED = 'Bounced',
    UNSUBSCRIBED = 'Unsubscribed',
    SUBSCRIBED = 'Subscribed',
    DELETED = 'Deleted',
    UNDEFINED = 'Undefined',
}

export enum InvoiceStatus {
    PAID = 'paid',
    VOID = 'void',
    UNCOLLECTIBLE = 'uncollectible',
    PAST_DUE = 'past due',
    OPEN = 'open',
    DRAFT = 'draft',
}

export enum SubscriptionAction {
    CANCEL = 'subscription.cancel',
    RESTORE = 'subscription.restore',
    RESUME = 'subscription.resume',
    PAUSE = 'subscription.pause',
    TERMINATE = 'subscription.terminate',
    ACTIVATE = 'subscription.activate',
    BILLING_RECORD_CREATE_INITIAL = 'subscription.billingRecord.createInitial',
    REACTIVATE = 'subscription.reactivate',
    EXTEND = 'subscription.extend',
    CUSTOM_PRICING_TERMS_UPDATE = 'subscription.customPricingTerms.update',
    CUSTOM_PRICING_TERMS_DELETE = 'subscription.customPricingTerms.delete',
    CUSTOM_PRICING_TERMS_CREATE = 'subscription.customPricingTerms.create',
    // not implemented
    // DELETE = 'subscription.delete',
}

export const subscriptionActionMapping: Partial<Record<SubscriptionAction, string>> = {
    [SubscriptionAction.CANCEL]: 'Cancel subscription',
    [SubscriptionAction.RESTORE]: 'Restore subscription',
    [SubscriptionAction.RESUME]: 'Resume subscription',
    [SubscriptionAction.PAUSE]: 'Pause subscription',
    [SubscriptionAction.TERMINATE]: 'Terminate subscription',
    [SubscriptionAction.ACTIVATE]: 'Activate subscription',
};

export enum SubscriptionStatus {
    ACTIVE = 'active',
    SERVICE_ENDED = 'serviceEnded',
    TERMINATED = 'terminated',
    PAUSED = 'paused',
    DRAFT = 'draft',
}

export const subscriptionStatusMapping = {
    [SubscriptionStatus.ACTIVE]: 'Active',
    [SubscriptionStatus.SERVICE_ENDED]: 'Service Ended',
    [SubscriptionStatus.TERMINATED]: 'Terminated',
    [SubscriptionStatus.PAUSED]: 'Paused',
    [SubscriptionStatus.DRAFT]: 'Draft',
};

export enum SubscriptionPaymentStatus {
    ON_GRACE_PERIOD = 'on grace period',
    WILL_NOT_RENEW = 'will not renew',
}

export enum UserRoles {
    ADMIN = 'Admin',
    APP_MANAGER = 'AppManager',
    USER_MANAGER = 'UserManager',
    USER = 'User',
    ANALYST = 'Analyst',
}

export const roleDescriptions: Record<UserRoles, string> = {
    [UserRoles.ADMIN]: 'Can manage accounts, credentials, subscriptions, applications and change roles.',
    [UserRoles.ANALYST]: 'Has read access to accounts and subscriptions.',
    [UserRoles.APP_MANAGER]: 'Can manage applications.',
    [UserRoles.USER_MANAGER]: 'Can manage accounts, credentials and subscriptions. Has read access to non-User accounts and credentials.',
    [UserRoles.USER]: 'Can manage their own details and settings as well as subscriptions where they are an owner or a manager.',
};

export const userRoleRenderMapping: Record<UserRoles, string> = {
    [UserRoles.ADMIN]: 'Admin',
    [UserRoles.APP_MANAGER]: 'App Manager',
    [UserRoles.USER_MANAGER]: 'User Manager',
    [UserRoles.USER]: 'User',
    [UserRoles.ANALYST]: 'Analyst',
};

export enum SsoApiEventTypes {
    updateTokens = 'updateTokens',
    revokeToken = 'revokeToken',
}

export enum ToolBarConditionTypes {
    SELECT = 'SELECT',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    DATE_OR_DATE_TIME = 'DATE_OR_DATE_TIME',
    TEXT_FIELD = 'TEXT_FIELD',
    NUMBER_FIELD = 'NUMBER_FIELD',
    MULTI_SELECT = 'MULTI_SELECT',
}

export enum ToolBarModelTypes {
    FILTERING = 'FILTERING',
    SORTING = 'SORTING',
}

export enum SsoConditionValues {
    CONTAINS = 'contains',
    ONE_OF = 'oneOf',
    FROM = 'from',
    TO = 'to',
    ORDER = 'order',
    EQUALS = 'equals',
    HAS = 'has',
    ENABLED = 'enabled',
}

export enum UdbConditions {
    EQUAL = '=',
    NOT_EQUAL = '!=',
    ANY_OF = 'anyOf',
    NOT_ANY_OF = 'notAnyOf',
    CONTAINS = 'contains',
    DOES_NOT_CONTAIN = 'doesNotContain',
    STARTS_WITH = 'startsWith',
    ENDS_WITH = 'endsWith',
    IS_EMPTY = 'isEmpty',
    IS_NOT_EMPTY = 'isNotEmpty',
    EQUAL_OR_MORE = '>=',
    EQUAL_OR_LESS = '<=',
    MORE_THAN = '>',
    LESS_THAN = '<',
}

export const emptyValueConditions: string[] = [
    UdbConditions.IS_EMPTY,
    UdbConditions.IS_NOT_EMPTY,
];

export enum BooleanValues {
    TRUE = 'true',
    FALSE = 'false',
}

export enum UdbOperators {
    AND = 'and',
    OR = 'or',
    NOT = 'not',
    OR_NOT = 'orNot',
}

export const PAGINATION_DEFAULT_PAGE = 1;
export const PROFILE_SECTION_PAGE_SIZE = 5;
export const PROFILE_SECTION_PAGE_SIZE_OPTIONS = [5, 10, 15, 20, 25];
export const GRID_DEFAULT_PAGE_SIZE = 20;
export const GRID_DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 50, 100, 250, 500, 1000];
export const DEFAULT_PAGINATION_DATA = { currentPage: 1, pages: 1, pageSize: PROFILE_SECTION_PAGE_SIZE, totalCount: 0 };

export const GRID_DEFAULT_TOOLTIP_PROPS = {
    enterNextDelay: 600,
    enterDelay: 600,
    leaveDelay: 0,
    arrow: true,
};

export const ORDER_OPTIONS: Record<'string' | 'number' | 'date' | 'boolean', OptionsType[]> = {
    string: [{
        label: 'A-Z',
        value: Order.ASC,
    }, {
        label: 'Z-A',
        value: Order.DESC,
    }],
    number: [{
        label: 'Lowest-Highest',
        value: Order.ASC,
    }, {
        label: 'Highest-Lowest',
        value: Order.DESC,
    }],
    date: [{
        label: 'Oldest-Newest',
        value: Order.ASC,
    }, {
        label: 'Newest-Oldest',
        value: Order.DESC,
    }],
    boolean: [{
        label: '"No" first',
        value: Order.ASC,
    }, {
        label: '"Yes" first',
        value: Order.DESC,
    }],
};

export const SNACKBAR_EXPIRATION_TIME = 5000;
export const SNACKBAR_MAX_COUNT = 5;

export enum SnackbarMessageVariants {
    SUCCESS = 'success',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    DEFAULT = 'default',
}

export const APP_TITLE = 'Endpoints News';
export const PAGE_TITLES = {
    ROOT: 'Reader profile: Personal',
    SECURITY: 'Reader profile: Security',
    PROFILE_SUBSCRIPTIONS: 'Reader profile: Subscriptions',
    APPLICATIONS: 'Applications',
    CREDENTIALS: 'Credentials',
    SUBSCRIPTIONS: 'Subscriptions',
    CREATE_SUBSCRIPTION: 'Create subscription',
    MANAGE_SUBSCRIPTIONS: 'Manage subscription',
    MANAGE_CREDENTIALS: 'Manage credentials',
    USERS: 'Users',
    LOG_IN: 'Log in',
    LOG_OUT: 'Log out',
    LOG_IN_LINK: 'Request magic link',
    RESET_PASSWORD: 'Reset password',
    NOT_FOUND: 'Page not found',
    CHANGE_EMAIL: 'Change Email',
    DELIVERY: 'Reader profile: Delivery',
    CREATE_ACCOUNT: 'Create Account',
    SUBSCRIBER: 'Subscribe to Endpoints News',
    ONE_CLICK_SUBSCRIBE: 'One-click subscribe',
    UPGRADE_TO_INSIDER: 'Upgrade to Insider',
    REQUEST_A_QUOTE: 'Request a quote',
    SUBSCRIPTION_TAC: 'Paid subscription terms and conditions',
    MANAGE_USER: 'Manage user',
    ENTERPRISE_CALCULATOR: 'Enterprise price calculator',
};

export const TEXT_CONDITIONS = [
    { label: 'contains', value: UdbConditions.CONTAINS },
    { label: 'does not contain', value: UdbConditions.DOES_NOT_CONTAIN },
    { label: 'equal', value: UdbConditions.EQUAL },
    { label: 'not equal', value: UdbConditions.NOT_EQUAL },
    { label: 'starts with', value: UdbConditions.STARTS_WITH },
    { label: 'ends with', value: UdbConditions.ENDS_WITH },
    { label: 'is empty', value: UdbConditions.IS_EMPTY },
    { label: 'is not empty', value: UdbConditions.IS_NOT_EMPTY },
];

export const TIME_CONDITIONS = [
    { label: 'on', value: UdbConditions.EQUAL },
    { label: 'after', value: UdbConditions.MORE_THAN },
    { label: 'before', value: UdbConditions.LESS_THAN },
    { label: 'on or after', value: UdbConditions.EQUAL_OR_MORE },
    { label: 'before or on', value: UdbConditions.EQUAL_OR_LESS },
];

export const MULTI_SELECT_CONDITIONS = [
    { label: 'is', value: UdbConditions.EQUAL },
    { label: 'any of', value: UdbConditions.ANY_OF },
    { label: 'not', value: UdbConditions.NOT_EQUAL },
    { label: 'not any of', value: UdbConditions.NOT_ANY_OF },
    { label: 'not set', value: UdbConditions.IS_EMPTY },
];

export const NUMBER_CONDITIONS = [
    { label: 'equal', value: UdbConditions.EQUAL },
    { label: 'equal or more', value: UdbConditions.EQUAL_OR_MORE },
    { label: 'equal or less', value: UdbConditions.EQUAL_OR_LESS },
    { label: 'more than', value: UdbConditions.MORE_THAN },
    { label: 'less than', value: UdbConditions.LESS_THAN },
    { label: 'is empty', value: UdbConditions.IS_EMPTY },
];

export enum ResetPasswordFormFields {
    NEW_PASSWORD = 'newPassword',
    REPEAT_NEW_PASSWORD = 'repeatNewPassword',
}

export enum ManageProfileSubscriptionActions {
    cancelSubscription = 'cancelSubscription',
    restoreSubscription = 'restoreSubscription',
}

export enum ProfilePaymentMethodSectionModal {
    addPaymentMethodModal = 'addPaymentMethodModal',
    changePaymentMethodModal = 'changePaymentMethodModal',
}

export enum ManageUserActions {
    changeRole = 'changeRole',
    disable2FA = 'disable2FA',
    changeAccountStatus = 'changeAccountStatus',
    logInLink = 'logInLink',
    passwordLink = 'passwordLink',
}

export enum UdbManageSubscriptionActions {
    getUsageData = 'getUsageData',
    upcomingRenewal = 'upcomingRenewal',
    updateGracePeriod = 'updateGracePeriod',
    addDomain = 'addDomain',
    cancelSubscription = 'cancelSubscription',
    restoreSubscription = 'restoreSubscription',
    pauseSubscription = 'pauseSubscription',
    resumeSubscription = 'resumeSubscription',
    extendSubscription = 'extendSubscription',
    terminateSubscription = 'terminateSubscription',
    activateSubscription = 'activateSubscription',
    reactivateSubscription = 'reactivateSubscription',
    assignStripeCustomer = 'assignStripeCustomer',
    adjustPricingTerms = 'adjustPricingTerms',
}

export enum UdbManagerSectionModal {
    addManager = 'addManager',
    changeOwner = 'changeOwner',
}

export enum UdbBillingSectionModal {
    createInitialRecordModal = 'createInitialRecordModal',
}

export const manageProfileSubscriptionActionsList: Record<ManageProfileSubscriptionActions, ManageActionType> = {
    [ManageProfileSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' },
    [ManageProfileSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' },
};

export const roundedBadgePropsMapping: Record<SubscriptionStatus, RoundedBadgeProps> = {
    [SubscriptionStatus.ACTIVE]: {
        variant: RoundedBadgeVariant.GREEN,
        label: subscriptionStatusMapping[SubscriptionStatus.ACTIVE],
    },
    [SubscriptionStatus.SERVICE_ENDED]: {
        variant: RoundedBadgeVariant.GREY,
        label: subscriptionStatusMapping[SubscriptionStatus.SERVICE_ENDED],
    },
    [SubscriptionStatus.TERMINATED]: {
        variant: RoundedBadgeVariant.RED,
        label: subscriptionStatusMapping[SubscriptionStatus.TERMINATED],
    },
    [SubscriptionStatus.PAUSED]: {
        variant: RoundedBadgeVariant.ORANGE,
        label: subscriptionStatusMapping[SubscriptionStatus.PAUSED],
    },
    [SubscriptionStatus.DRAFT]: {
        label: subscriptionStatusMapping[SubscriptionStatus.DRAFT],
    },
};

export const subscriptionActionMap: Record<string, SubscriptionAction> = {
    cancelSubscription: SubscriptionAction.CANCEL,
    restoreSubscription: SubscriptionAction.RESTORE,
    resumeSubscription: SubscriptionAction.RESUME,
    pauseSubscription: SubscriptionAction.PAUSE,
    terminateSubscription: SubscriptionAction.TERMINATE,
};

type ActionModalKey = ManageUserActions | UdbManageSubscriptionActions | UdbManagerSectionModal;

export const actionModalKeyMap: Record<ActionModalKey, GeneralModalKey> = {
    addDomain: GeneralModalKey.addDomain,
    addManager: GeneralModalKey.addManager,
    logInLink: GeneralModalKey.generateLink,
    changeOwner: GeneralModalKey.changeOwner,
    passwordLink: GeneralModalKey.generateLink,
    disable2FA: GeneralModalKey.disable2FAModal,
    changeRole: GeneralModalKey.changeRoleModal,
    getUsageData: GeneralModalKey.usageDataModal,
    upcomingRenewal: GeneralModalKey.upcomingRenewal,
    updateGracePeriod: GeneralModalKey.updateGracePeriod,
    pauseSubscription: GeneralModalKey.subscriptionActionModal,
    cancelSubscription: GeneralModalKey.subscriptionActionModal,
    extendSubscription: GeneralModalKey.extendSubscriptionModal,
    resumeSubscription: GeneralModalKey.subscriptionActionModal,
    restoreSubscription: GeneralModalKey.subscriptionActionModal,
    terminateSubscription: GeneralModalKey.subscriptionActionModal,
    activateSubscription: GeneralModalKey.activateSubscriptionModal,
    changeAccountStatus: GeneralModalKey.changeAccountStatusModal,
    reactivateSubscription: GeneralModalKey.reactivateSubscriptionModal,
    assignStripeCustomer: GeneralModalKey.assignStripeCustomerModal,
    adjustPricingTerms: GeneralModalKey.adjustPricingTerms,
};

export const weekDaysMapping: Record<number, string> = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
};

export enum SubscriberType {
    basic = 'basic',
    insider = 'insider',
    enterprise = 'enterprise',
}

export enum SubscriptionType {
    INSIDER = 'insider',
    ENTERPRISE = 'enterprise',
}

export enum SubscriptionBillingType {
    FREE = 'free',
    INVOICED = 'invoiced',
    AUTO_RENEWAL = 'autoRenewal',
}

export enum DiscountType {
    PERCENTAGE = 'percentage',
    FIXED = 'fixed',
}

export const subscriberTypeRenderMapping: Record<SubscriberType, string> = {
    [SubscriberType.basic]: 'Basic',
    [SubscriberType.insider]: 'Insider',
    [SubscriberType.enterprise]: 'Enterprise',
};

export const billingTypeRenderMapping: Record<SubscriptionBillingType, string> = {
    [SubscriptionBillingType.FREE]: 'Free',
    [SubscriptionBillingType.INVOICED]: 'Invoiced',
    [SubscriptionBillingType.AUTO_RENEWAL]: 'Auto renewal',
};

export const FORM_COLUMN_SPACING = { sm: 2, xs: 2.5 };

export const FORM_ROW_SPACING = { xs: 2.5, sm: 4, md: 2 };

export const FORM_SEPARATOR_MARGIN_TOP = { xs: -2, sm: -3.5, md: 0.5 };

export enum SubscriberStatus {
    EXIST = 'exist',
    INACTIVE = 'inactive',
    DOES_NOT_EXIST = 'does-not-exist',
}

export enum BillingStatus {
    PAID = 'Paid',
    FREE = 'Free',
    VOID = 'Void',
    UPCOMING = 'Upcoming',
    PAST_DUE = 'Past due',
}

export enum DateFormats {
    FULL = 'DD MMM YYYY, HH:mm:ss',
    SHORT = 'MMM DD, HH:mm',
    DATE = 'DD MMM YYYY',
}

export enum UDBNewsletterCampaignSegment {
    BASIC = 'Basic',
    ENTERPRISE = 'Enterprise',
    INSIDER = 'Insider',
}

export const editableSubscriptionStatuses: SubscriptionStatus[] = [
    SubscriptionStatus.ACTIVE,
    SubscriptionStatus.PAUSED,
    SubscriptionStatus.DRAFT,
];

export const newslettersSetGridColumns = ['newsletters', 'date', 'sampleIssue', 'email', 'sms'] as const;

export const additionalWideInputWidth = '300px';
export const additionalShortInputWidth = '130px';

export enum ActivationDate {
    NOW = 'now',
    SPECIFIED = 'specified',
    MANUALLY = 'manually',
}

export enum BillingCycleLengthInMonth {
    MONTH = 1,
    YEAR = 12,
}

export const billingCycleLengthRenderMapping: Record<BillingCycleLengthInMonth, string> = {
    [BillingCycleLengthInMonth.MONTH]: 'month',
    [BillingCycleLengthInMonth.YEAR]: 'year',
};

export enum DiscountDuration {
    FIRST_CYCLE = 'firstCycle',
    MULTIPLE_CYCLE = 'multipleCycle',
    FOREVER = 'forever',
}

export enum PricingModel {
    FIXED_PRICE = 'fixedPrice',
    RENEWABLE_PRICE = 'renewablePrice',
}

export const pricingModelRenderLabels: Record<PricingModel, string> = {
    [PricingModel.FIXED_PRICE]: 'Fixed price forever',
    [PricingModel.RENEWABLE_PRICE]: 'Based on the price at a renewal',
};
