import React, { FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { StyledExpiresIn } from './ActivateSubscriptionModal.styles';
import { getActivationButtonLabel, getActivationConfirmationMessage } from './services/activate-subscription-getters';
import { StyledModalMessage } from 'src/components/Modals/BaseModal/BaseModal.styles';
import { getActivationDate, getActivationDateRadioItems } from 'src/services/activation-date-getters';
import { convertToUTCDate } from 'src/services/date-time-zone-converter';
import { LoaderOverlay } from 'src/components/LoaderOverlay';
import { CustomAttributes } from 'src/@types/subscription-service-api';
import { activateSubscription, calculateDates, updateSubscription } from 'src/services/subscription-service-api';
import { BaseModal, DEFAULT_MODAL_PADDING_X } from 'src/components/Modals/BaseModal';
import { useGeneralModal, useReloadPage, useSnackbarMessage } from 'src/hooks';
import { Button } from 'src/components/Buttons';
import { Section } from 'src/components/Section';
import { RadioGroup } from 'src/components/Radio';
import { DurationInput } from 'src/components/Inputs';
import {
    ActivationDate,
    additionalShortInputWidth,
    BillingCycleLengthInMonth,
    billingCycleLengthRenderMapping,
    DATE_TIME_ISO_8601,
    SnackbarMessageVariants,
    SubscriptionBillingType,
} from 'src/constants';

export type ActivateSubscriptionModalProps = {
    billingType: SubscriptionBillingType;
    customAttributes: Partial<CustomAttributes>;
    subscriptionUUID: string;
    minimumActivationDate?: string;
};

export type TypeActivationData = {
    selectedActivationDateType?: ActivationDate;
    numberOfCycles?: number;
    activationDate?: string | null;
};

const ActivateSubscriptionModal: FC<ActivateSubscriptionModalProps> = ({
    billingType,
    customAttributes,
    subscriptionUUID,
    minimumActivationDate,
}) => {
    const { cycleCount, cycleLengthInMonths } = customAttributes;
    const cycleCountNumber = cycleCount ? parseInt(cycleCount, 10) : 1;

    const [activationData, setActivationData] = useState<TypeActivationData>({ numberOfCycles: cycleCountNumber });
    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expirationDate, setExpirationDate] = useState<string>();

    const { closeModal } = useGeneralModal();
    const { addMessage } = useSnackbarMessage();
    const { reloadPage } = useReloadPage();

    const { activationDate, selectedActivationDateType, numberOfCycles } = activationData;

    const showDurationInput = billingType === SubscriptionBillingType.FREE &&
        (selectedActivationDateType === ActivationDate.NOW || selectedActivationDateType === ActivationDate.SPECIFIED);

    const cycleLength = cycleLengthInMonths
        ? Number(cycleLengthInMonths) as BillingCycleLengthInMonth
        : BillingCycleLengthInMonth.YEAR;

    const hasSelectedData = [
        selectedActivationDateType,
        numberOfCycles,
        activationDate,
    ].every((item) => item !== undefined);

    const setData = (key: keyof TypeActivationData) => (value: TypeActivationData[keyof TypeActivationData]) => {
        setActivationData({
            ...activationData,
            [key]: value,
        });
    };

    const setSelectedActivationDateType = (activationDateType: ActivationDate) => {
        setActivationData({
            ...activationData,
            selectedActivationDateType: activationDateType,
            activationDate: getActivationDate(activationDateType),
        });
    };

    const handleActivateNow = async (): Promise<boolean> => {
        return activateSubscription(subscriptionUUID, {
            cycleCount: numberOfCycles,
        })
            .then(() => {
                addMessage('Subscription activated successfully', SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage('Failed to activate subscription', SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const handleActivateWithSpecificDate = async (selectedDate?: string | null): Promise<boolean> => {
        const selectedDateUTC = convertToUTCDate(selectedDate || dayjs());
        const newActivationDate = selectedDate ? selectedDateUTC.format(DATE_TIME_ISO_8601) : undefined;

        if (selectedDateUTC.isAfter(dayjs.utc())) {
            return updateSubscription(subscriptionUUID, {
                activationDate: newActivationDate,
                customAttributes: {
                    cycleCount: numberOfCycles || 1,
                },
            })
                .then(() => {
                    addMessage('Activation date updated successfully', SnackbarMessageVariants.SUCCESS);
                    return true;
                })
                .catch(() => {
                    addMessage('Failed to update subscription', SnackbarMessageVariants.ERROR);
                    return false;
                });
        }

        return activateSubscription(subscriptionUUID, {
            activationDate: newActivationDate,
            cycleCount: numberOfCycles,
        })
            .then(() => {
                addMessage('Subscription activated successfully', SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage('Failed to activate subscription', SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const handleActivateManually = async (): Promise<boolean> => {
        return updateSubscription(subscriptionUUID, {
            activationDate: null,
        })
            .then(() => {
                addMessage('Activation date updated successfully', SnackbarMessageVariants.SUCCESS);
                return true;
            })
            .catch(() => {
                addMessage('Failed to update subscription', SnackbarMessageVariants.ERROR);
                return false;
            });
    };

    const handleConfirm = () => {
        setIsLoading(true);

        let action: Promise<boolean>;
        switch (selectedActivationDateType) {
            case ActivationDate.SPECIFIED:
                action = handleActivateWithSpecificDate(activationDate);
                break;
            case ActivationDate.MANUALLY:
                action = handleActivateManually();
                break;
            default:
                action = handleActivateNow();
                break;
        }

        action.then((succeeded) => {
            if (succeeded) {
                closeModal();
                reloadPage();
                return;
            }

            setIsConfirming(false);
            setIsLoading(false);
        });
    };

    const activationDateUTCString = convertToUTCDate(activationDate ?? dayjs()).format(DATE_TIME_ISO_8601);

    const handleCalculateDates = async () => {
        if (selectedActivationDateType === ActivationDate.MANUALLY) {
            return;
        }

        setIsLoading(true);
        return calculateDates({
            cycleCount: numberOfCycles,
            cycleLengthInMonths: cycleLength,
            activationDate: activationDateUTCString,
        })
            .then(({ expirationDate: calculatedExpirationDate }) => {
                setExpirationDate(calculatedExpirationDate);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    if (isConfirming) {
        const confirmationMessage = getActivationConfirmationMessage(
            selectedActivationDateType as ActivationDate,
            activationDateUTCString,
            expirationDate,
        );

        return (
            <BaseModal
                open
                step={1}
                overlayScope="relative"
                handleClose={closeModal}
                handleGoBack={() => setIsConfirming(false)}
                title={
                    selectedActivationDateType === ActivationDate.MANUALLY
                        ? 'Activate manually later'
                        : 'Confirm activation'
                }
            >
                {isLoading && <LoaderOverlay />}
                <StyledModalMessage>
                    {confirmationMessage}
                </StyledModalMessage>
                <Stack direction="row" spacing={{ xs: 2, sm: 2.5 }} marginTop={3}>
                    <Button
                        minSize="small"
                        data-testid="activate-modal-cancel-button"
                        onClick={() => setIsConfirming(false)}
                        fullWidth
                        variant="secondary"
                    >
                        Go back
                    </Button>
                    <Button
                        minSize="small"
                        fullWidth
                        data-testid="activate-modal-submit-button"
                        onClick={handleConfirm}
                        disabled={isLoading}
                    >
                        {getActivationButtonLabel(selectedActivationDateType as ActivationDate)}
                    </Button>
                </Stack>
            </BaseModal>
        );
    }

    return (
        <Stack position="relative">
            {isLoading && <LoaderOverlay />}
            <Section
                marginTop={0}
                isMultiSection
                variant="headless"
                header="Activation time"
                sectionPaddingBottom={showDurationInput ? { xs: 2, md: 4.5 } : 3}
            >
                <RadioGroup<ActivationDate>
                    selectedValue={selectedActivationDateType}
                    items={getActivationDateRadioItems({
                        onChange: setData('activationDate'),
                        selectedType: selectedActivationDateType,
                        billingCycleLength: cycleLength,
                        activationDate,
                        position: 'fixed',
                        centeredDatePicker: 'center',
                        initialMinActivationDate: minimumActivationDate,
                    })}
                    onChange={setSelectedActivationDateType}
                />
                {showDurationInput && (
                    <>
                        <StyledExpiresIn paddingTop={{ xs: 2, md: 3 }}>
                         Expires in
                        </StyledExpiresIn>
                        <Box
                            maxWidth={additionalShortInputWidth}
                            paddingTop={{ xs: 2, md: 2.5 }}
                        >
                            <DurationInput
                                value={numberOfCycles}
                                onChange={setData('numberOfCycles')}
                                adornmentText={
                                    billingCycleLengthRenderMapping[cycleLength]
                                }
                            />
                        </Box>
                    </>
                )}
            </Section>
            <Stack
                paddingX={DEFAULT_MODAL_PADDING_X}
                direction="row"
                spacing={{ xs: 2, sm: 2.5 }}
                paddingBottom={{ xs: 3, sm: 4.5 }}
            >
                <Button minSize="small" onClick={closeModal} fullWidth variant="secondary">Cancel</Button>
                <Button
                    minSize="small"
                    disabled={!hasSelectedData}
                    fullWidth
                    data-testid="activate-modal-button"
                    onClick={() => {
                        handleCalculateDates().then(() => setIsConfirming(true));
                    }}
                >
                    Confirm
                </Button>
            </Stack>
        </Stack>
    );
};

export default ActivateSubscriptionModal;
