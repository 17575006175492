import React, { FC, Fragment, useEffect } from 'react';
import { Stack } from '@mui/material';
import { StyledStack } from './EnterprisePricingTerms.styles';
import { StyledCheckboxLabel } from 'src/components/Label/Label.styles';
import { PriceInput } from 'src/components/Inputs';
import { Checkbox } from 'src/components/Checkbox';
import { formatPrice } from 'src/services/formatters';
import { SubscriptionBillingType } from 'src/constants';
import PriceCalculator from 'src/pages/udb/EnterpriseCalculator/PriceCalculator';
import { CalculatePriceResponse, DomainModel } from 'src/@types/subscription-service-api';
import { AddDomainForm } from 'src/components/Forms';

export type EnterprisePricingTermsData = {
    domains: DomainModel[];
    priceData: CalculatePriceResponse | null;
    finalPriceInCents: number;
};

type EnterprisePricingTermsProps = {
    selectedBillingType: SubscriptionBillingType;
    initialData?: EnterprisePricingTermsData;
    setEnterpriseData: (data: EnterprisePricingTermsData) => void;
};

const getFieldToRender = (setPrice: (newPrice: number) => void, finalPriceInCents: number) =>
    (price: CalculatePriceResponse | null, userCount: number) => [
        {
            key: 'pricePerUser',
            label: `Suggested price for ${userCount} users:`,
            value: formatPrice(price?.priceInCents ?? 0),
        },
        {
            key: `${finalPriceInCents}`,
            label: 'Final price:',
            value: (
                <StyledStack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width="150px"
                    paddingY={1.5}
                >
                    <PriceInput
                        initialPrice={finalPriceInCents}
                        handleChange={(newPrice: number) => {
                            setPrice(newPrice);
                        }}
                        currency="$"
                    />
                </StyledStack>
            ),
        },
    ];

const EnterprisePricingTerms: FC<EnterprisePricingTermsProps> = ({
    selectedBillingType,
    setEnterpriseData,
    initialData = {
        domains: [],
        finalPriceInCents: 0,
        priceData: null,
    },
}) => {
    const { domains, priceData, finalPriceInCents } = initialData;
    const totalUserCount = domains.reduce((total, { userCount }) => total + userCount, 0);
    const domainsStr = domains.reduce((acc, { domain }) => `${acc}${domain}`, '');

    const setData = (
        key: keyof EnterprisePricingTermsData,
    ) => (
        value: EnterprisePricingTermsData[keyof EnterprisePricingTermsData],
    ) => {
        setEnterpriseData({ ...initialData, [key]: value });
    };

    useEffect(() => {
        if (priceData) {
            setData('finalPriceInCents')(priceData.priceInCents);
        }
    }, [priceData]);

    return (
        <Fragment>
            <AddDomainForm
                testId="enterprise-pricing-terms-add-domains"
                domains={domains}
                setDomains={setData('domains')}
                totalUserCount={totalUserCount}
            />
            {selectedBillingType !== SubscriptionBillingType.FREE && domains.length > 0 && (
                <PriceCalculator
                    getFieldToRender={getFieldToRender(setData('finalPriceInCents'), finalPriceInCents)}
                    hash={domainsStr}
                    initialUserCount={totalUserCount}
                    initialPrice={priceData}
                    onPriceChange={setData('priceData')}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={{ xs: 1.5, sm: 2 }}
                        paddingTop={2}
                    >
                        <Checkbox />
                        <StyledCheckboxLabel>
                            Create invoice
                        </StyledCheckboxLabel>
                    </Stack>
                </PriceCalculator>
            )}
        </Fragment>
    );
};

export default EnterprisePricingTerms;
