import React, { FC, useCallback, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledIcon, StyledText, StyledTextButton } from './CopyIcon.styles';
import { ReactComponent as ContentCopyIcon } from 'src/assets/icons/copy-icon.svg';
import { ReactComponent as DoneIcon } from 'src/assets/icons/done-icon.svg';
import StyledTooltip from 'src/components/Tooltip';

type CopyCellProps = {
    content: string;
    hiddenIcon?: boolean;
    type?: 'text' | 'link';
};

const CopyIcon: FC<CopyCellProps> = ({
    content,
    hiddenIcon = false,
    type = 'text',
}) => {
    const [copied, setCopied] = useState<boolean>(false);

    const copyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(content).then(() => setCopied(true));
    }, [content]);

    if (content.toLowerCase() === 'n/a') {
        return null;
    }

    return (
        <StyledIcon data-testid="copy-icon" data-visible={hiddenIcon}>
            <StyledTooltip
                leaveDelay={copied ? 2000 : 0}
                TransitionProps={{
                    onExited: () => setCopied(false),
                }}
                disableInteractive
                title={
                    copied ? (
                        <Stack direction="row" gap={1} alignItems="center">
                            <DoneIcon />
                            <StyledText>{type} copied!</StyledText>
                        </Stack>
                    ) : (
                        <StyledText>Copy {type}</StyledText>
                    )
                }
                arrow
            >
                <StyledTextButton data-testid="copy-icon-content" onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </StyledTextButton>
            </StyledTooltip>
        </StyledIcon>
    );
};

export default CopyIcon;
