import dayjs from 'dayjs';
import { BillingRecordModel, SubscriptionModel, SubscriptionUserModel } from 'src/@types/subscription-service-api';
import type { ManageActionType } from 'src/components/ManageActions/ManageActions';
import {
    DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE,
    editableSubscriptionStatuses,
    SUBSCRIPTION_RENEWAL_DAYS,
    SubscriptionAction,
    SubscriptionBillingType,
    SubscriptionStatus,
    SubscriptionType,
    UdbBillingSectionModal,
    UdbManagerSectionModal,
    UdbManageSubscriptionActions,
} from 'src/constants';

export const isBillingAllowedToRenew = ({ isPaid, contractSigned }: BillingRecordModel) => {
    return isPaid || contractSigned;
};

export const isRenewalDisabled = (
    expirationDate: string,
    billingRecord?: BillingRecordModel,
) => {
    const today = dayjs();
    const renewalPeriodStartDate = dayjs(expirationDate).subtract(SUBSCRIPTION_RENEWAL_DAYS, 'days');

    const isRenewalPeriodStartedToday = today.isSame(renewalPeriodStartDate, 'days');
    const isRenewalPeriodStartedAfterToday = today.isAfter(renewalPeriodStartDate, 'days');
    const isInRenewalPeriod = isRenewalPeriodStartedAfterToday || isRenewalPeriodStartedToday;

    const isRenewalForbidden = isInRenewalPeriod && (
        billingRecord && isBillingAllowedToRenew(billingRecord)
    );

    return !isInRenewalPeriod || isRenewalForbidden;
};

export const getUpcomingRenewalAction = (
    { expirationDate, status, type }: SubscriptionModel,
    billingRecord?: BillingRecordModel,
) => {
    const excludedStatusesForUpcomingRenewal = [
        SubscriptionStatus.SERVICE_ENDED,
        SubscriptionStatus.TERMINATED,
        SubscriptionStatus.PAUSED,
    ];

    if (excludedStatusesForUpcomingRenewal.includes(status) || type !== SubscriptionType.ENTERPRISE) {
        return;
    }

    const isDisabled = isRenewalDisabled(expirationDate, billingRecord);

    return {
        [UdbManageSubscriptionActions.upcomingRenewal]: {
            label: 'Upcoming renewal',
            disabled: isDisabled,
            tooltipTitle: isDisabled ? 'This functionality will become available 90 days before the renewal date' : '',
        },
    };
};

export const getUdbManageSubscriptionActionsList = ({
    getActionExtra,
    isActionAllowed,
    billingRecord,
    canManage,
    subscription,
}: {
    isActionAllowed: (actionKey: SubscriptionAction) => boolean;
    getActionExtra: (actionKey: SubscriptionAction) => Record<string, unknown> | null;
    subscription?: SubscriptionModel;
    billingRecord?: BillingRecordModel;
    canManage?: boolean;
}): Partial<Record<UdbManageSubscriptionActions, ManageActionType>> => {
    if (!canManage) {
        return {
            [UdbManageSubscriptionActions.getUsageData]: { label: 'Get usage data' },
        };
    }

    const {
        status,
        type,
        billingType,
        customAttributes,
    } = subscription || {};

    const isActivateActionDisabled = !isActionAllowed(SubscriptionAction.ACTIVATE);
    const isActive = status === SubscriptionStatus.ACTIVE;
    const isDraft = status === SubscriptionStatus.DRAFT;
    const hideUpdateGracePeriod =
        type === SubscriptionType.INSIDER && billingType === SubscriptionBillingType.AUTO_RENEWAL;
    const allowAssignStripeCustomer = !customAttributes?.stripeCustomerId;

    const canAdjustPricingTerms = isActionAllowed(SubscriptionAction.CUSTOM_PRICING_TERMS_UPDATE)
    || isActionAllowed(SubscriptionAction.CUSTOM_PRICING_TERMS_CREATE);

    return {
        ...(isDraft && {
            [UdbManageSubscriptionActions.activateSubscription]: {
                label: 'Activate subscription',
                disabled: isActivateActionDisabled,
                tooltipTitle: isActivateActionDisabled ? 'Activation conditions have not been met' : '',
                actionExtraProps: getActionExtra(SubscriptionAction.ACTIVATE),
            },
        }),
        ...(isActionAllowed(SubscriptionAction.CANCEL) && { [UdbManageSubscriptionActions.cancelSubscription]: { label: 'Cancel subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESTORE) && { [UdbManageSubscriptionActions.restoreSubscription]: { label: 'Restore subscription' } }),
        ...(isActionAllowed(SubscriptionAction.PAUSE) && { [UdbManageSubscriptionActions.pauseSubscription]: { label: 'Pause subscription' } }),
        ...(isActionAllowed(SubscriptionAction.RESUME) && { [UdbManageSubscriptionActions.resumeSubscription]: { label: 'Resume subscription' } }),
        ...(isActionAllowed(SubscriptionAction.TERMINATE) && { [UdbManageSubscriptionActions.terminateSubscription]: { label: 'Terminate subscription' } }),
        ...(isActionAllowed(SubscriptionAction.REACTIVATE) && { [UdbManageSubscriptionActions.reactivateSubscription]: { label: 'Reactivate subscription' } }),
        ...(isActionAllowed(SubscriptionAction.EXTEND) && canManage && { [UdbManageSubscriptionActions.extendSubscription]: { label: 'Extend subscription' } }),
        [UdbManageSubscriptionActions.getUsageData]: { label: 'Get usage data' },
        ...(isActive && !hideUpdateGracePeriod && { [UdbManageSubscriptionActions.updateGracePeriod]: { label: 'Update grace period' } }),
        ...(subscription && getUpcomingRenewalAction(subscription, billingRecord)),
        ...(allowAssignStripeCustomer && { [UdbManageSubscriptionActions.assignStripeCustomer]: { label: 'Assign Stripe customer' } }),
        ...(canAdjustPricingTerms && { [UdbManageSubscriptionActions.adjustPricingTerms]: { label: 'Adjust pricing terms' } }),
    };
};

export const getManagerSectionActionsList = (
    managersData: SubscriptionUserModel[],
    { status }: SubscriptionModel,
): Record<UdbManagerSectionModal, ManageActionType> => {
    const hasManagers = !!managersData.length;
    const disabledDueToSubscriptionStatus = !editableSubscriptionStatuses.includes(status);
    const tooltipTitle = disabledDueToSubscriptionStatus ? DISALLOWED_ACTION_DUE_TO_SUBSCRIPTION_STATUS_MESSAGE : '';

    return {
        [UdbManagerSectionModal.addManager]: {
            label: 'Add manager',
            disabled: disabledDueToSubscriptionStatus,
            tooltipTitle,
        },
        [UdbManagerSectionModal.changeOwner]: {
            label: 'Change owner',
            disabled: !hasManagers || disabledDueToSubscriptionStatus,
            tooltipTitle: !hasManagers ? 'Please add at least one manger first' : tooltipTitle,
        },
    };
};

export const getBillingSectionActionsList = (
    isActionAllowed: (actionKey: SubscriptionAction) => boolean,
): Partial<Record<UdbBillingSectionModal, ManageActionType>> => {

    return {
        ...(isActionAllowed(SubscriptionAction.BILLING_RECORD_CREATE_INITIAL) && { [UdbBillingSectionModal.createInitialRecordModal]: { label: 'Create initial record' } }),
    };
};
